import { Component, Input, ViewChild, AfterContentInit } from '@angular/core';
import { Reference } from '@app/core/models/reference.model';
import { AuthService } from '@app/core/services/auth.service';
import { UserService } from '@app/core/services/user.service';
import { ReferenceResponseReviewDialogComponent } from './reference-response-review-dialog.component';

@Component({
  selector: 'reference-response-status',
  templateUrl: './reference-response-status.component.html',
  styles: ['fa-icon.sub-icon ::ng-deep svg { top: 10px}'],
})
export class ReferenceResponseStatusComponent implements AfterContentInit {
  @ViewChild(ReferenceResponseReviewDialogComponent)
  referenceResponseReviewDialog: ReferenceResponseReviewDialogComponent;

  @Input()
  set reference(reference: Reference) {
    this._reference = reference;
    this.isResponded = reference && reference.responseIds && reference.responseIds.length > 0;
  }

  get reference() {
    return this._reference;
  }

  @Input()
  userId: string;

  @Input()
  openable: boolean = true;

  @Input()
  labelPlacement: string = 'left';

  isResponded: boolean = false;
  isAdmin: boolean = false;

  private _reference: Reference;

  constructor(private authService: AuthService) {}

  ngAfterContentInit() {
    this.authService.isLoggedInAsAdminForCompany().then(isAdmin => {
      this.isAdmin = this.openable && isAdmin;
    });
  }

  openDialogIfAdmin() {
    if (this.isAdmin) {
      this.referenceResponseReviewDialog.openForUserReference(this.userId, this.reference.id);
    }
  }
}
