<div>
	<div class="row">
		<div class="col">
			<h6>Days</h6>
			<p-calendar [(ngModel)]="range" (onClose)="onDateRangeUpdated()" [defaultDate]="min" [baseZIndex]="1060" selectionMode="range" [showIcon]="!isMobile" appendTo="body" [focusTrap]="isMobile" [touchUI]="isMobile" [readonlyInput]="true" dateFormat="dd/mm/yy">
				<ng-template pTemplate="date" let-date>
					<span [ngStyle]="{ backgroundColor: isDateWithinRange(date) ? '#26af89' : '' }" style="border-radius:100%;  display:block;width: 1.6rem; height: 1.6rem;">{{ date.day }}</span>
				</ng-template>
			</p-calendar>
		</div>
	</div>
	<div class="row mt-2" *ngIf="showTimes">
		<div class="col">
			<h6>Start Time</h6>
			<p-calendar
				[(ngModel)]="startTime"
				(ngModelChange)="onStartTimeUpdated($event)"
				[baseZIndex]="1060"
				[showIcon]="!isMobile"
				icon="pi pi-clock"
				appendTo="body"
				[focusTrap]="isMobile"
				hourFormat="24"
				[hideOnDateTimeSelect]="false"
				[showTime]="true"
				[timeOnly]="true"
				[touchUI]="isMobile"
				[readonlyInput]="true"
				[stepMinute]="15"
			>
			</p-calendar>
		</div>
		<div class="col">
			<h6>End Time</h6>
			<p-calendar
				[(ngModel)]="endTime"
				(ngModelChange)="onEndTimeUpdated($event)"
				[baseZIndex]="1060"
				[showIcon]="!isMobile"
				icon="pi pi-clock"
				appendTo="body"
				[focusTrap]="isMobile"
				hourFormat="24"
				[hideOnDateTimeSelect]="false"
				[showTime]="true"
				[timeOnly]="true"
				[touchUI]="isMobile"
				[readonlyInput]="true"
				[stepMinute]="15"
			>
			</p-calendar>
		</div>
	</div>
	<div class="row row-no-margin" *ngIf="showTimes">
		<small class="text-danger mt-2" *ngIf="!isEndAfterStart">End must be after start</small>
	</div>
</div>
