import { Component, OnInit, Input, OnDestroy, ElementRef, HostListener, Renderer2, EventEmitter, Output } from '@angular/core';
import { UserService } from '@app/core/services/user.service';

import { from, merge, Subscription } from 'rxjs';
import { LinkService } from '@app/core/link.service';
import { CompanyService } from '@app/core/services/company.service';
import { Company } from '@app/core/models/company.model';
import { ShareHelper } from '@app/core/helpers/share.helper';
import { NavigationStateService } from '@app/core/helpers/navigation-state.service';
import { CurrentUser } from '@app/core/models/user.model';
import { Event } from '../../core/services/event.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  transparent: boolean = false;

  @Input()
  showSideMenuExpander: boolean = false;

  @Input()
  showLogoMobile: boolean = true;

  @Input()
  set isFadeIn(isFadeIn: boolean) {
    this._isFadeIn = isFadeIn;
    if (!isFadeIn) {
      this.updateBarOpacity();
    }
  }

  get isFadeIn() {
    return this._isFadeIn;
  }

  @Input()
  share: boolean = true;

  @Input()
  event: Event;

  menuHidden = true;
  currentUser: CurrentUser;
  isAdmin = false;
  isTimesheetManager = false;
  company: Company;
  subs: Subscription;

  @Output()
  onBurgerMenuToggle: EventEmitter<void> = new EventEmitter<void>();

  private showAtScollPx = 150;
  private fadeInLength = 150;
  private _isFadeIn = false;
  private isHidden = false;

  constructor(
    private navigationStateService: NavigationStateService,
    private shareHelper: ShareHelper,
    private linkService: LinkService,
    private authService: AuthService,
    private companyService: CompanyService,
    private el: ElementRef,
    private _renderer: Renderer2,
  ) {
    this.subs = merge(from(this.authService.getLoggedInUser()), this.authService.onLoggedInUserChanged()).subscribe(user => {
      this.currentUser = user;
      this.authService.isLoggedInAsAdminForCompany().then(admin => (this.isAdmin = admin));
      this.authService.isLoggedInAsTimesheetManagerForCompany().then(admin => (this.isTimesheetManager = admin));
    });
    this.subs.add(
      merge(from(this.companyService.getCompany()), this.companyService.onCompanyUpdate()).subscribe(company => {
        this.company = company;
      }),
    );
  }

  ngOnInit() {}

  toggleSideMenu() {
    this.onBurgerMenuToggle.emit();
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  logout() {
    this.linkService.navigate(['logout']);
  }

  isLoggedIn() {
    return !!this.currentUser;
  }

  isShowingBackNavButton() {
    return this.navigationStateService.hasHistory();
  }

  navigateBack() {
    this.navigationStateService.navigatePrev();
  }

  getUserName() {
    return this.currentUser && this.currentUser.user ? this.currentUser.user.firstName : null;
  }

  openProfile() {
    this.linkService.navigate(['profile']);
  }

  editProfile() {
    this.linkService.navigate(['profile', 'edit']);
  }

  onLoginButtonClick() {
    try {
      if (document.querySelector('#login')) {
        document.querySelector('#login').scrollIntoView({ behavior: 'smooth' });
      }
    } catch (e) {}
  }
  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    this.updateBarOpacity();
  }

  updateBarOpacity() {
    let opacity = 0;
    if (!this.isFadeIn) {
      opacity = 1;
    } else if (window.pageYOffset > this.showAtScollPx) {
      opacity = (window.pageYOffset - this.showAtScollPx) / this.fadeInLength;
    }
    if (opacity < 0.1) {
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }

    const elementsToFadeIn = this.el.nativeElement.getElementsByClassName('fadeIn');

    for (let i = 0; i < elementsToFadeIn.length; i++) {
      this._renderer.setStyle(elementsToFadeIn.item(i), 'opacity', opacity + '');
    }

    const elementsToBrightOut = this.el.nativeElement.getElementsByClassName('brightOut');

    for (let i = 0; i < elementsToBrightOut.length; i++) {
      this._renderer.setStyle(elementsToBrightOut.item(i), 'filter', 'brightness(' + (10 - opacity * 10) + ')');
    }
  }

  shareLink() {
    if (!this.isHidden) {
      this.shareHelper.shareCurrentUrl();
    }
  }
}
