import { Pipe, PipeTransform } from '@angular/core';
import { RoleLite } from '@app/core/models/role.model';
@Pipe({ name: 'roleRate' })
export class RoleRatePipe implements PipeTransform {
  transform(role: RoleLite, args: any) {
    if (role == null) {
      return null;
    }
    if (role?.rateManual && !args?.includes('hideManual')) {
      return role.rateManual;
    } else if (role?.calculated?.rate?.length) {
      if (role.calculated.rate.length === 1) {
        return '£' + role.calculated.rate[0];
      } else {
        return `£${Math.min(...role.calculated.rate).toFixed(2)}-${Math.max(...role.calculated.rate).toFixed(2)}`;
      }
    } else {
      return 'TBC';
    }
  }
}
