<div class="page">
  <div>
    <div class="header">
      <div class="banner-wrapper">
        <img class="banner" *ngIf="!isLoading" [src]="(isMobile() ? event.imageMobile : event.image) || '/assets/default-event.jpg'" [class.past-event]="!isInFuture" />
        <p-skeleton height="100%" width="100%" *ngIf="isLoading" [animation]="isError && 'none'"></p-skeleton>
        <div class="not-found-banner vertical-center text-center" *ngIf="isError">
          <h3>Ooops - Event not found!</h3>
          <h6 class="link" link="../">Take me back</h6>
        </div>
      </div>
      <div class="container">
        <div class="event-summary">
          <div *ngIf="!isLoading">
            <h1 class="text-center desktop">{{ event.name }}</h1>
            <h2 class="text-center mobile mb-3">{{ event.name }}</h2>

            <h2 class="text-center desktop">{{ event.dateRange | dateRange: 'showYear' }}</h2>
            <h3 class="text-center mobile">{{ event.dateRange | dateRange: 'showYear' }}</h3>
          </div>
          <div *ngIf="isLoading" class="w-50 mx-auto">
            <p-skeleton width="100% d-block mt-2" height="4rem" [animation]="isError && 'none'"></p-skeleton>
            <p-skeleton class="mt-3 d-block" width="100%" height="2rem" [animation]="isError && 'none'"></p-skeleton>
          </div>
          <hr />
          <h3 class="text-center-mobile mb-3">Event Info:</h3>
          <div class="row">
            <div class="col-md-8">
              <div *ngIf="!isLoading">
                <p class="text-justify description" ellipsis="Read more..." (ellipsis-click-more)="onEllipsisShowMore(event.description)">
                  {{ event.description }}
                </p>
                <a *ngIf="event.website" class="btn btn-success mt-3 mb-3 text-center-mobile" [href]="event.website" target="_blank">Visit Website</a>

                <div class="row row-no-margin mt-3 mb-3" *ngIf="event.instagram || event.facebook">
                  <div class="text-center-mobile">
                    <h6 class="bold d-inline mr-2">Social Feeds:</h6>
                    <a [href]="event.instagram" target="_blank" class="mr-2" *ngIf="event.instagram">
                      <fa-icon [icon]="['fab', 'instagram']" class="clickable"> </fa-icon>
                    </a>
                    <a [href]="event.facebook" target="_blank" *ngIf="event.facebook">
                      <fa-icon [icon]="['fab', 'facebook-f']" class="mr-2 clickable"> </fa-icon>
                    </a>
                  </div>
                </div>
              </div>
              <div class="isLoading">
                <p-skeleton width="100%" height="10rem" *ngIf="isLoading" [animation]="isError && 'none'"></p-skeleton>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="text-center-mobile mt-3-mobile" *ngIf="!isLoading">
                <li *ngIf="event.location">
                  <div class="vertical-center">
                    <fa-icon [icon]="['fas', 'map-marker']" [fixedWidth]="true"> </fa-icon>
                  </div>
                  <h6>{{ event.location }}</h6>
                </li>
                <li>
                  <div class="vertical-center">
                    <fa-icon [icon]="['fas', 'calendar-alt']" [fixedWidth]="true"> </fa-icon>
                  </div>
                  <h6>{{ event.dateRange | dateRange: 'showYear' }}</h6>
                </li>
                <li>
                  <div class="vertical-center">
                    <fa-icon [icon]="['fas', 'ticket-alt']" [class.disabled]="!event.info.ticket"> </fa-icon>
                  </div>
                  <h6>Ticket {{ event.info.ticket ? 'included' : 'not included' }}</h6>
                </li>
                <li>
                  <div class="vertical-center">
                    <fa-icon [icon]="['fas', 'campground']" [class.disabled]="!event.info.camping"> </fa-icon>
                  </div>
                  <h6>Camping {{ event.info.camping ? 'available' : 'not available' }}</h6>
                </li>
                <li>
                  <div class="vertical-center">
                    <fa-icon [icon]="['fas', 'car']" [class.disabled]="!event.info.carParking"> </fa-icon>
                  </div>
                  <h6>Car parking permit {{ event.info.carParking ? 'available' : 'not available' }}</h6>
                </li>
                <li>
                  <div class="vertical-center">
                    <fa-icon [icon]="['fas', 'shuttle-van']" [fixedWidth]="true" [class.disabled]="!event.info.vanParking"> </fa-icon>
                  </div>
                  <h6>Van parking permit {{ event.info.vanParking ? 'available' : 'not available' }}</h6>
                </li>
                <li>
                  <div class="vertical-center">
                    <fa-icon [icon]="['fas', 'tshirt']" [class.disabled]="!event.info.uniform"> </fa-icon>
                  </div>
                  <h6>Uniform {{ event.info.uniform ? 'required' : 'not required' }}</h6>
                </li>
              </ul>
              <div class="isLoading">
                <p-skeleton width="100%" height="10rem" *ngIf="isLoading" [animation]="isError && 'none'"></p-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <h3 class="text-center text-muted my-5" *ngIf="!isLoading && !isInFuture">This event has finished</h3>
      <h2 class="text-center-mobile">{{ isLoading || isInFuture ? 'Available' : 'Past' }} Shifts</h2>
      <div class="row mt-3" *ngIf="!isLoading">
        <div class="col-sm-12 col-md-6 mb-2" *ngFor="let role of roles">
          <div class="role card mb-2">
            <div class="card-body">
              <div class="flex-column">
                <div class="flex-none d-flex flex-column-mobile">
                  <h3 class="flex-auto bold">{{ role.name }}</h3>
                  <div class="flex-none vertical-center ">
                    <div class="d-flex">
                      <h2 class="my-0 bold hr-label">{{ role | roleRate }}</h2>
                      <h4 class="hr-label" *ngIf="!role.rateManual">/hr</h4>
                    </div>
                    <h4 *ngIf="event.holidayPay">+ Holiday Pay</h4>
                  </div>
                </div>
                <div class="flex-auto row row-no-margin flex-column mt-2">
                  <p class="text-justify mb-3 description" ellipsis="Read more..." (ellipsis-click-more)="onEllipsisShowMore(role.description)">
                    {{ role.description }}
                  </p>
                  <div class="row row-no-margin">
                    <div class="item" *ngIf="role.info">
                      <fa-icon [icon]="['fas', 'utensils']" [class.disabled]="!role.info.food"> </fa-icon>
                      <h6>Meals {{ role.info.food ? 'included' : 'not included' }}</h6>
                    </div>
                    <div class="item">
                      <fa-icon [icon]="['fas', 'calendar-alt']" [fixedWidth]="true"> </fa-icon>
                      <h6>{{ role | roleShifts }}</h6>
                    </div>
                  </div>
                  <div class="row row-no-margin">
                    <div class="item" *ngIf="role.info && role.info.arrival">
                      <fa-icon [icon]="['fas', 'clock']" [fixedWidth]="true"> </fa-icon>
                      <h6>Check in: {{ role.info.arrival.toDate() | amDateFormat: 'HH:mm Do MMM' }}</h6>
                    </div>
                    <div class="item" *ngIf="role.info && role.info.deposit">
                      <fa-icon [icon]="['fas', 'money-bill-alt']" [fixedWidth]="true"> </fa-icon>
                      <h6>Deposit required</h6>
                    </div>
                  </div>
                </div>
                <div class="flex-none row row-no-margin text-right d-block">
                  <apply-for-role-button [role]="role" *ngIf="!role.activeApplication" [event]="event" class="ml-3"> </apply-for-role-button>
                  <application-status *ngIf="role.activeApplication" [status]="role.activeApplication.status"> </application-status>
                </div>
              </div>
              <div class="overlay-locked vertical-center" *ngIf="!isLoggedIn">
                <h6 class="text-center">To view available shifts, please login or register</h6>
                <div *ngIf="true; then login_template; else undefined"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col" *ngIf="!isLoading && isLoggedIn && (!roles || !roles.length)">
          <div class="image-background vertical-center">
            <img src="/assets/branding/dancers.svg" class="dancers" />
            <div>
              <h3 class="text-center mb-3 bold">No active shifts at the moment..</h3>
              <h6 class="text-center">
                Check back here later to apply for work at <br class="mobile" /><span class="bold">{{ event.name }}</span
                >.
              </h6>
              <div class="row row-no-margin mt-5">
                <register-interest-card class="mx-auto" [event]="event" [currentUser]="currentUser" [company]="company"></register-interest-card>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mx-auto" *ngIf="!isLoading && !isLoggedIn && !roles.length">
          <div class="role card mb-2">
            <div class="card-body flex-column">
              <h6 class="text-center">To view available shifts, please register or login</h6>
              <div *ngIf="true; then login_template; else undefined"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isLoading">
        <div class="col-sm-12 col-md-6">
          <p-skeleton width="100%" height="12.5rem" [animation]="isError && 'none'"></p-skeleton>
        </div>
        <div class="col-sm-12 col-md-6">
          <p-skeleton width="100%" height="12.5rem" [animation]="isError && 'none'"></p-skeleton>
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <hr class="mb-5" />
      <div class="row row-no-margin" *ngIf="!isLoading && ((isLoggedIn && roles.length) || !isLoggedIn)">
        <register-interest-card class="mx-auto" [event]="event" [currentUser]="currentUser" [company]="company" [isRolesAvailable]="!!roles.length"></register-interest-card>
      </div>
      <div *ngIf="isLoading" class="w-50 mx-auto">
        <p-skeleton width="100%" height="10rem" [animation]="isError && 'none'"></p-skeleton>
      </div>
    </div>
    <div class="container">
      <div *ngIf="isLoggedIn && applications?.length">
        <h2 class="text-center-mobile">My Applications</h2>
        <div class="row" *ngIf="!isApplicationsLoading">
          <div class="col-sm-12 col-md-6 mb-2" *ngFor="let application of applications">
            <user-application-card [application]="application" (onChange)="onApplicationChange($event)"> </user-application-card>
          </div>
          <div class="col" *ngIf="!applications || !applications.length">
            <h4 class="text-center mb-3">No applications submitted for this event.</h4>

            <div class="col-sm-12 col-md-6 mx-auto"></div>
          </div>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isApplicationsLoading">
        <div class="col-sm-12 col-md-6">
          <p-skeleton width="100%" height="10rem" [animation]="isError && 'none'"></p-skeleton>
        </div>
        <div class="col-sm-12 col-md-6">
          <p-skeleton width="100%" height="10rem" [animation]="isError && 'none'"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
  <confirm-dialog></confirm-dialog>
</div>

<ng-template #login_template>
  <div class="login-wrapper vertical-center">
    <img class="login" src="/assets/branding/logo-powered-by.svg" />
    <div class="d-flex justify-content-center">
      <button class="btn btn-success mr-2" (click)="register()"><fa-icon [icon]="['fas', 'user-plus']" class="mr-2"></fa-icon>Create profile</button>
      <button class="btn btn-warning ml-2" (click)="login()"><fa-icon [icon]="['fas', 'sign-in-alt']" class="mr-2"></fa-icon>Login</button>
    </div>
  </div>
</ng-template>
