<div class="bank-info" [class.is-profile-complete]="(isProfileComplete || !showSectionError) && !isLoading">
	<div class="absolute-fill vertical-center" *ngIf="isLoading || !user">
		<fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="text-center"></fa-icon>
	</div>
	<div class="row row-no-margin">
		<div class="body-col flex-auto p-2">
			<div class="row">
				<div class="col">
					<div class="row row-no-margin flex-column">
						<h5>Contact Information:</h5>
						<span class="info">
							<fa-icon [icon]="['fas', 'phone-alt']" placement="right" ngbTooltip="Phone Number"></fa-icon>
							<p *ngIf="user && user.telephone && !isLoading">{{ user.telephone }}</p>
							<p *ngIf="!user || !user.telephone || isLoading" class="error">Required</p>
						</span>
						<span class="info">
							<fa-icon [icon]="['fas', 'home']" placement="right" ngbTooltip="Home Address"></fa-icon>
							<p>
								<span>{{ getAddressField('line1') }}</span
								><span *ngIf="!getAddressField('line1')" class="error">Required</span><span *ngIf="getAddressField('line2')">, {{ getAddressField('line2') }}</span
								><span>, {{ getAddressField('city') }}</span
								><span *ngIf="!getAddressField('city')" class="error">Required</span><span>, {{ getAddressField('county') }}</span
								><span *ngIf="!getAddressField('county')" class="error">Required</span><span>, {{ getAddressField('postCode') }}</span
								><span *ngIf="!getAddressField('postCode')" class="error">Required</span><span>, {{ getAddressField('country') }}</span
								><span *ngIf="!getAddressField('country')" class="error">Required</span>
							</p>
						</span>
						<span class="info">
							<fa-icon [icon]="['fas', 'ambulance']" placement="right" ngbTooltip=""></fa-icon>
							<p>
								<span>{{ getEmergencyContactField('name') }}</span
								><span *ngIf="!getEmergencyContactField('name')" class="error">Required</span>, <span>{{ getEmergencyContactField('telephone') }}</span
								><span *ngIf="!getEmergencyContactField('telephone')" class="error">Required</span>, <span>{{ getEmergencyContactField('relationship') }}</span
								><span *ngIf="!getEmergencyContactField('relationship')" class="error">Required</span>
							</p>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row row-no-margin" *ngIf="editable">
		<button class="btn btn-success ml-auto mt-auto mr-2 d-block mt-2 text-center-mobile" (click)="edit()">
			<fa-icon [icon]="['fas', 'pencil-alt']" class="mr-2"></fa-icon>
			{{ isProfileComplete ? 'Edit' : 'Complete Contact Info' }}
		</button>
	</div>
</div>
