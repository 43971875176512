import isEqual from 'lodash-es/isEqual';

export class CompanyEditableFields {
  name: string;
  description: string;
  website: string;
  telephone: string;
  instagram: string;
  facebook: string;
  email: string;
  logo: string;
  banner: string;
  isPrivate: boolean;

  static convert(company: Company): CompanyEditableFields {
    return {
      name: company.name,
      description: company.description,
      website: company.website,
      telephone: company.telephone,
      instagram: company.instagram,
      facebook: company.facebook,
      email: company.email,
      logo: company.logo,
      banner: company.banner,
      isPrivate: company.isPrivate,
    };
  }

  static isEqual(original: CompanyEditableFields, company: CompanyEditableFields) {
    return isEqual(original, company);
  }
}

export class Company extends CompanyEditableFields {
  id?: string;
  banner: string;
  logo: string;
  url?: string;
  stripePublicKey?: string;

  static toJSON(company: Company) {
    return {
      name: this.trimOrNull(company.name),
      banner: company.banner,
      logo: company.logo,
      description: this.trimOrNull(company.description),
      website: this.trimOrNull(company.website),
      telephone: this.trimOrNull(company.telephone),
      facebook: this.trimOrNull(company.facebook),
      instagram: this.trimOrNull(company.instagram),
      email: this.trimOrNull(company.email),
      isPrivate: company.isPrivate || false,
    };
  }

  static isEqual(original: Company, company: Company) {
    return isEqual(original, company);
  }

  static trimOrNull(str: string) {
    return str ? str.trim() : null;
  }
}

export function parseCompany(doc: any): Company {
  console.log('Parsing retrieved company');
  const company = Object.assign(new Company(), doc.data());
  company.id = doc.id;
  return company;
}

export enum UserIdentifierType {
  NI = 'NI',
  EMAIL = 'EMAIL',
}

export class UserIdentifier {
  key: string;
  type: UserIdentifierType;
  name: string;

  static parse(data: any): UserIdentifier[] {
    return Object.keys(data || {}).map(key => {
      const d = data[key];
      return {
        key,
        type: d?.type,
        name: d?.name,
      };
    });
  }

  static toJSON(users: UserIdentifier[]) {
    const obj = {};
    (users || []).forEach(user => {
      if (user?.key) {
        obj[user.key.trim()] = {
          type: user.type?.trim() || UserIdentifierType.EMAIL,
          name: user.name?.trim() || null,
        };
      }
    });
    return obj;
  }

  static getDiffAsJSON(updated: UserIdentifier[], original: UserIdentifier[]): any {
    const json = UserIdentifier.toJSON(updated);
    const originalJSON = UserIdentifier.toJSON(original);

    //add in the deletes
    Object.keys(originalJSON || {})
      .filter(o => !json[o])
      .map(deletedId => (json[deletedId] = null));

    const updates = {};

    Object.keys(json || {})
      .filter(id => !isEqual(originalJSON[id], json[id]))
      .forEach(id => (updates[id] = json[id]));
    return updates;
  }
}
