<div class="container">
	<div class="absolute-center vertical-center text-center mb-3" *ngIf="isLoadingFile">
		<fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x" class="text-muted"></fa-icon>
	</div>
	<div class="row" *ngIf="!fileSelected">
		<div class="text-center col-md-12">
			<button *ngIf="!preselectedImage" type="button" (click)="onBrowseClicked()" class="btn btn-sm btn-success" readMode="binaryString" ngxFilePicker accept="image/*" (filePick)="onFileSelect($event)">
				<fa-icon [icon]="['fas', 'camera']"></fa-icon>
				Browse for Image
			</button>
		</div>
	</div>
	<div class="row" *ngIf="fileSelected">
		<div class="text-center col wrapper">
			<image-cropper
				[imageFile]="imageFile"
				[maintainAspectRatio]="true"
				[aspectRatio]="options.aspectRatio"
				[resizeToWidth]="options.resizeToWidth"
				format="jpeg"
				[roundCropper]="options.roundCropper"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="onImageLoaded()"
				(cropperReady)="cropperReady()"
				(loadImageFailed)="loadImageFailed()"
			>
			</image-cropper>
			<h5 *ngIf="selectionDimensions">Selection {{ selectionDimensions.width }}w x {{ selectionDimensions.height }}h <fa-icon *ngIf="!showTooSmallError" [icon]="['fas', 'check']" class="text-success"></fa-icon></h5>
			<p *ngIf="showTooSmallError" class="text-warning mt-2">To avoid your image looking pixelated please select an image that is at least {{ options.resizeToWidth }}w x {{ options.resizeToHeight }}h</p>
		</div>
	</div>
</div>
