import { Component, Input } from '@angular/core';
import { Event } from '@app/core/services/event.service';
import { LinkService } from '@app/core/link.service';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent {
  @Input()
  set event(event: Event) {
    this._event = event;
    if (event) {
      this.roles = event.rolesLite
        .filter(r => r.active && r.open)
        .map(r => r.name)
        .join(', ');
    }
  }

  get event() {
    return this._event;
  }

  @Input()
  clickable: boolean = true;

  @Input()
  isDashboard: boolean = false;

  roles: string;

  private _event: Event;

  constructor(protected linkService: LinkService) {}

  open() {
    if (this.clickable) {
      this.linkService.navigate(this.isDashboard ? ['dashboard', 'events', this.event.id] : ['events', this.event.id]);
    }
  }
}
