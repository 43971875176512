<div class="d-flex">
	<div class="flex-none" [class.mr-2]="small" [class.mr-3]="!small">
		<fa-icon [icon]="icon" *ngIf="icon?.length" [fixedWidth]="false" [class.fa-3x]="!small" placement="right" ngbTooltip="{{ name }}"> </fa-icon>
	</div>
	<div class="flex-auto vertical-center">
		<h6 *ngIf="!small">{{ name }}</h6>
		<a class="small" *ngIf="link" [href]="link" target="_blank" [class.event-master-teal-color]="light">Open</a>
		<p class="text-muted" *ngIf="!link && showError">Not provided..</p>
	</div>
</div>
