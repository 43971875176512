import { Component, forwardRef, Input } from '@angular/core';
import { DateRange, Timestamp } from '@app/core/models/firebase.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeviceService } from '@app/core/device/device.service';

@Component({
  selector: 'datetime-range-picker',
  templateUrl: 'datetime-range-picker.component.html',
  styleUrls: ['./datetime-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatetimeRangePickerComponent),
    },
  ],
})
export class DatetimeRangePickerComponent implements ControlValueAccessor {
  @Input()
  showTimes: boolean = true;

  @Input()
  set maxDate(date: Timestamp) {
    this._max = date ? date.toDate() : null;
  }

  @Input()
  set minDate(date: Timestamp) {
    this._min = date ? date.toDate() : null;
  }

  set range(range: Date[]) {
    this._range = range;
    this.validateForm();
    if (this.onChange) {
      const retVal = range && range.length === 2 && range[0] && range[1] && this.isEndAfterStart ? { start: Timestamp.fromDate(range[0]), end: Timestamp.fromDate(range[1]) } : null;
      this.onChange(retVal);
    }
    if (this.onTouch) {
      this.onTouch();
    }
  }

  get range() {
    return this._range;
  }

  get max() {
    return this._max;
  }

  get min() {
    return this._min;
  }

  onChange: (range: DateRange) => void;
  onTouch: any;

  startTime: Date;
  endTime: Date;
  isEndAfterStart: boolean;
  completeForm: boolean;

  private _range: Date[];
  private _max: Date;
  private _min: Date;

  constructor(private mobileService: DeviceService) {}

  writeValue(range: DateRange): void {
    this.range = range?.start && range?.end ? [range.start.toDate(), range.end.toDate()] : null;
    if (range?.start) {
      this.startTime = range.start.toDate();
    } else {
      this.startTime = new Date();
      this.startTime.setHours(12, 0, 0, 0);
    }
    if (range?.end) {
      this.endTime = range.end.toDate();
    } else {
      this.endTime = new Date();
      this.endTime.setHours(12, 0, 0, 0);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  onStartTimeUpdated(time: Date): void {
    if (this.range && this.range.length === 2) {
      const updated = new Date();
      updated.setTime((this.range && this.range[0]?.getTime()) || new Date().getTime());
      updated.setHours(time.getHours());
      updated.setMinutes(time.getMinutes());
      updated.setMilliseconds(0);
      updated.setSeconds(0);

      this.range = [updated, this.range[1]];
      this.validateForm();
    }
  }

  onEndTimeUpdated(time: Date): void {
    const updated = new Date();
    updated.setTime((this.range && this.range[1]?.getTime()) || new Date().getTime());
    updated.setHours(time.getHours());
    updated.setMinutes(time.getMinutes());
    updated.setMilliseconds(0);
    updated.setSeconds(0);
    this.range = [this.range[0], updated];
    this.validateForm();
  }

  onDateRangeUpdated() {
    if (this.range && this.range.length === 2 && !this.range[1]) {
      this.range = [this.range[0], this.range[0]];
    }
    this.onStartTimeUpdated(this.startTime);
    this.onEndTimeUpdated(this.endTime);
    this.validateForm();
  }
  get isMobile() {
    return this.mobileService.isMobileSize();
  }

  isDateWithinRange(date: any) {
    const parsedMin = new Date(date.year, date.month, date.day, 0, 0, 0, 0);
    const parsedMax = new Date(date.year, date.month, date.day, 23, 59, 59);
    return this.min && this.max && this.max >= parsedMin && this.min <= parsedMax;
  }

  private validateForm() {
    if (this.range && this.range[0] && this.range[1]) {
      this.isEndAfterStart = this.showTimes ? this.range[0] < this.range[1] : this.range[0] <= this.range[1];
    } else {
      this.isEndAfterStart = false;
    }
  }
}
