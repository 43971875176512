<div class="modal" id="{{ id }}" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog zoomIn faster animated" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" id="{{ id }}-title">Register for Notifications</h4>
			</div>
			<div class="modal-body">
				<div class="col">
					<div class="row flex-column d-block" *ngIf="company && event && user">
						<p>
							<span class="bold">{{ company.name }}</span> can notify you when applications are open to <span class="bold event-master-blue-color">{{ event.name }}</span
							>. To do this they'll need access to some basic information from your profile.
						</p>
						<p class="mt-3">
							Do you grant <span class="bold">{{ company.name }}</span> permission to:
						</p>
						<ul>
							<li>
								<p>Access your name, email address and profile picture.</p>
								<user-basic-permissions-card [showDelete]="false"> </user-basic-permissions-card>
							</li>
							<li>
								<p>
									To contact you via email regarding applications to <span class="bold event-master-blue-color">{{ event.name }}</span
									>.
								</p>
							</li>
							<li>
								<p>To contact you about similar events that they think you might be interested in.</p>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
				<button type="button" class="btn btn-success" [disabled]="isSaving" (click)="save()"><fa-icon [icon]="['fas', 'cog']" [spin]="true" class="mr-2" *ngIf="isSaving"></fa-icon>Agreed, notify me!</button>
			</div>
		</div>
	</div>
</div>
