import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { AnalyticsService } from '../helpers/analytics.service';
import { CompanyService } from './company.service';
import { CurrentUser, CustomClaims, User, parseUserData } from '../models/user.model';

@Injectable()
export class AuthService {
  private currentUser: CurrentUser;
  private onLoggedInUserChange = new EventEmitter<CurrentUser>();

  private firstLoadPromise: Promise<void>;

  private currentUserPromise: Promise<CurrentUser>;

  private isAuthLoggedIn: boolean = false;
  private currentFirebaseUser: firebase.default.User;
  resentlyRequestedEmailVerificationEmail: boolean = false;

  constructor(
    private companyService: CompanyService,
    private fns: AngularFireFunctions,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private analyticsService: AnalyticsService,
  ) {
    this.analyticsService.reportInit();
    this.firstLoadPromise = new Promise(async (resolve, _) => {
      this.angularFireAuth.authState.subscribe(
        async authUser => {
          if (authUser) {
            this.currentFirebaseUser = authUser;
            if (!this.isAuthLoggedIn) {
              try {
                this.isAuthLoggedIn = true;
                const claims = await this.getCustomClaimsForUser(false);
                if (!claims?.emailHash) {
                  //setLoggedInUser is done at the end of the claims refresh
                  await this.refreshCustomClaimsForUserIfNotSet();
                } else {
                  await this.setLoggedInUser(authUser, claims);
                }
                this.analyticsService.reportLogin();
                resolve();
              } catch (e) {
                console.error('Error while trying to log in', e);
                this.resetAuthState();
              } finally {
                resolve();
              }
            }
          } else {
            this.resetAuthState();
            resolve();
          }
        },
        error => resolve(),
      );
    });
  }

  private resetAuthState() {
    this.isAuthLoggedIn = false;
    this.currentFirebaseUser = null;
    this.setLoggedInUser(null, null);
  }

  isLoggedIn() {
    return !!this.currentUser;
  }

  getLoggedInUser(): Promise<CurrentUser> {
    return this.firstLoadPromise.then(() => this.currentUserPromise);
  }

  async getLoggedInUserForceRefresh(): Promise<CurrentUser> {
    console.log('forcing refresh of user claims');
    const claims = await this.getCustomClaimsForUser(true);
    const user = await this.angularFireAuth.currentUser;
    user.reload();
    return this.setLoggedInUser(await this.angularFireAuth.currentUser, claims);
  }

  logout() {
    this.isAuthLoggedIn = false;
    this.currentFirebaseUser = null;
    localStorage.removeItem('onSiteMode');
    return this.angularFireAuth.signOut();
  }

  onLoggedInUserChanged(): Observable<CurrentUser> {
    return this.onLoggedInUserChange;
  }

  sendVerificationEmailIfRequired(): Promise<void> {
    this.resentlyRequestedEmailVerificationEmail = true;
    return this.currentFirebaseUser.sendEmailVerification();
  }

  async getAuthToken(forceRefresh: boolean = false): Promise<string> {
    try {
      const currentUser = !forceRefresh && this.currentFirebaseUser ? this.currentFirebaseUser : await this.angularFireAuth.currentUser;
      return currentUser ? currentUser.getIdToken(forceRefresh) : Promise.reject({ message: 'Not currently logged in' });
    } catch (e) {
      console.error('An error occurred while getting auth token', e);
    }
  }

  refreshCustomClaimsForUser(): Promise<void> {
    return this.refreshCustomClaims('customClaims-setupUserCustomClaims').then(() => {
      setTimeout(() => this.refreshCustomClaims('customClaims-refreshAsyncUserCustomClaims'), 5000);
    });
  }

  isLoggedInAsAdminForCompany() {
    const companyId = this.companyService.getId();
    return this.getLoggedInUser().then(currentUser => {
      return currentUser && companyId && this.currentUser?.claims?.adminForCompanys?.includes(companyId);
    });
  }

  isLoggedInAsTimesheetManagerForCompany() {
    const companyId = this.companyService.getId();
    return this.getLoggedInUser().then(currentUser => {
      return currentUser && companyId && this.currentUser?.claims?.timesheetManagerForCompanys?.includes(companyId);
    });
  }

  isLoggedInAsSuperAdminForCompany() {
    const companyId = this.companyService.getId();
    return this.getLoggedInUser().then(currentUser => {
      return (
        currentUser &&
        companyId &&
        this.currentUser?.claims?.superAdminForCompanys?.includes(companyId) &&
        this.currentUser?.claims?.adminForCompanys?.includes(companyId)
      );
    });
  }

  async refreshCustomClaimsForUserIfNotSet() {
    if (!this.currentUser?.claims?.emailHash) {
      console.log('no email hash found, so refreshing before creation');
      await this.refreshCustomClaimsForUser();
    }
  }

  private async refreshCustomClaims(functionName: string): Promise<void> {
    const token = await this.getAuthToken(true);
    try {
      const callable = this.fns.httpsCallable(functionName, { timeout: 30000 });
      const result = await callable({ idToken: token }).toPromise();

      if (result.updated || !this.currentUser?.claims?.emailHash) {
        return this.getCustomClaimsForUser(true)
          .then(async claims => this.setLoggedInUser(await this.angularFireAuth.currentUser, claims))
          .then(() => undefined);
      } else {
        return;
      }
    } catch (e) {
      console.error(e);
      throw new Error(functionName + ' call failed');
    }
  }

  private async getCustomClaimsForUser(forceRefresh: boolean = false): Promise<CustomClaims> {
    try {
      const currentUser = this.currentFirebaseUser || (await this.angularFireAuth.currentUser);
      if (!currentUser) {
        return Promise.resolve(new CustomClaims({}));
      }
      const idTokenResult = await currentUser.getIdTokenResult(forceRefresh);
      if (idTokenResult) {
        return new CustomClaims(idTokenResult.claims);
      } else {
        return Promise.resolve(new CustomClaims({}));
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private setLoggedInUser(authUser: firebase.default.User, claims: CustomClaims): Promise<CurrentUser> {
    if (authUser !== null && claims?.emailHash) {
      this.currentUserPromise = this.getUserByIdNullable(claims.emailHash)
        .then(user => (this.currentUser = new CurrentUser(user, authUser, claims)))
        .then(() => this.onLoggedInUserChange.emit(this.currentUser))
        .then(() => this.currentUser);
    } else {
      this.currentUser = null;
      this.onLoggedInUserChange.emit(null);
      this.currentUserPromise = Promise.resolve(null);
    }

    return this.currentUserPromise;
  }

  getUserByIdNullable(id: string): Promise<User> {
    console.info('getting full user: ' + id);
    const result = this.firestore.doc(`users/${id}`);
    return result
      .get()
      .toPromise()
      .then(doc => (doc.exists ? parseUserData(doc) : null));
  }
}
