import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { RouteReusableStrategy } from './route-reusable-strategy';
import { HttpService } from './http/http.service';
import { UnsavedChangesDialogComponent } from './guards/unsaved-changes-dialog.component';
import { DeviceService } from './device/device.service';
import { SentryErrorHandler } from './error-handler.service';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FirebaseConfigHelper } from '../../firebase-config/firebase-config.helper';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { ToastrModule } from 'ngx-toastr';
import { LinkService } from './link.service';
import { EventService } from './services/event.service';
import { FileStorageService } from './services/file-storage.service';
import { RoleService } from './services/role.service';
import { StaffService } from './services/staff.service';
import { ImageHelperService } from './services/image-helper.service';
import { ApplicationService } from './services/application.service';
import { UserService } from './services/user.service';
import { CompanyService } from './services/company.service';
import { ShareHelper } from './helpers/share.helper';
import { NavigationStateService } from './helpers/navigation-state.service';
import { AnalyticsService } from './helpers/analytics.service';
import { MetricsService } from './helpers/metrics.service';
import { ReferenceService } from './services/reference.service';
import { TeamService } from './services/team.service';
import { PayrollService } from './services/payroll.service';
import { UIBlockedService } from './helpers/ui-blocked.service';
import { DepositService } from './services/deposit.service';
import { UserCacheService } from '@app/core/services/user-cache.service';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AngularFireModule,
    ToastrModule.forRoot(),
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
  ],
  providers: [
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    { provide: FIREBASE_OPTIONS, useValue: FirebaseConfigHelper.getConfig() }, // this gets push into in initializeApp on AngularFireModule magically
    { provide: REGION, useValue: 'europe-west2' },
    DeviceService,
    SentryErrorHandler,
    AngularFirestore,
    LinkService,
    EventService,
    FileStorageService,
    RoleService,
    DepositService,
    StaffService,
    ImageHelperService,
    ApplicationService,
    UserService,
    AuthService,
    UserCacheService,
    ReferenceService,
    CompanyService,
    ShareHelper,
    NavigationStateService,
    AnalyticsService,
    MetricsService,
    TeamService,
    PayrollService,
    UIBlockedService,
  ],
  declarations: [UnsavedChangesDialogComponent],
  exports: [CommonModule, UnsavedChangesDialogComponent, HttpClientModule, RouterModule, ToastrModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private firestore: AngularFirestore) {
    if (localStorage.getItem('onSiteMode') === 'true') {
      firestore.firestore.enablePersistence({ synchronizeTabs: true });
    }
    // this.firestore.firestore.settings({ experimentalForceLongPolling: true });
    // firebase.firestore.setLogLevel('debug');
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
