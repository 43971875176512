import { DateRange, Timestamp } from './firebase.model';

export class Shift {
  id: string;
  name: string;
  times: DateRange;

  static toJSON(shift: Shift): Shift {
    return {
      id: shift.id,
      name: shift.name,
      times: { start: shift.times.start, end: shift.times.end },
    };
  }
}

export class Position {
  id?: string;
  name: string;
  rate: number;
  colourId: number;

  static toJSON(position: Position) {
    return {
      name: position.name,
      rate: position.rate,
      colourId: position.colourId,
    };
  }

  static parse(doc: any): Position {
    console.log('parsing potions');
    const position = Object.assign(new Position(), doc.data());
    position.id = doc.id;
    return position;
  }
}

export class ShiftPattern {
  id: string;
  roleId: string;
  positionId: string;
  assignedTo: AssignedTo;

  shiftIds: {} = {};

  static toJSON(shiftPatterns: ShiftPattern) {
    const shiftIds = {};
    Object.keys(shiftPatterns.shiftIds || {}).forEach(key => {
      if (shiftPatterns.shiftIds[key]) {
        shiftIds[key] = shiftPatterns.shiftIds[key];
      }
    });

    return {
      id: shiftPatterns.id,
      roleId: shiftPatterns.roleId,
      positionId: shiftPatterns.positionId,
      assignedTo: shiftPatterns.assignedTo || null,
      shiftIds: shiftIds,
    };
  }

  static parse(json: any): ShiftPattern {
    console.log('Parsing shift pattern');
    return Object.assign(new ShiftPattern(), json);
  }
}

export class Team {
  id?: string;
  name: string;
  openingTimes: DateRange[];
  shifts: Shift[];
  shiftPatterns: ShiftPattern[];

  static toJSON(team: Team) {
    return {
      name: team.name,
      openingTimes: team.openingTimes || [],
      shifts: (team.shifts || []).map(Shift.toJSON),
      shiftPatterns: (team.shiftPatterns || []).map(ShiftPattern.toJSON),
    };
  }

  static parse(doc: any): Team {
    console.log('parsing retrieved team', doc ? doc.data() : null);
    const team = Object.assign(new Team(), doc.data());
    team.id = doc.id;
    team.shiftPatterns = (team.shiftPatterns || []).map(ShiftPattern.parse);
    console.log(team);
    return team;
  }
}

export class AssignedTo {
  id?: string;
  label?: string;
}

export class TimesheetWorkingTimes {
  clocked: string[]; //odd == IN even == OUT. HH:mm
  added: Timestamp;
  overrides?: {
    assignedTo?: AssignedTo;
    positionId?: string;
  };

  static toJSON(workingTimes: TimesheetWorkingTimes) {
    return {
      added: workingTimes?.added || null,
      clocked: workingTimes?.clocked || [],
      overrides: workingTimes?.overrides
        ? {
            assignedTo:
              (workingTimes.overrides.assignedTo && workingTimes.overrides.assignedTo.id) || workingTimes.overrides.assignedTo.label
                ? {
                    id: workingTimes.overrides.assignedTo.id || null,
                    label: workingTimes.overrides.assignedTo.label || null,
                  }
                : null,
            positionId: workingTimes.overrides.positionId || null,
          }
        : null,
    };
  }
}

export class Timesheet {
  id?: string;
  workingTimes: {};
  additional: {};

  static parse(doc: any): Timesheet {
    if (doc?.data()) {
      console.log('parsing retrieved timesheet', doc ? doc.data() : null);
      const timesheet = Object.assign(new Timesheet(), doc.data());
      timesheet.id = doc.id;
      return timesheet;
    } else {
      return null;
    }
  }

  static toJSON(timesheet: Timesheet): any {
    const clonedWorkingTimes = {};
    Object.keys(timesheet.workingTimes || {}).forEach(shiftPatternId => {
      clonedWorkingTimes[shiftPatternId] = TimesheetWorkingTimes.toJSON(timesheet.workingTimes[shiftPatternId]);
    });
    const clonedAdditional = {};
    Object.keys(timesheet.additional || {}).forEach(id => {
      clonedAdditional[id] = TimesheetWorkingTimes.toJSON(timesheet.additional[id]);
    });
    return {
      workingTimes: clonedWorkingTimes,
      additional: clonedAdditional,
    };
  }
}
