import { Component, Input } from '@angular/core';
import { ApplicationHistory } from '@app/core/services/application.service';
import { Dialog } from '../../dialog/dialog.component';
import { LinkService } from '@app/core/link.service';
import { Location } from '@angular/common';

@Component({
  selector: 'application-history',
  templateUrl: './application-history.component.html',
  styleUrls: ['./application-history.component.scss'],
})
export class ApplicationHistoryComponent {
  @Input()
  isAdmin = false;

  @Input()
  history: ApplicationHistory[];
}
