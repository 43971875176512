import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';

import { LogoutComponent } from './logout.component';

import { Shell } from '@app/shell/shell.service';
import { RegisterComponent } from './register.component';
import { UnauthenticationGuard } from '@app/core/guards/unauthentication.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'login',
      component: LoginComponent,
      data: { title: 'Log In' },
      canActivate: [UnauthenticationGuard]
    }, {
      path: 'register',
      component: RegisterComponent,
      data: { title: 'Register' },
      canActivate: [UnauthenticationGuard]
    },
    {
      path: 'logout',
      component: LogoutComponent,
      data: { title: 'Log Out' }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRoutingModule { }
