<div>
  <fa-stack
    [class.clickable]="isResponded && isAdmin"
    (click)="isResponded ? openDialogIfAdmin() : undefined"
    [placement]="labelPlacement"
    ngbTooltip="{{ isResponded ? 'Reference validated' : 'Reference not yet responded' }}"
  >
    <fa-icon [icon]="['fas', 'file']" [class.text-muted]="!isResponded" stackItemSize="2x"> </fa-icon>
    <fa-icon class="sub-icon" [icon]="['fas', isResponded ? 'check' : 'clock']" stackItemSize="1x" [class.text-success]="isResponded" [class.text-warning]="!isResponded"> </fa-icon>
  </fa-stack>

  <reference-response-review-dialog *ngIf="isAdmin"></reference-response-review-dialog>
</div>
