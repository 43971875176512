import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { AnalyticsService } from '../helpers/analytics.service';
import {
  CurrentUser,
  User,
  UserMetadataForCompany,
  parseUserMetadata,
  parseUserData,
  parseUserExtrasData,
  CustomClaims,
  parseUser,
  UserBasic,
  parseUserBasicData,
  UserExtras,
  UserSettings,
} from '../models/user.model';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {
  constructor(private fns: AngularFireFunctions, private firestore: AngularFirestore, private analyticsService: AnalyticsService, private authService: AuthService) {}

  async createCurrentUser(user: User, recaptchaToken: string): Promise<CurrentUser> {
    const json = User.toJSON(user);
    const token = await this.authService.getAuthToken();
    await this.fns
      .httpsCallable('user-createUser', { timeout: 30000 })({
        idToken: token,
        user: json,
        recaptchaToken,
      })
      .toPromise();

    this.authService.sendVerificationEmailIfRequired();
    const currentUser = await this.authService.getLoggedInUser();
    const updatedUser = await this.getUserById(currentUser.id);
    currentUser.user = updatedUser;
    this.analyticsService.reportRegisterUser();
    return currentUser;
  }

  async updateCurrentUser(user: User): Promise<CurrentUser> {
    const json = User.toJSON(user);
    const token = await this.authService.getAuthToken();
    await this.fns
      .httpsCallable('user-updateUser', { timeout: 30000 })({
        idToken: token,
        user: json,
        id: user.id,
      })
      .toPromise();

    const currentUser = await this.authService.getLoggedInUser();
    const updatedUser = await this.getUserById(currentUser.id);
    currentUser.user = updatedUser;
    return currentUser;
  }

  getUserByIdNullable(id: string) {
    return this.authService.getUserByIdNullable(id);
  }

  async getUserById(id: string): Promise<User> {
    const user = await this.getUserByIdNullable(id);
    if (!user) {
      throw new Error(id + ' not found');
    }
    return user;
  }

  setCompanyAccessToPayroll(currentUser: CurrentUser, companyId: string, approved: boolean): Promise<CurrentUser> {
    return this.authService
      .getAuthToken()
      .then(token =>
        this.fns
          .httpsCallable('user-setCompanyAccessToPayroll', { timeout: 30000 })({
            idToken: token,
            companyId,
            approved,
          })
          .toPromise(),
      )
      .then(() => this.getUserById(currentUser.user.id))
      .then(updatedUser => {
        currentUser.user = updatedUser;
        return currentUser;
      });
  }

  getUserBasicById(id: string): Promise<UserBasic> {
    console.info('getting basic user: ' + id);
    const result = this.firestore.doc(`users/${id}/basic/${id}`);
    return result
      .get()
      .toPromise()
      .then(doc => (doc.exists ? parseUserBasicData(doc) : Promise.reject(id + 'not found')));
  }

  getUserExtras(userId: string, companyId: string): Promise<UserExtras> {
    console.info('getting user extras for user: ' + userId + ' companyId' + companyId);
    const result = this.firestore.doc(`users/${userId}/extras/${companyId}`);
    return result
      .get()
      .toPromise()
      .then(doc => (doc.exists ? parseUserExtrasData(doc) : Promise.reject(userId + ' companyId: ' + companyId + ' extras not found')));
  }

  patchUserExtras(userId: string, companyId: string, patch: any): Promise<void> {
    console.info('patching user extras for user: ' + userId + ' companyId' + companyId, patch);

    const result = this.firestore.doc(`users/${userId}/extras/${companyId}`);
    return result.set(patch, { merge: true });
  }

  async deleteCurrentUser() {
    const currentUser = await this.authService.getLoggedInUser();
    const token = await this.authService.getAuthToken(true);
    await this.fns
      .httpsCallable('user-deleteCurrentUser', { timeout: 30000 })({ idToken: token })
      .toPromise();
    currentUser.user = null;
  }

  getUserMetadataForCompany(id: string, companyId: string): Promise<UserMetadataForCompany> {
    console.info('getting metadata for user: ' + id + ' company: ' + companyId);
    return this.firestore
      .doc(`users/${id}/metadata/${companyId}`)
      .get()
      .toPromise()
      .then(doc => (doc.exists ? parseUserMetadata(doc.data(), doc.id) : null));
  }

  getUserSettings(userId: string): Promise<UserSettings> {
    console.info('getting user settings for user: ' + userId);
    const result = this.firestore.doc(`users/${userId}/settings/settings`);
    return result
      .get()
      .toPromise()
      .then(doc => (doc.exists ? (doc.data() as UserSettings) : { eventMasterEmailEvents: false, eventMasterEmailNews: false }));
  }

  patchUserSettings(userId: string, patch: any): Promise<void> {
    console.info('patching user settings for user: ' + userId, patch);
    const result = this.firestore.doc(`users/${userId}/settings/settings`);
    return result.set(patch, { merge: true });
  }

  recalculateUserApplicationsMetadata(companyId: string): Promise<void> {
    return this.authService
      .getAuthToken()
      .then(token =>
        this.fns
          .httpsCallable('user-recalculateUserApplicationsMetadata', { timeout: 120000 })({
            idToken: token,
            companyId,
          })
          .toPromise(),
      )
      .then(() => {
        return new Promise((resolve, reject) => setTimeout(() => resolve(), 10000));
      });
  }

  recalculateUserInterestMetadata(companyId: string): Promise<void> {
    return this.authService
      .getAuthToken()
      .then(token =>
        this.fns
          .httpsCallable('user-recalculateUserInterestMetadata', { timeout: 120000 })({
            idToken: token,
            companyId,
          })
          .toPromise(),
      )
      .then(() => {
        return new Promise((resolve, reject) => setTimeout(() => resolve(), 10000));
      });
  }

  recalcuateApplicationsMetadata(companyId: string): Promise<void> {
    return this.authService
      .getAuthToken()
      .then(token =>
        this.fns
          .httpsCallable('company-resyncCompanyApplicationsMetadata', { timeout: 120000 })({
            idToken: token,
            companyId,
          })
          .toPromise(),
      )
      .then(() => {
        return new Promise((resolve, reject) => setTimeout(() => resolve(), 10000));
      });
  }

  resyncUserLiteReferences(companyId: string): Promise<void> {
    return this.authService
      .getAuthToken()
      .then(token =>
        this.fns
          .httpsCallable('user-forceUpdateOfUserLiteReferences', { timeout: 120000 })({
            idToken: token,
            companyId,
          })
          .toPromise(),
      )
      .then(() => {
        return new Promise((resolve, reject) => setTimeout(() => resolve(), 15000));
      });
  }
}
