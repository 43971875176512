import { AfterContentInit, Component, Input } from '@angular/core';
import { User, DocumentType, UserLite } from '@app/core/models/user.model';
import { FileStorageService } from '@app/core/services/file-storage.service';
import { ObjectCache } from '@app/shared/storage/object-cache';
import { UserCacheService } from '../../../core/services/user-cache.service';

@Component({
  selector: 'user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent {
  private static imageCache: ObjectCache<string> = new ObjectCache<string>(ObjectCache.ONE_DAY);

  @Input()
  set imageUrl(imageUrl: string) {
    this._imageUrl = imageUrl;
    this.init();
  }

  @Input()
  set userId(userId: string) {
    this._userId = userId;
    this.init();
  }

  get userId() {
    return this._userId;
  }

  @Input()
  set user(user: User) {
    this._user = user;
    this.userId = user ? user.id : null;
    this.imageUrl = user ? user.image : null; //triggers the imageUrl setter
  }

  get user() {
    return this._user;
  }

  get imageUrl() {
    return this._imageUrl;
  }

  image: string;

  isNoImage: boolean = false;

  @Input()
  size: string = '10rem';

  private _user: User;
  private _imageUrl: string;
  private _userId: string;

  constructor(protected fileStorageService: FileStorageService, protected userCacheService: UserCacheService) {}

  async init() {
    if (!this.imageUrl && this.userId) {
      try {
        const user = await this.userCacheService.getUserBasicById(this.userId);
        this._imageUrl = user.image;
      } catch (e) {
        console.error('user was not found, so cant lookup image: ' + this.userId, e);
      }
    }
    if (this.imageUrl && this.userId) {
      this.setup(this.imageUrl, this.userId);
    } else {
      this.image = null;
      this.isNoImage = true;
    }
  }

  private setup(imageUrl: string, userId: string) {
    this.isNoImage = false;
    if (imageUrl === DocumentType.PROFILE_IMAGE) {
      UserAvatarComponent.imageCache
        .getOrUpdate(userId + DocumentType.PROFILE_IMAGE, () => {
          console.info('retrieved profile image');
          return this.fileStorageService.getProfileImageForUser(userId);
        })
        .then((link: string) => (this.image = link));
    } else if (imageUrl) {
      this.image = imageUrl;
    } else {
      this.image = null;
      this.isNoImage = true;
    }
  }

  static resetCacheForUserId(userId: string) {
    this.imageCache.resetId(userId + DocumentType.PROFILE_IMAGE);
    UserCacheService.resetId(userId);
  }
}
