import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as clipboard from 'clipboard-polyfill/';
import { Router } from '@angular/router';
import { CompanyService } from '../services/company.service';

@Injectable()
export class ShareHelper {
  constructor(private toastrService: ToastrService, private route: Router, private companyService: CompanyService) {

  }

  shareLink(link: string, title: string, message: string) {
    if (navigator['share']) {
      this.companyService.getCompany().then(company =>
        navigator['share']({
          title,
          text: message,
          url: `${this.processHostUrl(company.url)}${link}`
        }).catch((e: any) => {
          console.info('cancelled share');
        }));
    } else {
      this.toastrService.info('URL Copied');
      clipboard.writeText(`${this.processHostUrl(location.host)}${link}`);
    }
  }

  shareCurrentUrl() {
    this.shareLink(this.route.url, 'Check this out on event master', 'Hey, I saw this on event master and thought you might be interested. Check it out!');
  }

  private processHostUrl(host: string) {
    if (host[host.length - 1] !== '/') {
      host += '/';
    }
    return host;
  }
}
