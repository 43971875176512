<div class="modal" id="unsaved-dialog" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog zoomIn faster animated" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="unsaved-dialog-title">You have unsaved changes</h5>
			</div>
			<div class="modal-body">
				<div class="col">
					<div class="row">
						<p class="message"></p>
					</div>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" class="btn btn-danger confirm">Exit anyway</button>
				<button type="button" class="btn btn-info cancel">Continue editing</button>
			</div>
		</div>
	</div>
</div>
