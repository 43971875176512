import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { LinkService } from '../link.service';
import { UIBlockedService } from '../helpers/ui-blocked.service';

export interface IsSaving {
  isSavingPromise: Promise<void>;
}

@Injectable({
  providedIn: 'root'
})
export class IsSavingFormGuard implements CanDeactivate<IsSaving> {

  constructor(private uiBlockedService: UIBlockedService) {

  }
  // stop user navigating away if something is currently saving
  canDeactivate(component: IsSaving, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (component.isSavingPromise) {
      console.log('Doing guard now');
      return this.uiBlockedService.blockForPromise(component.isSavingPromise.then(() => true).catch(() => true));
    }
    return true;
  }

}
