import { Component, Input } from '@angular/core';
import { Dialog } from './dialog.component';
import { Location } from '@angular/common';
import { LinkService } from '@app/core/link.service';

export class ConfirmOptions {
  title: string;
  message: string;
  messageHtml?: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: () => Promise<any>;
  onCancel?: () => void;
  isLarge?: boolean;
}
@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styles: ['p:last-child { margin-bottom: 0; }', '.large { max-width: 768px}'],
})
export class ConfirmDialogComponent extends Dialog {
  @Input()
  centered = false;

  options: ConfirmOptions;
  messageParagraphs: string[];

  isSaving = false;

  constructor(protected location: Location, protected linkService: LinkService) {
    super(location, linkService, 'confirm-dialog');
  }

  openConfirm(options: ConfirmOptions) {
    this.options = options;
    this.messageParagraphs = this.options.message.split('\n\n');
    setTimeout(() => super.open());
  }

  select() {
    this.close();
  }

  onCancel() {
    if (this.options.onCancel) {
      this.options.onCancel();
    }
    this.close();
  }

  async onConfirm() {
    if (this.options.onConfirm) {
      this.isSaving = true;
      await this.options.onConfirm();
      this.isSaving = false;
    }
    this.close();
  }
}
