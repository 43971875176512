import { Component, Input, AfterContentInit } from '@angular/core';
import { DocumentType, Document } from '@app/core/models/user.model';
import { FileStorageService } from '@app/core/services/file-storage.service';

@Component({
  selector: 'document-label',
  templateUrl: './document-label.component.html',
})
export class DocumentLabelComponent {
  @Input()
  set document(document: Document) {
    this._document = document;
    this.link = null;
    if (document) {
      this.icon = DocumentLabelComponent.getIconForType(document.type);
      this.name = DocumentLabelComponent.getNameForType(document.type);

      this.fileStorageService
        .getDocument(this.document)
        .then(link => (this.link = link))
        .catch(error => console.warn('Got error while getting document', this.document));
    }
  }

  get document(): Document {
    return this._document;
  }

  @Input()
  set type(type: DocumentType) {
    if (!this.document) {
      this.icon = DocumentLabelComponent.getIconForType(type);
      this.name = DocumentLabelComponent.getNameForType(type);
    }
  }

  @Input()
  userId: string;

  @Input()
  light: boolean = false;

  @Input()
  small: boolean = false;

  @Input()
  showError: boolean = true;

  icon: string[];
  name: string;
  link: string;

  private _document: Document;

  constructor(private fileStorageService: FileStorageService) {}

  static getIconForType(type: DocumentType): string[] {
    switch (type) {
      case DocumentType.CV:
        return ['far', 'file-pdf'];
      case DocumentType.PASSPORT:
        return ['fas', 'passport'];
      case DocumentType.WORK_VISA:
        return ['fas', 'id-badge'];
      case DocumentType.NI_CARD:
        return ['fas', 'credit-card'];
      case DocumentType.NI_PROOF:
        return ['fas', 'file-word'];
      case DocumentType.BIRTH_CERT:
        return ['fas', 'baby'];
      default:
        return [];
    }
  }

  static getNameForType(type: DocumentType) {
    switch (type) {
      case DocumentType.CV:
        return 'CV';
      case DocumentType.PASSPORT:
        return 'Passport';
      case DocumentType.WORK_VISA:
        return 'Work Visa';
      case DocumentType.NI_CARD:
        return 'NI Card';
      case DocumentType.NI_PROOF:
        return 'Proof of NI';
      case DocumentType.BIRTH_CERT:
        return 'Birth Certificate';
      default:
        return '';
    }
  }
}
