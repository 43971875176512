import { toInitCap, trimOrNull } from './helpers.model';
import { Timestamp } from '@app/core/models/firebase.model';
import sortBy from 'lodash-es/sortBy';
import reverse from 'lodash-es/reverse';
export class Reference {
  id?: string;

  company: string;
  refereeName: string;
  telephone: string;
  email: string;
  order: number;

  responseCodes: {
    code: string;
    date: Timestamp;
  }[];
  responseIds: string[];

  getLatestCode() {
    const sorted = reverse(sortBy(this.responseCodes || [], code => code.date));
    return sorted.length ? sorted[0] : null;
  }

  toJSON() {
    const result = {
      company: toInitCap(trimOrNull(this.company)),
      refereeName: toInitCap(trimOrNull(this.refereeName)),
      telephone: trimOrNull(this.telephone),
      email: trimOrNull(this.email),
      order: this.order || 0,
    };
    return result;
  }
}

export class ReferenceResponse {
  date: Timestamp;
  questions: {
    question: string;
    answer: string;
  }[];
  ratings: {
    type: string;
    value: number;
  }[];

  toJSON() {
    return {
      questions: this.questions
        ? this.questions.map(q => {
            return {
              question: trimOrNull(q.question),
              answer: trimOrNull(q.answer),
            };
          })
        : [],
      ratings: this.ratings
        ? this.ratings.map(r => {
            return {
              type: trimOrNull(r.type),
              value: r.value || 0,
            };
          })
        : [],
    };
  }
}

export function parseReferenceResponse(doc: any): ReferenceResponse {
  console.log('Parsing retrieved reference response');
  const response = Object.assign(new ReferenceResponse(), doc.data());
  response.id = doc.id;
  return response;
}

export function parseReference(doc: any): Reference {
  console.log('Parsing retrieved reference');
  const reference = Object.assign(new Reference(), doc.data());
  reference.id = doc.id;
  return reference;
}
