import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class UIBlockedService {

  @Output()
  onUpdate = new EventEmitter<string>();

  private set isBlocked(isBlocked: string) {
    this._isBlocked = isBlocked;
    this.onUpdate.emit(isBlocked);
  }

  private get isBlocked() {
    return this._isBlocked;
  }

  private _isBlocked: string;

  blockForPromise(promise: Promise<any>, label: string = 'Waiting for Operation to complete') {
    this.isBlocked = label;
    return promise.finally(() => {
      this.isBlocked = null;
    });
  }
}
