import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { ApplicationService, ApplicationStatus } from '@app/core/services/application.service';
import { ConfirmDialogComponent } from '@app/shared/dialog/confirm-dialog.component';
import { LinkService } from '@app/core/link.service';
import { UserService } from '@app/core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ApplicationStatusChangeDialogComponent } from './application-status-change-dialog.component';
import { ErrorHandlerService } from '@app/core/error-handler.service';
import { ApplicationStatusComponent } from '../application-status.component';
import { ApplicationCardBase } from './application-card-base.component';
import { ApplicationSummaryDialogComponent } from '../summary/application-summary-dialog.component';
import { RoleService } from '@app/core/services/role.service';
import { DepositService } from '@app/core/services/deposit.service';
import { CompanyService } from '@app/core/services/company.service';
import { AuthService } from '@app/core/services/auth.service';
import { EventService } from '@app/core/services/event.service';

@Component({
  selector: 'user-application-card',
  templateUrl: './user-application-card.component.html',
  styleUrls: ['./application-card-base.component.scss', './user-application-card.component.scss'],
})
export class UserApplicationCardComponent extends ApplicationCardBase {
  @ViewChild(ApplicationSummaryDialogComponent)
  applicationSummaryDialog: ApplicationSummaryDialogComponent;

  @Input()
  isShowingEventInfo: boolean = false;

  @Input()
  isNew: boolean = false;

  @ViewChild(ConfirmDialogComponent)
  configDialog: ConfirmDialogComponent;

  @ViewChild(ApplicationStatusChangeDialogComponent)
  applicationStatusChangeDialog: ApplicationStatusChangeDialogComponent;

  constructor(
    protected errorHandlerService: ErrorHandlerService,
    protected toastr: ToastrService,
    protected linkService: LinkService,
    protected applicationService: ApplicationService,
    protected authService: AuthService,
    protected roleService: RoleService,
    protected depositService: DepositService,
    protected companyService: CompanyService,
    protected eventService: EventService,
  ) {
    super(errorHandlerService, toastr, linkService, applicationService, authService, roleService, depositService, companyService, eventService);
  }

  openSummaryDialog() {
    this.applicationSummaryDialog.openForApplication(this.application, this.event);
  }
  statusText(status: ApplicationStatus) {
    return ApplicationStatusComponent.statusText(status, false);
  }
}
