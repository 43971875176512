<div class="bank-info" [class.is-profile-complete]="(isProfileComplete || !showSectionError) && !isLoading">
  <div class="absolute-fill vertical-center" *ngIf="isLoading || !user">
    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="text-center"></fa-icon>
  </div>
  <div class="row row-no-margin">
    <div class="flex-none avatar-col text-center-mobile vertical-center flex-column">
      <user-avatar *ngIf="user && !isLoading" class="desktop ml-2 mr-5" [user]="user" size="10rem"></user-avatar>
      <user-avatar *ngIf="user && !isLoading" class="mobile" [user]="user" size="5rem"></user-avatar>
      <div class="avatar-placeholder" *ngIf="!user || isLoading"></div>
      <span *ngIf="!user || !user.image || isLoading" class="error text-center">Required</span>
    </div>
    <div class="body-col flex-auto p-2">
      <div class="row">
        <div class="col">
          <div class="row row-no-margin flex-column">
            <h5>Personal Information:</h5>
            <span class="info">
              <fa-icon [icon]="['fas', 'user-tie']" placement="right" ngbTooltip="Full name"></fa-icon>
              <p>
                <span *ngIf="user?.getTitle() && !isLoading">{{ user.getTitle() }} </span>
                <span *ngIf="!user?.getTitle() || isLoading" class="error">Required </span>
                <span *ngIf="user?.firstName && !isLoading">{{ user.firstName }} </span>
                <span *ngIf="!user?.firstName || isLoading" class="error">Required </span>
                <span *ngIf="user?.lastName && !isLoading">{{ user.lastName }} </span>
                <span *ngIf="!user?.lastName || isLoading" class="error">Required </span>
              </p>
            </span>
            <span class="info">
              <fa-icon [icon]="['fas', 'venus-mars']" placement="right" ngbTooltip="Gender"></fa-icon>
              <p *ngIf="user?.gender && !isLoading">{{ user.gender | upperCaseFirstLetter }}</p>
              <p *ngIf="!user?.gender || isLoading" class="error">Required</p>
            </span>
            <span class="info">
              <fa-icon [icon]="['fas', 'birthday-cake']" placement="right" ngbTooltip="Date of Birth"></fa-icon>
              <p *ngIf="user?.dob && !isLoading">{{ user.dob.toDate() | amDateFormat: 'DD/MM/YYYY' }}</p>
              <p *ngIf="!user?.dob || isLoading" class="error">Required</p>
            </span>
            <span class="info">
              <fa-icon [icon]="['fas', 'globe-europe']" placement="right" ngbTooltip="Nationality"></fa-icon>
              <p *ngIf="user?.nationality && !isLoading">{{ user.nationality }}</p>
              <p *ngIf="!user?.nationality || isLoading" class="error">Required</p>
            </span>
            <span class="info">
              <fa-icon [icon]="['fas', 'envelope']" placement="right" ngbTooltip="Email"></fa-icon>
              <p *ngIf="user && isEmailVerified && !isLoading">{{ user.email }}</p>
              <p *ngIf="!user || !isEmailVerified || isLoading" class="error">Email not yet verified</p>
              <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="text-muted" *ngIf="isEmailVerificationLoading"></fa-icon>
            </span>
            <span class="info">
              <fa-icon [icon]="['fas', 'medkit']" placement="right" ngbTooltip="Disabilities/Health Conditions Statement"></fa-icon>
              <p *ngIf="user && (user.disability === true || user.disability === false) && !isLoading">
                {{ user.disability ? 'Yes' : 'No' }}
              </p>
              <p *ngIf="!user || (user.disability !== true && user.disability !== false) || isLoading" class="error">
                Required
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-no-margin" *ngIf="editable">
    <button class="btn btn-success ml-auto mt-auto mr-2 d-block mt-2 text-center-mobile" (click)="edit()">
      <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
      {{ isProfileComplete ? 'Edit' : 'Complete Profile' }}
    </button>
  </div>
</div>
