import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';
import groupBy from 'lodash-es/groupBy';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';
import * as moment from 'moment';

import { Timestamp } from '@app/core/models/firebase.model';
import { CompanyService } from './company.service';
import { Observable } from 'rxjs';
import { RoleService } from './role.service';
import { UserService } from './user.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AnalyticsService } from '../helpers/analytics.service';
import { DateRange } from '../models/firebase.model';
import { RoleLite } from '../models/role.model';
import { AuthService } from './auth.service';
import { ObjectCache } from '@app/shared/storage/object-cache';

export class EventInfo {
  camping?: boolean;
  ticket?: boolean;
  carParking?: boolean;
  vanParking?: boolean;
  uniform?: boolean;

  static toJSON(info: EventInfo) {
    return {
      camping: info.camping || false,
      ticket: info.ticket || false,
      carParking: info.carParking || false,
      vanParking: info.vanParking || false,
      uniform: info.uniform || false,
    };
  }
}

export class LatLng {
  lat: number;
  lng: number;
}

export class EventLite {
  id?: string;
  name: string = null;
  image: string = null;
  imageMobile: string = null;
  imageMini: string = null;
  active: boolean = null;
  dateRange: DateRange = null;
  holidayPay: boolean = false;

  rolesLite?: RoleLite[] = null;

  constructor() {
    this.dateRange = { start: null, end: null };
    this.active = false;
  }
}
export class Event extends EventLite {
  description: string = null;
  info: EventInfo = null;
  location: LatLng = null;
  website: string = null;
  facebook: string = null;
  instagram: string = null;

  constructor() {
    super();
    this.info = {
      camping: null,
      carParking: null,
      ticket: null,
      uniform: null,
      vanParking: null,
    };
  }

  toJSON() {
    return {
      name: this.name,
      image: this.image || null,
      imageMobile: this.imageMobile || null,
      imageMini: this.imageMini || null,
      description: this.description || null,
      location: this.location || null,
      website: this.lowercase(this.addHttpsIfMissing(this.website || null)),
      facebook: this.lowercase(this.addHttpsIfMissing(this.facebook || null)),
      instagram: this.lowercase(this.addHttpsIfMissing(this.instagram || null)),
      active: this.active !== undefined ? this.active : null,
      info: EventInfo.toJSON(this.info || {}),
      dateRange: {
        start: this.dateRange.start,
        end: this.dateRange.end,
      },
      holidayPay: this.holidayPay || false,
    };
  }

  lowercase(str: string) {
    return str ? str.toLowerCase() : null;
  }

  addHttpsIfMissing(str: string) {
    if (str) {
      return str.includes('http://') || str.includes('https://') ? str : 'https://' + str;
    } else {
      return null;
    }
  }

  static isEqual(event: Event, event2: Event) {
    return isEqual(omit(event2, 'rolesLite'), omit(event, 'rolesLite'));
  }

  static clone(event: Event) {
    return cloneDeep(event);
  }
}

export class EventsInMonth {
  yearMonth: string;
  events: Event[];

  constructor(monthYear: string, events: Event[]) {
    this.yearMonth = monthYear;
    this.events = events;
  }
}

@Injectable()
export class EventService {
  private cache = new ObjectCache<Event>(ObjectCache.FIVE_MINUTES);

  private onEventUpdated: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(
    private firestore: AngularFirestore,
    private companyService: CompanyService,
    private authService: AuthService,
    private fns: AngularFireFunctions,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {
    this.onEventUpdated.subscribe(() => this.cache.reset());
  }

  getUrl(url: string) {
    return `companys/${this.companyService.getId()}/${url}`;
  }

  create(event: Event): Promise<Event> {
    return this.firestore
      .collection(this.getUrl('events'))
      .add(event.toJSON())
      .then(response => response.get())
      .then(doc => this.parseEvent(doc))
      .then(createdEvent => {
        this.onEventUpdated.emit(createdEvent);
        return new Promise(resolve => setTimeout(() => resolve(createdEvent), 1000));
      }); // Add timeout to allow the triggers to catch up
  }

  update(event: Event): Promise<Event> {
    return this.firestore
      .doc(this.getUrl(`events/${event.id}`))
      .update(event.toJSON())
      .then(() => this.onEventUpdated.emit(event))
      .then(() => new Promise(resolve => setTimeout(() => resolve(event), 1000))); // Add timeout to allow the triggers to catch up
  }

  getEvent(id: string): Promise<Event> {
    console.info('getting event: ' + id);
    const result = this.firestore.doc(this.getUrl(`events/${id}`));
    return result
      .get()
      .toPromise()
      .then(doc => this.parseEvent(doc));
  }

  getEventFromCache(id: string): Promise<Event> {
    return this.cache.getOrUpdate(id, () => this.getEvent(id));
  }

  getEventForCompany(companyId: string, id: string): Promise<Event> {
    console.info('getting event: ' + id + ' for company: ' + companyId);
    const result = this.firestore.doc(`companys/${companyId}/events/${id}`);
    return result
      .get()
      .toPromise()
      .then(doc => this.parseEvent(doc));
  }

  getUpcomingActiveEvents(): Promise<Event[]> {
    return this.getEventsFromDate(
      false,
      true,
      moment()
        .add(-1, 'weeks')
        .toDate(),
    );
  }

  /*
   * only admin can access inactive events
   */
  getUpcomingActiveInactiveEvents(): Promise<Event[]> {
    return this.getEventsFromDate(
      true,
      true,
      moment()
        .add(-2, 'weeks')
        .toDate(),
    );
  }

  getPastActiveEvents(): Promise<Event[]> {
    return this.getEventsFromDate(
      false,
      false,
      moment()
        .add(-1, 'weeks')
        .toDate(),
    );
  }

  getEventsCalendar(future: boolean): Promise<EventsInMonth[]> {
    return this.getEventsFromDate(
      true,
      future,
      future
        ? moment()
            .add(-2, 'weeks')
            .toDate()
        : new Date(),
    )
      .then(events => groupBy(events, event => event.dateRange.start.toDate().getFullYear() + '_' + this.getMonth(event.dateRange.start.toDate())))
      .then(grouped =>
        Object.keys(grouped).map(key => {
          const data = sortBy(grouped[key], e => e.dateRange.start.toDate().getDate());
          return new EventsInMonth(key, future ? data : data.reverse());
        }),
      );
  }

  setCurrentUserForEventNotifications(eventId: string, registerForNotifications: boolean) {
    return this.authService
      .getAuthToken()
      .then(token => {
        const callable = this.fns.httpsCallable('event-setUserEventInterestStatus', { timeout: 30000 });
        return callable({ idToken: token, eventId, isInterested: registerForNotifications, companyId: this.companyService.getId() }).toPromise();
      })
      .then(() => {
        registerForNotifications ? this.analyticsService.reportInterestRegistered() : this.analyticsService.reportInterestDeregistered();
      });
  }

  async isCurrentUserInterestedInEventNotifications(eventId: string): Promise<boolean> {
    const currentUser = await this.authService.getLoggedInUser();
    const metadata = await this.userService.getUserMetadataForCompany(currentUser.id, this.companyService.getId());
    const found = metadata && metadata.interested.find(i => i.eventId === eventId);
    return !!found;
  }

  getInterestedInNotifications(eventId: string): Promise<{ userId: string; added: Timestamp }[]> {
    return this.firestore
      .doc(this.getUrl(`events/${eventId}/interested/interested`))
      .get()
      .toPromise()
      .then(doc => {
        const data = doc.exists ? doc.data() : {};
        return Object.keys(data).map(userId => {
          return { userId, added: data[userId].added as Timestamp };
        });
      });
  }

  onEventUpdate(): Observable<Event> {
    return this.onEventUpdated;
  }

  private getEventsFromDate(includeNonActive: boolean, future: boolean, fromDate: Date = new Date()) {
    console.info('getting events ' + (future ? 'after' : 'before') + ' ' + fromDate);
    const result = this.firestore.collection(this.getUrl('events'), ref => {
      const retVal = ref.where('dateRange.end', future ? '>' : '<=', Timestamp.fromDate(fromDate));
      return !includeNonActive ? retVal.where('active', '==', true) : retVal;
    });
    return result
      .get()
      .toPromise()
      .then(actions => actions.docs.map(this.parseEvent.bind(this)))
      .then((events: Event[]) => sortBy(events, 'dateRange.start'));
  }

  private getMonth(date: Date): string {
    const month = date.getMonth() + 1;
    return (month < 10 ? '0' : '') + month;
  }

  private parseEvent(doc: any): Event {
    if (doc) {
      console.log('parsing retrieved event');
      return EventService.parseEvent(doc.data(), doc.id);
    } else {
      console.log('could not parse empty Event');
      return null;
    }
  }

  static parseEvent(json: any, id: string): Event {
    if (json) {
      json.rolesLite = Object.keys(json.rolesLite || {}).map(roleId => RoleService.parseRoleLite(json.rolesLite[roleId], roleId));
      const event = Object.assign(new Event(), json);
      event.id = id;
      return event;
    } else {
      console.error('could not parse empty Event json for id: ' + id);
      return null;
    }
  }

  static parseEventLite(json: any, id: string): EventLite {
    json.rolesLite = Object.keys(json.rolesLite || {}).map(roleId => RoleService.parseRoleLite(json.rolesLite[roleId], roleId));
    const event = Object.assign(new EventLite(), json);
    event.id = id;
    return event;
  }
}
