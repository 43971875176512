import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'possessive' })
export class PossessivePipe implements PipeTransform {
  transform(value: string) {
    if (value == null || !value.length) {
      return null;
    }
    return this.addPosessive(value.trim());
  }

  private addPosessive(text: string) {
    const end = text.endsWith('s') ? `'` : `'s`;
    return text + end;
  }
}
