import { Component, forwardRef, Input } from '@angular/core';
import { Timestamp } from '@app/core/models/firebase.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeviceService } from '@app/core/device/device.service';

@Component({
  selector: 'datetime-picker',
  templateUrl: 'datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatetimePickerComponent),
    },
  ],
})
export class DatetimePickerComponent implements ControlValueAccessor {
  @Input()
  set maxDate(date: Timestamp) {
    this._max = date ? date.toDate() : null;
  }

  @Input()
  set minDate(date: Timestamp) {
    this._min = date ? date.toDate() : null;
  }

  set date(date: Date) {
    this._date = date;
    if (this.onChange) {
      this.onChange(date ? Timestamp.fromDate(date) : null);
    }
    if (this.onTouch) {
      this.onTouch();
    }
  }

  @Input()
  clearButton: boolean = false;

  get date() {
    return this._date;
  }

  get max() {
    return this._max;
  }

  get min() {
    return this._min;
  }

  onChange: any;
  onTouch: any;

  time: Date;
  defaultTime: Date;

  private _date: Date;
  private _max: Date;
  private _min: Date;

  constructor(private mobileService: DeviceService) {}

  writeValue(timestamp: Timestamp): void {
    this.date = timestamp ? timestamp.toDate() : null;
    this.time = timestamp ? timestamp.toDate() : null;
    this.defaultTime = new Date();
    this.defaultTime.setHours(0, 0, 0, 0);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  onTimeUpdated(time: Date): void {
    const updated = new Date();
    if (this.date) {
      updated.setTime(this.date.getTime());
    }
    updated.setHours(time?.getHours() || 0);
    updated.setMinutes(time?.getMinutes() || 0);
    updated.setMilliseconds(0);
    updated.setSeconds(0);

    this.date = updated;
  }

  get isMobile() {
    return this.mobileService.isMobileSize();
  }

  isDateWithinRange(date: any) {
    const parsed = new Date(date.year, date.month, date.day);
    return this.date && this.min && this.max && this.max >= parsed && this.min <= parsed;
  }
}
