import { OnDestroy, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import 'bootstrap';
import { Subscription } from 'rxjs';
import { LinkService } from '@app/core/link.service';

@Injectable()
export class Dialog implements OnDestroy {
  private subs: Subscription;

  private appendedToBody: boolean = false;

  isLoaded: boolean = false;

  get selector() {
    return `#${this.id}`;
  }

  get id(): string {
    return this._id + '' + this.uniqueId;
  }
  static count = 1;

  private uniqueId: number = 0;

  constructor(protected location: Location, protected linkService: LinkService, protected _id: string, protected isClosedByClickingOutsideEnabled: boolean = true) {
    this.uniqueId = Dialog.count++;
    this.subs = this.linkService.onNavigate.subscribe(() => this.close());
    this.subs.add(
      location.subscribe(next => {
        if (next.pop) {
          this.close();
        }
      }),
    );
  }

  open(): void {
    const params: any = !this.isClosedByClickingOutsideEnabled
      ? {
          backdrop: 'static',
          keyboard: false,
        }
      : {};

    $(this.selector)
      .modal(params)
      .on('hidden.bs.modal', this.afterModalClosed.bind(this))
      .on('shown.bs.modal', this.afterModalOpened.bind(this));
    if (!this.appendedToBody) {
      $(this.selector)
        .detach()
        .appendTo('body');
      this.appendedToBody = true;
    }
    setTimeout(() => {
      this.setupZIndexForWhenNestedDialog();
    });
  }

  private setupZIndexForWhenNestedDialog() {
    const zIndex = 1050 + 10 * $('.modal:visible').length;
    $(this.selector).css('z-index', zIndex);
    const id = this.id;
    $('.modal-backdrop').each(function() {
      if (!$(this).attr('id')) {
        $('.modal-backdrop')
          .not('.modal-stack')
          .css('z-index', zIndex - 1)
          .addClass('modal-stack')
          .attr('id', id + '-backdrop');
      }
    });
  }

  close() {
    $(this.selector).modal('hide');
  }

  static cleanup() {
    $('.modal-backdrop').each(function() {
      this.remove();
    });
    $(document.body).removeClass('modal-open');
    $('.modal').each(function() {
      this.remove();
    });
  }

  afterModalClosed() {
    // // If current modal was nested, ensure the previous one is now in focus
    if ($('.modal:visible').length > 0) {
      $(document.body).addClass('modal-open');
    }
    $(this.selector + '-backdrop').remove();
    this.isLoaded = false;
    this.close();
  }

  afterModalOpened() {
    this.isLoaded = true;
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
