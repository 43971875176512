import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CompanyService } from './company.service';
import { parseUser, UserLite } from '../models/user.model';
import flatten from 'lodash-es/flatten';
import uniqBy from 'lodash-es/uniqBy';
import { Role } from '../models/role.model';
import { AuthService } from './auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Event } from './event.service';
import * as fs from 'file-saver';
declare const Buffer: any;
export class StaffUserLite extends UserLite {
  applicationId?: string;
}
@Injectable()
export class StaffService {
  constructor(
    private authService: AuthService,
    private fns: AngularFireFunctions,
    private firestore: AngularFirestore,
    private companyService: CompanyService,
  ) {}

  getStaffForRole(eventId: string, roleId: string): Promise<StaffUserLite[]> {
    console.info(`Getting Staff for eventId: ${eventId} roleId: ${roleId}`);
    const result = this.firestore.doc(this.getUrl(`events/${eventId}/staff/${roleId}`));
    return result
      .get()
      .toPromise()
      .then(this.parseStaffList.bind(this));
  }

  getStaffForEvent(eventId: string): Promise<StaffUserLite[]> {
    console.info(`Getting Staff for eventId: ${eventId}`);
    const result = this.firestore.collection(this.getUrl(`events/${eventId}/staff/`));
    return result
      .get()
      .toPromise()
      .then(result => uniqBy(flatten(result.docs.map(this.parseStaffList.bind(this))), u => u.id));
  }

  exportStaffForEventToExcel(event: Event, role?: Role) {
    return this.authService
      .getAuthToken()
      .then(idToken => {
        const callable = this.fns.httpsCallable('export-exportStaffToExcel', { timeout: 30000 });
        return callable({ idToken, companyId: this.companyService.getId(), eventId: event.id, roleId: role ? role.id : undefined }).toPromise();
      })
      .then(result => {
        const buffer = this.parseBuffer(result.buffer);
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const roleName = role ? ' ' + role.name : '';
        fs.saveAs(blob, event.name + roleName + ' Staff.xlsx');
      });
  }

  private parseBuffer(bufferStr: string) {
    return Buffer.from(JSON.parse(bufferStr).data);
  }

  private getUrl(url: string) {
    return `companys/${this.companyService.getId()}/${url}`;
  }

  private parseStaffList(doc: any): StaffUserLite[] {
    console.log('Parsing retrieved staff list');
    const staffList = doc.data();
    return staffList
      ? Object.keys(staffList || {})
          .map(userId => {
            if (staffList[userId]?.userLite) {
              const user: StaffUserLite = parseUser(staffList[userId].userLite, userId) as StaffUserLite;
              user.applicationId = staffList[userId].applicationId;
              return user;
            } else {
              return null;
            }
          })
          .filter(user => !!user)
      : [];
  }
}
