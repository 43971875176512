<div class="modal" id="{{ id }}" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog zoomIn faster animated" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title d-flex mr-2" id="{{ id }}-title">
					Updating status to
					<application-status class="ml-2" [status]="status" [isAdmin]="true"> </application-status>
				</h4>
			</div>
			<div class="modal-body">
				<div class="col">
					<div class="row flex-column">
						<textarea class="w-100" rows="2" placeholder="Explanation for application status change, if applicable..." maxlength="300" [(ngModel)]="notes"></textarea>
					</div>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
				<button type="button" class="btn btn-success" (click)="save()"><fa-icon [icon]="['fas', 'save']"> </fa-icon>Update Status</button>
			</div>
		</div>
	</div>
</div>
