import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CompanyService } from './company.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Role, RoleLabel, RoleLite, RoleMetadata } from '../models/role.model';
import { AuthService } from './auth.service';
import firebase from 'firebase/app';

@Injectable()
export class RoleService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private companyService: CompanyService,
    private fns: AngularFireFunctions,
  ) {}

  getUrl(url: string) {
    return `companys/${this.companyService.getId()}/${url}`;
  }

  /*
   * Only admin is able to query without this, else the pre-query checks will fail
   */
  getRolesForEvent(eventId: string, includeNonActive: boolean = true, includeMetadata: boolean = false): Promise<Role[]> {
    console.info('getting roles for event: ' + eventId);
    const result = this.firestore.collection(this.getUrl(`events/${eventId}/roles`), ref => (!includeNonActive ? ref.where('active', '==', true) : ref));
    return result
      .get()
      .toPromise()
      .then(actions => actions.docs.map(this.parseRole.bind(this)) as Role[])
      .then(roles =>
        includeMetadata
          ? Promise.all(
              roles.map(r =>
                this.getMetadata(eventId, r.id).then(metadata => {
                  r.metadata = metadata;
                  return r;
                }),
              ),
            )
          : Promise.resolve(roles),
      );
  }

  getMetadata(eventId: string, roleId: string): Promise<RoleMetadata> {
    console.info('getting role metadata: ' + roleId);
    const result = this.firestore.doc(this.getUrl(`events/${eventId}/roles/${roleId}/metadata/metadata`));
    return result
      .get()
      .toPromise()
      .then(doc => (doc.exists ? doc.data() : {}))
      .then(this.parseMetadata.bind(this));
  }

  getRole(eventId: string, roleId: string): Promise<Role> {
    console.info('getting role: ' + roleId);
    const result = this.firestore.doc(this.getUrl(`events/${eventId}/roles/${roleId}`));
    return result
      .get()
      .toPromise()
      .then(this.parseRole.bind(this));
  }

  getRoleLabels(eventId: string, roleId: string): Promise<RoleLabel[]> {
    console.info('getting role labels: ' + roleId);
    const result = this.firestore.collection(this.getUrl(`events/${eventId}/roles/${roleId}/labels`));
    return result
      .get()
      .toPromise()
      .then(result => result.docs.map(this.parseRoleLabel.bind(this)));
  }

  async updateRole(eventId: string, roleId: string, role: Role): Promise<Role> {
    await this.validate(eventId, 'eventId');
    await this.validate(role, 'role');
    role.id = roleId;
    const json = Role.toJSON(role);
    const result = this.firestore.doc(this.getUrl(`events/${eventId}/roles/${roleId}`));
    return result.set(json, { merge: true }).then(
      () => new Promise<Role>(resolve => setTimeout(() => resolve(role), 1000)),
    ); // Add timeout to allow the triggers to catch up
  }

  async createRole(eventId: string, role: Role): Promise<Role> {
    await this.validate(eventId, 'eventId');
    await this.validate(role, 'role');
    const result = this.firestore.collection(this.getUrl(`events/${eventId}/roles`));
    return result
      .add(Role.toJSON(role))
      .then(response => response.get())
      .then(doc => this.parseRole(doc))
      .then(createdRole => new Promise(resolve => setTimeout(() => resolve(createdRole), 1000))); // Add timeout to allow the triggers to catch up
  }

  async setLabelsForRole(eventId: string, roleId: string, labels: RoleLabel[]) {
    const url = this.getUrl(`events/${eventId}/roles/${roleId}/labels`);
    const existing = await this.firestore
      .collection(url)
      .get()
      .toPromise();
    //delete removed ones first.
    await Promise.all(
      existing.docs.map(d => {
        if (!labels.find(label => label.id === d.id)) {
          return this.firestore.doc(d.ref).delete();
        } else {
          return Promise.resolve();
        }
      }),
    );
    //now add/set
    await Promise.all(
      labels.map(label => {
        const json = RoleLabel.toJSON(label);
        if (label.id) {
          return this.firestore.doc(url + '/' + label.id).set(json, { mergeFields: ['name', 'targetCount'] });
        } else {
          return this.firestore
            .collection(url)
            .add(json)
            .then(() => undefined);
        }
      }),
    );
  }

  async setApplicationStateForRoleLabels(eventId: string, roleId: string, applicationId: string, selectedLabels: RoleLabel[]) {
    console.log('setting selected labels for application', eventId, roleId, applicationId, selectedLabels);
    const url = this.getUrl(`events/${eventId}/roles/${roleId}/labels`);
    const allLabelsForRole = await this.getRoleLabels(eventId, roleId);
    await Promise.all(
      allLabelsForRole.map(label => {
        const obj = {
          applications: {},
        };
        if (selectedLabels.find(selected => selected.id === label.id)) {
          obj.applications[applicationId] = true;
        } else {
          obj.applications[applicationId] = firebase.firestore.FieldValue.delete();
        }
        return this.firestore.doc(url + '/' + label.id).set(obj, { mergeFields: [`applications.${applicationId}`] });
      }),
    );
  }

  recalcuateMetadata(eventId: string, roleId: string) {
    console.info('recalcuating metadata for role: ' + roleId);
    return this.authService.getAuthToken().then(token => {
      const callable = this.fns.httpsCallable('role-recalcuateRoleMetadata', { timeout: 30000 });
      return callable({ idToken: token, eventId, roleId, companyId: this.companyService.getId() }).toPromise();
    });
  }

  parseMetadata(json: any): RoleMetadata {
    return new RoleMetadata(json);
  }

  private validate(param: any, name: string) {
    if (!param) {
      return Promise.reject({ status: 500, message: name + ' param is invalid' });
    }
  }
  private parseRole(doc: any): Role {
    console.log('parsing retrieved role');
    return RoleService.parseRole(doc.data(), doc.id);
  }
  private parseRoleLabel(doc: any): RoleLabel {
    console.log('parsing retrieved role label');
    return RoleService.parseRoleLabel(doc.data(), doc.id);
  }
  static parseRole(json: any, id: string): Role {
    const role = Object.assign(new Role(), json);
    role.id = id;
    return role;
  }

  static parseRoleLite(json: any, id: string): RoleLite {
    const role = Object.assign(new RoleLite(), json);
    role.id = id;
    return role;
  }
  static parseRoleLabel(json: any, id: string): RoleLabel {
    const roleLabel = Object.assign(new RoleLabel(), json);
    roleLabel.id = id;
    return roleLabel;
  }
}
