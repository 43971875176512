<div class="modal" id="{{ id }}" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog zoomIn faster animated" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" id="{{ id }}-title">Upload {{ title || 'Image' }}</h4>
				<button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<image-uploader *ngIf="isOpen" [isImageCompressionSkippable]="false" [preselectedImage]="preselectedImage" (onUpload)="onImageSelectedForUpload($event)" [shape]="shape"></image-uploader>
			</div>
			<div class="modal-footer">
				<button type="button" [disabled]="isSaving" class="btn btn-sm btn-secondary" (click)="close()">
					Cancel
				</button>
				<button type="button" [disabled]="isSaving || !isImageLoaded" class="btn btn-sm btn-info" (click)="save()">
					<fa-icon [icon]="['fas', 'cog']" [spin]="true" class="mr-2" *ngIf="isSaving"></fa-icon>
					Save
				</button>
			</div>
		</div>
	</div>
</div>
