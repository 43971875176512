import { Component, Input } from '@angular/core';
import { UserLite } from '@app/core/models/user.model';
import { UserCacheService } from '../../../core/services/user-cache.service';

@Component({
  selector: 'user-fullname',
  template: `
    <div>
      <p-skeleton *ngIf="isLoading" width="4rem" height="1rem"></p-skeleton>
      <span *ngIf="!isLoading">{{ user?.getFullName() }}</span>
    </div>
  `,
  styles: [],
})
export class UserFullNameComponent {
  @Input()
  set id(id: string) {
    this._id = id;
    if (id) {
      this.setup();
    }
  }

  get id() {
    return this._id;
  }

  user: UserLite;
  isLoading: boolean;

  private _id: string;

  constructor(private userCacheService: UserCacheService) {}

  setup() {
    this.isLoading = true;
    return this.userCacheService
      .getUserBasicById(this.id)
      .then(user => (this.user = user))
      .then(() => (this.isLoading = false));
  }
}
