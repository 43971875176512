import { Component, Input } from '@angular/core';
import { IdentificationType } from '@app/core/models/user.model';

@Component({
  selector: 'identification-type-label',
  template: '<p>{{name}}</p>',
})
export class IdentificationTypeLabelComponent {
  @Input()
  set type(type: IdentificationType) {
    this._type = type;
    this.name = IdentificationTypeLabelComponent.getName(type);
  }

  get type(): IdentificationType {
    return this.type;
  }

  name: string;

  private _type: IdentificationType;

  static getName(type: IdentificationType) {
    switch (type) {
      case IdentificationType.UK_EU_PASSPORT:
        return 'I have a UK/EAA/Swiss Passport';
      case IdentificationType.NON_EU_PASSPORT:
        return 'I have a non UK/EEA passport with a valid working visa';
      case IdentificationType.BIRTH_CERT_NI:
        return 'I am a UK citizen with a birth certificate and NI card';
      case IdentificationType.BIRTH_CERT_NI_PROOF:
        return 'I am a UK citizen with a birth certificate and a document from my previous/current employer showing my NI number';
      default:
        return '';
    }
  }
}
