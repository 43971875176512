import { Component, Input } from '@angular/core';
import { Event } from '@app/core/services/event.service';
import { LinkService } from '@app/core/link.service';
import { Role } from '@app/core/models/role.model';
import * as moment from 'moment';

@Component({
  selector: 'apply-for-role-button',
  templateUrl: './apply-for-role-button.component.html',
  styleUrls: ['./apply-for-role-button.component.scss'],
})
export class ApplyForRoleButtonComponent {
  @Input()
  role: Role;

  @Input()
  set event(event: Event) {
    this._event = event;
    this.isInFuture =
      event && event.dateRange && event.dateRange.start
        ? event.dateRange.start.toMillis() >
          moment()
            .toDate()
            .getTime()
        : false;
  }

  get event() {
    return this._event;
  }

  @Input()
  disabled: boolean = false;

  isInFuture: boolean = false;

  private _event: Event;

  constructor(private linkService: LinkService) {}

  apply() {
    this.linkService.navigate(['events', this.event.id, 'apply', this.role.id]);
  }
}
