<div class="page">
  <div *ngIf="!isLoading" class="container mb-3">
    <div class="content">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-10">
          <h2 class="text-center-mobile">
            You're applying to work for:<br />
            {{ company.name }} at {{ event.name }}
          </h2>

          <div class="d-flex flex-column-mobile mt-3">
            <div class="flex-auto d-flex text-left text-center-mobile mb-2">
              <h4 class="bold mr-2">Dates:</h4>
              <h4 class="text-left-mobile">
                {{ event.dateRange | dateRange }}
              </h4>
            </div>
            <div class="flex-auto d-flex text-left text-center-mobile mb-2">
              <h4 class="bold mr-2">Job:</h4>
              <h4 class="text-left-mobile">{{ role.name }}</h4>
            </div>
            <div class="flex-auto d-flex text-left text-center-mobile mb-2">
              <h4 class="bold mr-2">Rate:</h4>
              <h4 class="text-left-mobile">{{ role | roleRate }} <span *ngIf="!role.rateManual">per hour</span> <span *ngIf="event.holidayPay">+ Holiday Pay</span></h4>
            </div>
          </div>
        </div>
      </div>

      <div class="application pb-3">
        <div class="row">
          <div class="col-md-2">
            <div class="section-tab">
              <h4 class="title text-left text-center-mobile">About You</h4>
            </div>
            <div class="percentage flex-column">
              <p>{{ getProfileDetailsPercentage() }}% Complete</p>
              <ngb-progressbar [showValue]="false" [type]="getProfileDetailsPercentage() === 100 ? 'info' : 'warning'" [value]="getProfileDetailsPercentage()" [max]="100" class="mb-2 d-block">
              </ngb-progressbar>
              <small [hidden]="userProfile?.isProfileComplete || !submitAttempted" class="text-danger text-right">
                Your info section must be completed
              </small>
            </div>
          </div>
          <div class="col">
            <div class="overlay vertical-center" *ngIf="!user">
              <div *ngIf="true; then login_or_import; else undefined"></div>
            </div>
            <current-user-info [isLoading]="isSectionsLoading" [user]="user"> </current-user-info>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-2">
            <div class="section-tab">
              <h4 class="title text-left text-center-mobile">Experience</h4>
            </div>
            <div class="percentage flex-column">
              <p>{{ getProfileExperiencePercentage() }}% Complete</p>
              <ngb-progressbar [showValue]="false" [type]="getProfileExperiencePercentage() === 100 ? 'info' : 'warning'" [value]="getProfileExperiencePercentage()" [max]="100" class="mb-2 d-block">
              </ngb-progressbar>
              <small [hidden]="userExperience?.isProfileComplete || !submitAttempted" class="text-danger text-right">
                Your experience section must be completed
              </small>
            </div>
          </div>
          <div class="col">
            <div class="overlay vertical-center" *ngIf="!user">
              <div *ngIf="true; then login_or_import; else undefined"></div>
            </div>
            <current-user-experience [isLoading]="isSectionsLoading" [user]="user" (onLoaded)="onSectionsLoaded()"> </current-user-experience>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-2">
            <div class="section-tab">
              <h4 class="title text-left text-center-mobile">Contact Info:</h4>
            </div>
            <div class="percentage flex-column">
              <p>{{ getProfileContactPercentage() }}% Complete</p>
              <ngb-progressbar [showValue]="false" [type]="getProfileContactPercentage() === 100 ? 'info' : 'warning'" [value]="getProfileContactPercentage()" [max]="100" class="mb-2 d-block">
              </ngb-progressbar>
              <small [hidden]="userContact?.isProfileComplete || !submitAttempted" class="text-danger text-right">
                Your contact info section must be completed
              </small>
            </div>
          </div>
          <div class="col">
            <div class="overlay vertical-center" *ngIf="!user">
              <div *ngIf="true; then login_or_import; else undefined"></div>
            </div>
            <current-user-contact [isLoading]="isSectionsLoading" [user]="user"></current-user-contact>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-2">
            <div class="section-tab">
              <h4 class="title text-left text-center-mobile">Right to Work:</h4>
            </div>
            <div class="percentage flex-column">
              <p>{{ getProfileRightToWorkPercentage() }}% Complete</p>
              <ngb-progressbar [showValue]="false" [type]="getProfileRightToWorkPercentage() === 100 ? 'info' : 'warning'" [value]="getProfileRightToWorkPercentage()" [max]="100" class="mb-2 d-block">
              </ngb-progressbar>
              <small [hidden]="rightToWork?.isProfileComplete || !submitAttempted" class="text-danger text-right">
                Your right to work section must be completed
              </small>
            </div>
          </div>
          <div class="col">
            <div class="overlay vertical-center" *ngIf="!user">
              <div *ngIf="true; then login_or_import; else undefined"></div>
            </div>
            <current-user-right-to-work [isLoading]="isSectionsLoading" [user]="user"></current-user-right-to-work>
          </div>
        </div>

        <div class="row mt-3" *ngIf="role?.info?.payrollRequiredUpFront">
          <div class="col-md-2">
            <div class="section-tab">
              <h4 class="title text-left text-center-mobile">Payroll:</h4>
            </div>
            <div class="percentage flex-column">
              <p>{{ getProfilePayrollPercentage() }}% Complete</p>
              <ngb-progressbar [showValue]="false" [type]="getProfilePayrollPercentage() === 100 ? 'info' : 'warning'" [value]="getProfilePayrollPercentage()" [max]="100" class="mb-2 d-block">
              </ngb-progressbar>
              <small [hidden]="!role?.info?.payrollRequiredUpFront || payroll?.isProfileComplete || !submitAttempted" class="text-danger text-right">
                Your payroll section must be completed
              </small>
            </div>
          </div>
          <div class="col">
            <div class="overlay vertical-center" *ngIf="!user">
              <div *ngIf="true; then login_or_import; else undefined"></div>
            </div>
            <current-user-payroll [isLoading]="isSectionsLoading" [user]="user"></current-user-payroll>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <div class="section-tab">
              <h4 class="title text-left text-center-mobile">Cover letter:</h4>
            </div>
          </div>
          <div class="col textarea-wrapper">
            <textarea class="w-100" rows="6" [(ngModel)]="coverLetter" maxlength="2000"></textarea>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col">
            <div class="row row-no-margin flex-column">
              <h6 class="tiny mb-2">If applying for more than one role at {{ event.name }} then please indicate your preferential priority of this role application.</h6>
              <div class="d-flex">
                <label class="d-block">
                  <h6>Priority choice</h6>
                  <select class="custom-select form-control" (change)="priorityChoice = $event.target.value" [(ngModel)]="priorityChoice">
                    <option value=""></option>
                    <option value="1">1st</option>
                    <option value="2">2nd</option>
                    <option value="3">3rd</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="row row-no-margin flex-column">
              <div class="d-flex">
                <label class="d-block">
                  <h6>How did you find out about this role?</h6>
                  <select class="custom-select form-control" (change)="source = $event.target.value" [(ngModel)]="source">
                    <option value=""></option>
                    <option [value]="source" *ngFor="let source of sources">
                      <application-source-label [source]="source"></application-source-label>
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="row row-no-margin">
              <div class="d-block mb-3">
                <h6>I have reviewed all information being submitted with this Application and confirm it is accurate.</h6>
                <div class="d-flex mt-2">
                  <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="reviewedProfileConfirmation">
                    <label ngbButtonLabel class="btn-info"> <input ngbButton type="radio" [value]="false" /> Disagree </label>
                    <label ngbButtonLabel class="btn-info"> <input ngbButton type="radio" [value]="true" /> Agree </label>
                  </div>
                </div>
                <small [hidden]="reviewedProfileConfirmation || !submitAttempted" class="text-danger">
                  You must confirm that you have reviewed your application and it is accurate.
                </small>
              </div>
            </div>
            <div class="row row-no-margin">
              <div class="d-block mb-3">
                <h6 class="tiny mb-2">
                  To enable {{ company?.name }} to assess your application to work at {{ event.name }}, they require access to your event master profile. Submitting this application will grant access
                  to your CV, proof of identity documents, email and telephone number.
                </h6>
                <h6>
                  I agree to grant <span class="bold">{{ company.name }}</span> access to my event master Profile.
                </h6>
                <div class="d-flex mt-2">
                  <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="agreeAccessToProfile">
                    <label ngbButtonLabel class="btn-info"> <input ngbButton type="radio" [value]="false" /> Disagree </label>
                    <label ngbButtonLabel class="btn-info"> <input ngbButton type="radio" [value]="true" /> Agree </label>
                  </div>
                </div>
                <small [hidden]="agreeAccessToProfile || !submitAttempted" class="text-danger"> You must agree to permit {{ company?.name }} to access your profile. </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h6 class="text-center mt-3">All sections above must be completed to apply.</h6>
        </div>
      </div>
      <div class="row row-no-margin mt-3">
        <button class="btn btn-success text-center btn-block" (click)="submit()" [disabled]="isSaving">
          <fa-icon [icon]="['fas', 'cog']" [spin]="true" class="mr-2" *ngIf="isSaving"></fa-icon>Submit Application
        </button>
      </div>
    </div>
  </div>
  <app-loader *ngIf="isLoading"></app-loader>
</div>
<ng-template #login_or_import>
  <div class="vertical-center" *ngIf="currentUser">
    <current-user-avatar size="5rem" class="text-center"></current-user-avatar>
    <button class="btn btn-success mx-auto" (click)="importUser()">Import your event master Profile</button>
  </div>
  <div class="vertical-center" *ngIf="!currentUser">
    <img class="login" src="/assets/branding/logo-powered-by.svg" />
    <div class="d-flex justify-content-center">
      <button class="btn btn-success mr-2" (click)="register()"><fa-icon [icon]="['fas', 'user-plus']" class="mr-2"></fa-icon>Create profile</button>
      <button class="btn btn-warning ml-2" (click)="login()"><fa-icon [icon]="['fas', 'sign-in-alt']" class="mr-2"></fa-icon>Login</button>
    </div>
  </div>
</ng-template>
