import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl } from '@angular/forms';

import { Timestamp } from '@app/core/models/firebase.model';
import * as moment from 'moment';

@Component({
  selector: 'date-picker',
  template: `
    <div class="input-group">
      <p-inputMask
        type="text"
        autoClear="false"
        styleClass="form-control btn-sm"
        placeholder="DD/MM/YYYY"
        mask="99/99/9999"
        [(ngModel)]="date"
        (ngModelChange)="onDateUpdate($event)"
      ></p-inputMask>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatepickerComponent),
    },
  ],
})
export class DatepickerComponent implements ControlValueAccessor {
  date: string;

  onChange: any;
  onTouch: any;

  private value: Timestamp;

  constructor() {}

  onDateUpdate(field: string) {
    if (field !== null && field.trim().length > 0 && field.trim().length >= 8) {
      try {
        const date = moment(field, 'DD/MM/YYYY').toDate();
        this.value = Timestamp.fromDate(date);
      } catch (e) {
        console.error('Caught error while parsing date: ' + field);
      }
    } else {
      this.value = null;
    }
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
    this.date = value ? moment(value.toDate()).format('DD/MM/YYYY') : null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  static isDateValidWithin(min: moment.Moment, max: moment.Moment) {
    return (control: AbstractControl) => {
      const timestamp = control.value as Timestamp;
      if (timestamp) {
        const date = moment(timestamp.toDate());
        if (!date.isValid()) {
          return { invalid: true };
        } else if (date.isBefore(min)) {
          return { beforeMin: true };
        } else if (date.isAfter(max)) {
          return { afterMax: true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
}
