<div class="bank-info" [class.is-profile-complete]="(isProfileComplete || !showSectionError) && !isLoading">
	<div class="absolute-fill vertical-center" *ngIf="isLoading || !user">
		<fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="text-center"></fa-icon>
	</div>
	<div class="row row-no-margin">
		<div class="body-col flex-auto p-2">
			<div class="row">
				<div class="col-sm-12 col-md-6">
					<h5>Right to work:</h5>
					<span class="info">
						<h6 class="tiny">Right to Work:</h6>
						<p class="font-italic mb-2" *ngIf="user && user.identificationType && !isLoading">
							<identification-type-label [type]="user.identificationType"> </identification-type-label>
						</p>
						<span *ngIf="!user || !user.identificationType || isLoading" class="error">Required</span>
						<div class="row row-no-margin" *ngIf="user && requiredDocumentForType">
							<div *ngFor="let docForType of requiredDocumentForType" class="mr-3 d-flex">
								<document-label [small]="true" [type]="docForType.type" [document]="docForType.document" [light]="true" [showError]="false"> </document-label>
								<p class="error" *ngIf="!docForType.document || !docForType.document.valid">Required</p>
							</div>
						</div>
					</span>
					<span class="info">
						<h6 class="tiny">National Insurance Number:</h6>
						<p *ngIf="user && user.ni && !isLoading">{{ user.ni }}</p>
						<p *ngIf="!user || !user.ni || isLoading" class="error">Required</p>
					</span>
					<div class="info">
						<h6 class="tiny">Self Employed:</h6>
						<p *ngIf="user && (user.selfEmployed === true || user.selfEmployed === false) && !isLoading">
							{{ user.selfEmployed ? 'Yes' : 'No' }}
						</p>
						<p *ngIf="!user || (user.selfEmployed !== true && user.selfEmployed !== false)" class="error">Required</p>
					</div>
					<div class="info" *ngIf="user && !isLoading">
						<h6 class="tiny">Working Regs Waiver:</h6>
						<p *ngIf="user.hoursWorkingRegsWaver">Agreed</p>
						<p class="error" *ngIf="!user.hoursWorkingRegsWaver || isLoading">Required</p>
					</div>
				</div>
				<div class="col-sm-12 col-md-6">
					<h5>{{ user && user.selfEmployed ? 'Self Employment Info' : 'Employment Info' }}:</h5>
					<div *ngIf="user && user.selfEmployed === false && user.employeeInfo && !isLoading">
						<div class="info">
							<h6 class="tiny">Employment Status:</h6>
							<p *ngIf="user.employeeInfo.statement">
								{{ user.employeeInfo.statement | enumToString }}
							</p>
							<p class="error" *ngIf="!user.employeeInfo.statement">Required</p>
						</div>
						<div class="info">
							<h6 class="tiny">Student Loan:</h6>
							<p *ngIf="isBooleanSet(user.employeeInfo.studentLoan) && !isLoading">
								{{ user.employeeInfo.studentLoan ? 'Yes' : 'No' }}
							</p>
							<p class="error" *ngIf="!isBooleanSet(user.employeeInfo.studentLoan)">Required</p>
						</div>
						<div *ngIf="user.employeeInfo.studentLoan && !isLoading">
							<div class="info">
								<h6 class="tiny">Paying direct:</h6>
								<p *ngIf="isBooleanSet(user.employeeInfo.studentLoanInfo.payingDirect)">
									{{ user.employeeInfo.studentLoanInfo.payingDirect ? 'Yes' : 'No' }}
								</p>
								<p class="error" *ngIf="!isBooleanSet(user.employeeInfo.studentLoanInfo.payingDirect)">
									Required
								</p>
							</div>
							<div class="info">
								<h6 class="tiny">Studies finished before this FY:</h6>
								<p *ngIf="isBooleanSet(user.employeeInfo.studentLoanInfo.finishedBeforeThisFY)">
									{{ user.employeeInfo.studentLoanInfo.finishedBeforeThisFY ? 'Yes' : 'No' }}
								</p>
								<p class="error" *ngIf="!isBooleanSet(user.employeeInfo.studentLoanInfo.finishedBeforeThisFY)">Required</p>
							</div>
							<div class="info">
								<h6 class="tiny">Student Loan Plan:</h6>
								<p>{{ user.employeeInfo.studentLoanInfo.planType | enumToString }}</p>
								<p class="error" *ngIf="!user.employeeInfo.studentLoanInfo.planType">Required</p>
							</div>
						</div>
					</div>
					<div *ngIf="user && user.selfEmployed === true && user.selfEmployedInfo && !isLoading">
						<div class="info">
							<h6 class="tiny">UTR Number:</h6>
							<p *ngIf="user.selfEmployedInfo.utr">
								{{ user.selfEmployedInfo.utr }}
							</p>
							<p class="error" *ngIf="!user.selfEmployedInfo.utr">Required</p>
						</div>
						<div class="info">
							<h6 class="tiny">Agree to submit invoices:</h6>
							<p *ngIf="user.selfEmployedInfo.agreeToInvoice">
								{{ user.selfEmployedInfo.agreeToInvoice ? 'Yes' : 'No' }}
							</p>
							<p class="error" *ngIf="!user.selfEmployedInfo.agreeToInvoice">Required</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row row-no-margin" *ngIf="editable">
		<button class="btn btn-success ml-auto mr-2 d-block mt-2 text-center-mobile" (click)="edit()"><fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>{{ isProfileComplete ? 'Edit' : 'Complete Right to Work' }}</button>
	</div>
</div>
