import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService, Event } from '@app/core/services/event.service';
import { UserService } from '@app/core/services/user.service';
import { CurrentUser, User } from '@app/core/models/user.model';
import { ApplicationService, Application } from '@app/core/services/application.service';
import { LinkService } from '@app/core/link.service';
import { CompanyService } from '@app/core/services/company.service';
import { Company } from '@app/core/models/company.model';
import { RoleService } from '@app/core/services/role.service';
import { ErrorHandlerService } from '@app/core/error-handler.service';
import { ApplicationSource } from '@app/core/services/application.service';
import { NavigationStateService } from '@app/core/helpers/navigation-state.service';
import { CurrentUserInfoComponent } from '@app/shared/application/user-details/current-user-info.component';
import { CurrentUserContactComponent } from '@app/shared/application/user-details/current-user-contact.component';
import { CurrentUserExperienceComponent } from '@app/shared/application/user-details/current-user-experience.component';
import { CurrentUserRightToWorkComponent } from '@app/shared/application/user-details/current-user-right-to-work.component';
import { IsSaving } from '../../core/guards/is-saving-form.guard';
import { Role } from '@app/core/models/role.model';
import { CurrentUserPayrollComponent } from '@app/shared/application/user-details/current-user-payroll.component';
import { AuthService } from '@app/core/services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

class PendingApplication {
  coverLetter: string;
  agreeAccessToProfile: boolean;
  reviewedProfileConfirmation: boolean;
  importedProfile: boolean;
  priorityChoice: number;
  source: ApplicationSource;
}

@Component({
  selector: 'apply-page',
  templateUrl: './apply-page.component.html',
  styleUrls: ['./apply-page.component.scss'],
})
export class ApplyPageComponent implements OnDestroy, IsSaving {
  static cachedIncompleteApplications: PendingApplication[] = [];

  @ViewChild(CurrentUserInfoComponent)
  userProfile: CurrentUserInfoComponent;

  @ViewChild(CurrentUserContactComponent)
  userContact: CurrentUserContactComponent;

  @ViewChild(CurrentUserExperienceComponent)
  userExperience: CurrentUserExperienceComponent;

  @ViewChild(CurrentUserRightToWorkComponent)
  rightToWork: CurrentUserRightToWorkComponent;

  @ViewChild(CurrentUserPayrollComponent)
  payroll: CurrentUserPayrollComponent;

  event: Event;
  role: Role;

  submitAttempted: boolean = false;

  isLoading = true;

  coverLetter: string = '';

  isSaving = false;
  isSavingPromise: Promise<void>;

  isLoaded = true;
  isSectionsLoading = false;

  isSaved = false;

  application: Application;
  company: Company;
  user: User;
  agreeAccessToProfile: boolean;
  reviewedProfileConfirmation: boolean;
  priorityChoice: number;
  source: ApplicationSource;

  sources: string[] = Object.keys(ApplicationSource);

  private importedProfile = false;
  private subs: Subscription;
  private eventId: string;
  private roleId: string;
  private currentUser: CurrentUser;

  constructor(
    private toastrService: ToastrService,
    private navigationStateService: NavigationStateService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private roleService: RoleService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private eventService: EventService,
    private authService: AuthService,
    private linkService: LinkService,
  ) {
    this.subs = this.route.params.subscribe(params => {
      this.isLoading = true;
      this.eventId = params['eventId'];
      this.roleId = params['roleId'];
      const cachedIncompleteApplication = ApplyPageComponent.getCachedIncompleteApplication(this.eventId, this.roleId);

      if (cachedIncompleteApplication) {
        this.coverLetter = cachedIncompleteApplication.coverLetter;
        this.agreeAccessToProfile = cachedIncompleteApplication.agreeAccessToProfile;
        this.reviewedProfileConfirmation = cachedIncompleteApplication.reviewedProfileConfirmation;
        this.importedProfile = cachedIncompleteApplication.importedProfile;
        this.isSectionsLoading = this.importedProfile;
        this.priorityChoice = cachedIncompleteApplication.priorityChoice;
        this.source = cachedIncompleteApplication.source;
      }
    });
    this.init();
    this.subs.add(this.linkService.onNavigate.subscribe(this.saveIncompleteApplication.bind(this)));
  }

  async init() {
    try {
      const event = await this.eventService.getEvent(this.eventId);
      this.event = event;
      const role = await this.roleService.getRole(this.eventId, this.roleId);
      this.role = role;
      const company = await this.companyService.getCompany();
      this.company = company;
      const currentUser = await this.authService.getLoggedInUser();
      this.currentUser = currentUser;
      if (this.importedProfile) {
        this.user = this.currentUser.user;
      }
    } catch (e) {
      this.errorHandlerService.reportError('ApplyPageComponent.init', 'An error occurred while loading application form')(e);
    } finally {
      this.isLoading = false;
    }
  }

  isFormValid() {
    return (
      this.currentUser &&
      this.userProfile?.isProfileComplete &&
      this.userExperience?.isProfileComplete &&
      this.userContact?.isProfileComplete &&
      this.rightToWork?.isProfileComplete &&
      this.agreeAccessToProfile &&
      (this.role?.info?.payrollRequiredUpFront ? this.payroll.isProfileComplete : true) &&
      this.reviewedProfileConfirmation
    );
  }
  private onSubmitWhenFormInvalid() {
    this.toastrService.error('Application is not yet complete, check above for incomplete sections');
    this.submitAttempted = true;
  }
  submit() {
    if (!this.isFormValid()) {
      return this.onSubmitWhenFormInvalid();
    }
    this.isSaving = true;
    const application = Application.create(this.currentUser.id, this.event.id, this.role.id, this.coverLetter, new Date(), this.priorityChoice, this.source);
    this.isSavingPromise = this.applicationService
      .createApplication(application)
      .then(() => {
        ApplyPageComponent.setCachedIncompleteApplication(this.eventId, this.roleId, null);
        this.navigationStateService.removeCurrentPageFromHistoryStack();
        this.isSaved = true;
        this.linkService.navigate(['profile', 'applications']);
      })
      .catch(
        this.errorHandlerService.reportError(
          'ApplyPageComponent.createApplication',
          'An error occurred while processing application',
          JSON.stringify({ userId: this.currentUser.id, emailVerified: this.currentUser.emailVerified, claims: this.currentUser.claims }),
        ),
      )
      .finally(() => (this.isSaving = false));
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  onSectionsLoaded() {
    setTimeout(() => {
      this.isSectionsLoading = false;
    }, 1000);
  }
  getProfileDetailsPercentage() {
    return this.userProfile ? this.userProfile.completePercentage : 0;
  }

  getProfileExperiencePercentage() {
    return this.userExperience ? this.userExperience.completePercentage : 0;
  }

  getProfileContactPercentage() {
    return this.userContact ? this.userContact.completePercentage : 0;
  }

  getProfileRightToWorkPercentage() {
    return this.rightToWork ? this.rightToWork.completePercentage : 0;
  }

  getProfilePayrollPercentage() {
    return this.payroll ? this.payroll.completePercentage : 0;
  }

  importUser() {
    this.user = this.currentUser.user;
    this.importedProfile = true;
    this.saveIncompleteApplication();
    this.isSectionsLoading = true;
  }

  login() {
    this.linkService.navigate(['login'], { queryParams: { redirect: this.router.url } });
  }

  register() {
    this.linkService.navigate(['register'], { queryParams: { redirect: this.router.url } });
  }

  private saveIncompleteApplication() {
    if (this.eventId && this.roleId && !this.isSaved) {
      ApplyPageComponent.setCachedIncompleteApplication(this.eventId, this.roleId, {
        coverLetter: this.coverLetter,
        reviewedProfileConfirmation: this.reviewedProfileConfirmation,
        agreeAccessToProfile: this.agreeAccessToProfile,
        importedProfile: this.importedProfile,
        priorityChoice: this.priorityChoice,
        source: this.source,
      });
    }
  }

  static getCachedIncompleteApplication(eventId: string, roleId: string): PendingApplication {
    return ApplyPageComponent.cachedIncompleteApplications[eventId + '-' + roleId];
  }

  static setCachedIncompleteApplication(eventId: string, roleId: string, data: PendingApplication) {
    ApplyPageComponent.cachedIncompleteApplications[eventId + '-' + roleId] = data;
  }
}
