<div class="card" [class.is-new]="isNew">
  <div class="card-body clickable w-100" (click)="openSummaryDialog()" *ngIf="!isLoading">
    <div class="row row-no-margin">
      <div class="flex-auto">
        <h5 class="bold">{{ role?.name }}</h5>
      </div>
      <div class="flex-none ml-2">
        <application-status [status]="application.status"> </application-status>
      </div>
    </div>
    <div class="row row-no-margin" *ngIf="isShowingEventInfo">
      <h6 class="flex-none mr-3 vertical-center">Event:</h6>
      <p class="flex-auto bold link event-master-blue-color" (click)="openEvent()">
        {{ event?.name || '-' }}
      </p>
    </div>
    <div class="row row-no-margin" *ngIf="isShowingEventInfo">
      <h6 class=" flex-none mr-3 vertical-center">Date:</h6>
      <p class="flex-auto">
        {{ (event?.dateRange | dateRange) || '-' }}
      </p>
    </div>
    <div class="row row-no-margin">
      <h6 class="flex-none mr-3 vertical-center">Rate:</h6>
      <p class="flex-auto">{{ role | roleRate }} <span *ngIf="!role.rateManual">per hour</span> <span *ngIf="event?.holidayPay"> + Holiday Pay</span></p>
    </div>
    <div class="row row-no-margin">
      <h6 class="flex-none mr-3 vertical-center">Priority:</h6>
      <p class="flex-auto">{{ (application.priorityChoice | numberOrder) || 'None' }}</p>
    </div>
    <div class="row row-no-margin">
      <h6 class="flex-none mr-2 vertical-center">Deposit:</h6>
      <p class="flex-auto" *ngIf="role?.info?.deposit">
        <span *ngIf="depositState === 'NOT_REQUESTED'">Not requested yet</span>
        <span *ngIf="depositState === 'WAITING'" class="mt-1 d-block"
          >Requested {{ deposit?.requested?.toDate() | amTimeAgo }} <button class="btn btn-success ml-2" routerLink="/profile/deposits">Pay Now</button></span
        >
        <span *ngIf="depositState === 'HELD'"><fa-icon class="text-success" [icon]="['fas', 'check']" ngbTooltip="Paid"></fa-icon> {{ deposit?.paid?.toDate() | amTimeAgo }}</span>
      </p>
      <p class="text-muted ml-2" *ngIf="!role?.info?.deposit">Not Required</p>
    </div>
  </div>
  <div class="card-body clickable w-100" *ngIf="isLoading">
    <div class="row row-no-margin">
      <div class="flex-auto">
        <p-skeleton width="5rem"></p-skeleton>
      </div>
      <div class="flex-none ml-2">
        <application-status [status]="application.status"> </application-status>
      </div>
    </div>
    <div class="row row-no-margin" *ngIf="isShowingEventInfo">
      <h6 class="flex-none mr-3 vertical-center">Event:</h6>
      <p class="flex-auto bold link event-master-blue-color">
        <p-skeleton width="5rem"></p-skeleton>
      </p>
    </div>
    <div class="row row-no-margin" *ngIf="isShowingEventInfo">
      <h6 class=" flex-none mr-3 vertical-center">Date:</h6>
      <p class="flex-auto">
        <p-skeleton width="5rem"></p-skeleton>
      </p>
    </div>
    <div class="row row-no-margin">
      <h6 class="flex-none mr-3 vertical-center">Rate:</h6>
      <p class="flex-auto"><p-skeleton width="3rem"></p-skeleton></p>
    </div>
    <div class="row row-no-margin">
      <h6 class="flex-none mr-3 vertical-center">Priority:</h6>
      <p class="flex-auto"><p-skeleton width="3rem"></p-skeleton></p>
    </div>
    <div class="row row-no-margin">
      <h6 class="flex-none mr-2 vertical-center">Deposit:</h6>
      <p class="flex-auto" *ngIf="role?.info?.deposit">
        <p-skeleton width="5rem" class="mt-1"></p-skeleton>
      </p>
    </div>
  </div>
  <div class="card-footer">
    <div class="d-flex">
      <h6 class="flex-auto mr-2">
        Status Tracking:
        <fa-icon [icon]="['fas', 'external-link-alt']" class="ml-1 clickable" (click)="openHistory()"> </fa-icon>
      </h6>
      <div class="vertical-bottom">
        <button
          class="btn btn-danger flex-none"
          *ngIf="application.status === ApplicationStatus.SUBMITTED || application.status === ApplicationStatus.REVIEWING || application.status === ApplicationStatus.ACCEPTED"
          (click)="withdraw()"
          [disabled]="isSaving"
        >
          <fa-icon [icon]="['fas', 'cog']" [spin]="true" class="mr-2" *ngIf="isSaving"></fa-icon>
          Withdraw
        </button>
        <button class="btn btn-warning flex-none" *ngIf="application.status === ApplicationStatus.REJECTED" (click)="submitted()" [disabled]="isSaving">
          <fa-icon [icon]="['fas', 'cog']" [spin]="true" class="mr-2" *ngIf="isSaving"></fa-icon>
          Resubmit
        </button>
      </div>
    </div>
  </div>
</div>
<confirm-dialog></confirm-dialog>
<application-status-change-dialog (onChange)="onStatusChange($event)"></application-status-change-dialog>
<application-history-dialog [isAdmin]="false"> </application-history-dialog>
<application-summary-dialog></application-summary-dialog>
