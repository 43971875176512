import { Component, AfterContentInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'logout-page',
  templateUrl: './logout.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LogoutComponent implements AfterContentInit {
  constructor(private authService: AuthService) {}

  ngAfterContentInit() {
    this.authService.logout();
  }
}
