import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivationEnd, NavigationStart } from '@angular/router';
import { UIBlockedService } from '@app/core/helpers/ui-blocked.service';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnDestroy {
  isFadeIn: boolean = false;

  showingUIBlockedMessage: string = null;

  private subs: Subscription;

  constructor(private router: Router, private uiBlockedService: UIBlockedService) {
    this.subs = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isFadeIn = false;
      }
      if (event instanceof ActivationEnd) {
        if (event.snapshot.data['isFadeIn']) {
          this.isFadeIn = event.snapshot.data['isFadeIn'];
        }
      }
    });

    this.subs.add(this.uiBlockedService.onUpdate.subscribe((isBlocked: string) => (this.showingUIBlockedMessage = isBlocked)));
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
