import { Component, Input } from '@angular/core';
import { ApplicationSource } from '@app/core/services/application.service';
import { CompanyService } from '@app/core/services/company.service';

@Component({
  selector: 'application-source-label',
  template: `
    <p>{{ text }}</p>
  `,
  styles: [],
})
export class ApplicationSourceLabelComponent {
  @Input()
  set source(source: ApplicationSource) {
    this._source = source;
    this.loadingPromise.then(() => (this.text = this.getText(source)));
  }

  get source() {
    return this._source;
  }

  text: string;

  private _source: ApplicationSource;
  private companyName: string;
  private loadingPromise: Promise<void>;
  constructor(private companyService: CompanyService) {
    this.loadingPromise = this.companyService
      .getCompany()
      .then(c => (this.companyName = c.name))
      .then(() => undefined);
  }

  private getText(source: ApplicationSource) {
    const name = this.companyNamePosessive();
    switch (source) {
      case ApplicationSource.INTERNET:
        return 'Internet Search';
      case ApplicationSource.COMPANY_FACEBOOK:
        return `${name} Facebook page`;
      case ApplicationSource.COMPANY_INSTAGRAM:
        return `${name} Instagram page`;
      case ApplicationSource.COMPANY_EMAIL:
        return `${name} Email`;
      case ApplicationSource.COMPANY_WEBSITE:
        return `${name} Website`;
      case ApplicationSource.EVENT_MASTER_SOCIAL:
        return `event master's social media`;
      case ApplicationSource.EVENT_MASTER_JOB_SITE:
        return `event master's job site`;
      case ApplicationSource.WORD_OF_MOUTH:
        return `Word of mouth`;
      default:
        return `Other`;
    }
  }

  private companyNamePosessive() {
    const end = this.companyName.endsWith('s') ? `'` : `'s`;
    return this.companyName + end;
  }
}
