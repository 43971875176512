import { Injectable } from '@angular/core';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class NavigationStateService {
  private history: string[] = ['/'];

  // see https://blog.hackages.io/our-solution-to-get-a-previous-route-with-angular-5-601c16621cf0?gi=7213dc1fc1b1
  constructor(private router: Router, private authService: AuthService) {
    // prevent /login and other pages visited before being authenticated being in history
    this.authService.onLoggedInUserChanged().subscribe(() => this.onReset());

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((navigationEnd: NavigationEnd) => {
      const url = navigationEnd.urlAfterRedirects.trim();
      if (url === '/') {
        this.history = [url];
      } else if (url) {
        this.history.push(url);
      }
    });
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.history.splice(-2);
      }
    });
  }

  navigatePrev() {
    if (this.hasHistory()) {
      const previous = this.previousUrlAndQueryParams();
      // remove last 2 history entries on successful navigation to prevent an endless back loop
      if (previous) {
        this.history.splice(-2);
        this.router.navigate([previous.url], { queryParams: previous.queryParams });
      }
    }
  }

  hasHistory(): boolean {
    return this.history.filter(h => h !== '/').length >= 1;
  }

  removeCurrentPageFromHistoryStack() {
    if (this.history.length >= 1) {
      this.history.splice(-1);
    }
  }

  private onReset(): void {
    this.history = ['/'];
  }

  private previousUrlAndQueryParams(): { url: string; queryParams: object } {
    const url = this.history[this.history.length - 2];
    if (url) {
      return this.urlAndQueryParams(url);
    } else {
      return null;
    }
  }

  private urlAndQueryParams(fullUrl: string): { url: string; queryParams: object } {
    // 'http://localhost:4200/home?pwa=true' => ['http://localhost:4200/home', 'pwa=true']
    const [url, params] = fullUrl.split('?');

    const urlAndQueryParams = { url, queryParams: {} };
    if (params) {
      new URLSearchParams(`?${params}`).forEach((value, key) => (urlAndQueryParams.queryParams[key] = value));
    }
    return urlAndQueryParams;
  }
}
