import { Component, OnDestroy, NgZone, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LinkService } from '@app/core/link.service';
import { AuthService } from '@app/core/services/auth.service';
import { ErrorHandlerService } from '@app/core/error-handler.service';

@Component({
  selector: 'login-buttons',
  templateUrl: './login-buttons.component.html',
  styleUrls: ['./login-buttons.component.scss'],
})
export class LoginButtonsComponent implements OnDestroy {
  @Input()
  redirectTo: string;

  @Input()
  redirectBackHere: boolean = false;

  isLoading = false;

  @Output()
  onLoggedIn: EventEmitter<void> = new EventEmitter<void>();

  private subs: Subscription;

  private isRefreshed = false;

  private isStillOnPage: boolean = true;

  constructor(
    protected errorHandlerService: ErrorHandlerService,
    protected authService: AuthService,
    protected linkService: LinkService,
    protected route: ActivatedRoute,
    private router: Router,
    protected ngZone: NgZone,
  ) {}

  onLogInComplete() {
    this.isLoading = true;
    this.subs = this.authService.onLoggedInUserChanged().subscribe(user => {
      if (user && !this.isRefreshed) {
        this.isRefreshed = true;
        this.authService
          .refreshCustomClaimsForUser()
          .catch(e => {
            console.error('Custom claim refresh failed, but carrying on with login anyway');
          })
          .finally(() => {
            this.ngZone.run(() => {
              if (user.isMinimumFieldsComplete()) {
                const link = this.route.snapshot.queryParams.redirect || this.redirectTo;
                if (link && this.isStillOnPage) {
                  this.linkService.navigate([link], { replaceUrl: true });
                }
              } else {
                let params = {};
                if (this.route.snapshot.queryParams.redirect) {
                  params = { queryParams: { redirect: this.route.snapshot.queryParams.redirect } };
                } else if (this.redirectBackHere) {
                  params = { queryParams: { redirect: this.router.url } };
                }
                this.linkService.navigate(['profile', 'edit'], params);
              }
              this.onLoggedIn.emit();
            });
          });
      } else {
        this.errorHandlerService.reportError(
          'LoginButtonsComponent.onLogInComplete()',
          'Account creation failed, please try again later',
          JSON.stringify({ user }),
        )(new Error('Failed account creation'));

        this.reset();
      }
    });
  }

  private reset() {
    this.subs?.unsubscribe();
    this.isLoading = false;
    this.isRefreshed = false;
    this.isStillOnPage = false;
    this.authService.logout();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.isStillOnPage = false;
  }
}
