import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { MomentModule } from 'ngx-moment';

import { UserAvatarComponent } from './user/user-avatar/user-avatar.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog.component';
import { SlideToggleButtonComponent } from './widgets/slide-toggle-button.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExperienceLabelComponent } from './user/experience-label/experience-label.component';
import { IdentificationTypeLabelComponent } from './user/identification-type-label/identification-type-label.component';
import { DocumentLabelComponent } from './user/document-label/document-label.component';
import { CurrentUserAvatarComponent } from './user/user-avatar/current-user-avatar.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageUploaderComponent } from './widgets/image-uploader/image-uploader.component';
import { ImageUploaderDialogComponent } from './widgets/image-uploader/image-uploader-dialog.component';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { ApplicationStatusChangeDialogComponent } from './application/card/application-status-change-dialog.component';
import { ApplicationStatusComponent } from './application/application-status.component';
import { ApplicationHistoryDialogComponent } from './application/history/application-history-dialog.component';
import { DatepickerComponent } from './widgets/datepicker/single/date-picker.component';
import { LoginButtonsComponent } from './login/login-buttons.component';
import { ForceLoginDialogComponent } from './login/force-login/force-login.dialog.component';
import { UserApplicationCardComponent } from './application/card/user-application-card.component';
import { EllipsisModule } from 'ngx-ellipsis';
import { DatetimePickerComponent } from './widgets/datepicker/single/datetime-picker.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { UpperCaseFirstLetterPipe } from './pipes/upper-case-first-letter.pipe';
import { EnumToStringPipe } from './pipes/enum-to-string';
import { TwoDigitsPipe } from './pipes/two-digits.pipe';
import { StripHttpPipe } from './pipes/strip-http.pipe';
import { LinkDirective } from './widgets/link.directive';
import { FirebaseUIModule, firebase, firebaseui } from 'firebaseui-angular';
import { NumberOrderPipe } from './pipes/number-order.pipe';
import { FontAwesomeModule, FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';
import { FaIconsLibrary } from '@app/core/icons.library';
import { ApplicationSourceLabelComponent } from './application/application-source-label.component';
import { PossessivePipe } from './pipes/possessive.pipe';
import { ReferenceResponseStatusComponent } from './user/reference-response-status/reference-response-status.component';
import { ReferenceResponseReviewDialogComponent } from './user/reference-response-status/reference-response-review-dialog.component';
import { ApplicationSummaryDialogComponent } from './application/summary/application-summary-dialog.component';
import { CurrentUserRightToWorkComponent } from './application/user-details/current-user-right-to-work.component';
import { CurrentUserContactComponent } from './application/user-details/current-user-contact.component';
import { CurrentUserExperienceComponent } from './application/user-details/current-user-experience.component';
import { CurrentUserInfoComponent } from './application/user-details/current-user-info.component';
import { DateRangePipe } from './pipes/date-range.pipe';
import { PluralPipe } from './pipes/plural';
import { LinkActiveDirective } from './widgets/linkActive.directive';
import { DeferModule } from 'primeng/defer';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { TimeDayRangePipe } from './pipes/time-day-range.pipe';
import { RoleRatePipe } from './pipes/role-rate.pipe';
import { RoleShiftsPipe } from './pipes/role-shifts.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { PositionColourDirective } from './widgets/position-colour.directive';
import { DatetimeRangePickerComponent } from './widgets/datepicker/range/datetime-range-picker.component';
import { DatetimeRangePickerDialogComponent } from './widgets/datepicker/range/datetime-range-picker-dialog.component';
import { UserFullNameComponent } from './user/user-fullname/user-fullname.compoent';
import { SkeletonModule } from 'primeng/skeleton';
import { ApplicationHistoryComponent } from './application/history/application-history.component';
import { CurrentUserPayrollComponent } from './application/user-details/current-user-payroll.component';
import { DatetimePickerDialogComponent } from './widgets/datepicker/single/dialog/datetime-picker-dialog.component';
import { TimePickerComponent } from './widgets/datepicker/single/time-picker.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'redirect',
  signInOptions: [
    {
      scopes: ['public_profile', 'email'],
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Facebook',
    },
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Email',
      recaptchaParameters: {
        type: 'image', // 'audio'
        size: 'normal', // 'invisible' or 'compact'
        badge: 'bottomleft', //' bottomright' or 'inline' applies to invisible.
      },
    },
  ],
  tosUrl: '/terms-of-service',
  privacyPolicyUrl: '/privacy',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    MomentModule,
    EllipsisModule,
    ImageCropperModule,
    NgxFileHelpersModule,
    NgbModule,
    FontAwesomeModule,
    DeferModule,
    InputMaskModule,
    CalendarModule,
    SkeletonModule,
  ],
  declarations: [
    LoginButtonsComponent,
    ForceLoginDialogComponent,
    LoaderComponent,
    DatepickerComponent,
    UserFullNameComponent,
    ImageUploaderComponent,
    DatetimePickerDialogComponent,
    ApplicationHistoryDialogComponent,
    ApplicationHistoryComponent,
    ApplicationStatusComponent,
    DatetimeRangePickerComponent,
    ApplicationStatusChangeDialogComponent,
    UserApplicationCardComponent,
    ImageUploaderDialogComponent,
    DocumentLabelComponent,
    TimePickerComponent,
    CurrentUserAvatarComponent,
    IdentificationTypeLabelComponent,
    DatetimeRangePickerDialogComponent,
    ExperienceLabelComponent,
    UserAvatarComponent,
    ConfirmDialogComponent,
    SlideToggleButtonComponent,
    DatetimePickerComponent,
    LinkDirective,
    StripHttpPipe,
    TwoDigitsPipe,
    EnumToStringPipe,
    UpperCaseFirstLetterPipe,
    PossessivePipe,
    NumberOrderPipe,
    ApplicationSourceLabelComponent,
    ReferenceResponseStatusComponent,
    ReferenceResponseReviewDialogComponent,
    ApplicationSummaryDialogComponent,
    CurrentUserRightToWorkComponent,
    CurrentUserContactComponent,
    CurrentUserExperienceComponent,
    CurrentUserInfoComponent,
    CurrentUserPayrollComponent,
    DateRangePipe,
    RoleRatePipe,
    RoleShiftsPipe,
    TimeDayRangePipe,
    PositionColourDirective,
    PluralPipe,
    LinkActiveDirective,
    MoneyPipe,
  ],
  providers: [],
  exports: [
    // Modules
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule,
    NgbModule,
    RouterModule,
    NgxFileHelpersModule,
    EllipsisModule,
    InputMaskModule,
    FontAwesomeModule,
    DeferModule,

    // Components
    LoaderComponent,
    UserApplicationCardComponent,
    ApplicationSourceLabelComponent,
    LoginButtonsComponent,
    ForceLoginDialogComponent,
    TimePickerComponent,
    DatepickerComponent,
    UserFullNameComponent,
    ImageUploaderComponent,
    ApplicationHistoryDialogComponent,
    ApplicationStatusComponent,
    ApplicationStatusChangeDialogComponent,
    DatetimePickerDialogComponent,
    PossessivePipe,
    DatetimeRangePickerComponent,
    ImageUploaderDialogComponent,
    DocumentLabelComponent,
    CurrentUserAvatarComponent,
    DatetimePickerComponent,
    IdentificationTypeLabelComponent,
    DatetimeRangePickerDialogComponent,
    ExperienceLabelComponent,
    UserAvatarComponent,
    ConfirmDialogComponent,
    SlideToggleButtonComponent,
    LinkDirective,
    StripHttpPipe,
    TwoDigitsPipe,
    EnumToStringPipe,
    UpperCaseFirstLetterPipe,
    NumberOrderPipe,
    ReferenceResponseStatusComponent,
    ReferenceResponseReviewDialogComponent,
    CurrentUserRightToWorkComponent,
    CurrentUserContactComponent,
    CurrentUserExperienceComponent,
    CurrentUserInfoComponent,
    CurrentUserPayrollComponent,
    DateRangePipe,
    RoleRatePipe,
    RoleShiftsPipe,
    PluralPipe,
    LinkActiveDirective,
    TimeDayRangePipe,
    PositionColourDirective,
    MoneyPipe,
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    FaIconsLibrary.setupLibrary(library);
    faConfig.fixedWidth = true;
  }
}
