import { Timestamp } from './firebase.model';

export class Deposit {
  id: string;
  requested: Timestamp;
  paid: Timestamp;
  refunded: Timestamp;
  amount: number;
  paymentId: string;
  receipt: string;
  url: string;
}

export class CompanyDeposits {
  companyId: string;
  deposits: Deposit[];
}

export function parseCompanyDeposits(doc: any) {
  console.log('Parsing retrieved company deposits');
  const data = doc.data();
  const companyDeposits = new CompanyDeposits();
  companyDeposits.companyId = doc.id;
  companyDeposits.deposits = Object.keys(data || {}).map(depositId => {
    const deposit = Object.assign(new Deposit(), data[depositId]);
    deposit.id = depositId;
    return deposit;
  });
  return companyDeposits;
}
