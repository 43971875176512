import { Input, ViewChild, EventEmitter, Output, AfterContentInit, Directive } from '@angular/core';
import { Application, ApplicationService, ApplicationStatus } from '@app/core/services/application.service';
import { ConfirmDialogComponent } from '@app/shared/dialog/confirm-dialog.component';
import { LinkService } from '@app/core/link.service';
import { UserService } from '@app/core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Role } from '@app/core/models/role.model';
import { ApplicationStatusChangeDialogComponent } from './application-status-change-dialog.component';
import { ApplicationStatusComponent } from '../application-status.component';
import { ErrorHandlerService } from '@app/core/error-handler.service';
import { ApplicationHistoryDialogComponent } from '../history/application-history-dialog.component';
import { DepositService } from '@app/core/services/deposit.service';
import { CompanyService } from '@app/core/services/company.service';
import sortBy from 'lodash-es/sortBy';
import { Deposit } from '@app/core/models/deposit.model';
import { RoleService } from '@app/core/services/role.service';
import { AuthService } from '@app/core/services/auth.service';
import { Event, EventService } from '@app/core/services/event.service';

@Directive()
export class ApplicationCardBase implements AfterContentInit {
  @ViewChild(ApplicationHistoryDialogComponent)
  historyDialog: ApplicationHistoryDialogComponent;

  @Input()
  application: Application;

  @Input()
  isApprovalMode: boolean = false;

  @Output()
  onChange = new EventEmitter<Application>();

  @Output()
  onChangePromise = new EventEmitter<Promise<void>>();

  @ViewChild(ConfirmDialogComponent)
  configDialog: ConfirmDialogComponent;

  @ViewChild(ApplicationStatusChangeDialogComponent)
  applicationStatusChangeDialog: ApplicationStatusChangeDialogComponent;

  ApplicationStatus = ApplicationStatus;

  isOwnApplication: boolean = false;

  isSaving: boolean = false;
  isLoading: boolean = false;

  event: Event;

  role: Role;
  depositState: string;
  deposit: Deposit;

  constructor(
    protected errorHandlerService: ErrorHandlerService,
    protected toastr: ToastrService,
    protected linkService: LinkService,
    protected applicationService: ApplicationService,
    protected authService: AuthService,
    protected roleService: RoleService,
    protected depositService: DepositService,
    protected companyService: CompanyService,
    protected eventService: EventService,
  ) {}

  openEvent() {
    this.linkService.navigate(['/events', this.application.eventId]);
  }

  ngAfterContentInit() {
    this.isLoading = true;
    this.authService
      .getLoggedInUser()
      .then(user => {
        this.isOwnApplication = user.id === this.application.userId;
      })
      .then(() => this.setupEvent(this.application))
      .then(() => this.setupDepositIfRequired(this.application))
      .finally(() => (this.isLoading = false));
  }

  openCoverLetter() {
    this.configDialog.openConfirm({
      title: 'Cover Letter',
      cancelText: 'Close',
      message: this.application.coverLetter,
      isLarge: true,
    });
  }

  withdraw() {
    this.applicationStatusChangeDialog.openForApplicationStatus(ApplicationStatus.WITHDRAWN);
  }

  reject() {
    this.applicationStatusChangeDialog.openForApplicationStatus(ApplicationStatus.REJECTED);
  }

  approve() {
    this.applicationStatusChangeDialog.openForApplicationStatus(ApplicationStatus.ACCEPTED);
  }

  submitted() {
    this.applicationStatusChangeDialog.openForApplicationStatus(ApplicationStatus.SUBMITTED);
  }

  reviewing() {
    this.setStatus(ApplicationStatus.REVIEWING, 'Started reviewing application');
  }

  statusText(status: ApplicationStatus) {
    return ApplicationStatusComponent.statusText(status, true);
  }

  onStatusChange(result: { notes: string; status: ApplicationStatus }) {
    this.setStatus(result.status, result.notes);
  }

  openHistory() {
    this.historyDialog.openWithHistory(this.application.history);
  }

  private setStatus(status: ApplicationStatus, notes: string): Promise<void> {
    this.isSaving = true;
    const cloned = Application.clone(this.application);
    cloned.status = status;
    const promise = this.applicationService
      .update(cloned, notes)
      .then(application => (this.application = application))
      .then(() => this.onChange.emit(this.application))
      .catch(
        this.errorHandlerService.reportError(
          'ApplicationCardBase.setStatus',
          'An error occurred updating Application Status',
          JSON.stringify({ status, notes, application: this.application }),
        ),
      )
      .finally(() => (this.isSaving = false));

    this.onChangePromise.emit(promise);
    return promise;
  }

  async setupEvent(application: Application) {
    try {
      this.event = await this.eventService.getEventFromCache(application.eventId);
    } catch (e) {
      this.errorHandlerService.reportError('ApplicationCardBase', 'Error ocurred while loading event info', JSON.stringify(application, undefined, 2))(e);
    }
  }
  async setupDepositIfRequired(application: Application) {
    try {
      if (!this.role) {
        this.role = await this.roleService.getRole(application.eventId, application.roleId);
      }
      if (this.role?.info?.depositAmount) {
        const depositsForCompany = await this.depositService.getDepositsForCompany(application.userId, this.companyService.getId());

        const waiting = depositsForCompany.deposits.filter(d => !d.paid);
        if (waiting.length) {
          this.depositState = 'WAITING';
          this.deposit = sortBy(waiting, d => d.requested)[waiting.length - 1];
          return;
        }

        const paid = depositsForCompany.deposits.filter(d => d.paid && !d.refunded);
        if (paid.length) {
          this.depositState = 'HELD';
          this.deposit = sortBy(paid, d => d.requested)[paid.length - 1];
          return;
        }

        const isAllRefunded = !depositsForCompany.deposits.find(d => !d.refunded);
        if (!depositsForCompany.deposits.length || isAllRefunded) {
          this.depositState = 'NOT_REQUESTED';
        }
      }
    } catch (e) {
      this.errorHandlerService.reportError('ApplicationCardBase', 'Error ocurred while loading deposit info', application.id)(e);
    }
  }
}
