<header [class.fixed]="isFadeIn">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="background" [class.fadeIn]="isFadeIn" [class.transparent]="transparent"></div>
    <div class="container py-0">
      <div id="navbar-menu">
        <div class="back-nav mobile vertical-center mr-2" (click)="isShowingBackNavButton() && navigateBack()">
          <fa-icon [icon]="['fas', 'chevron-left']" *ngIf="isShowingBackNavButton()"></fa-icon>
        </div>
        <div class="navbar-nav mobile ml-2" *ngIf="showSideMenuExpander">
          <fa-icon [icon]="['fas', 'bars']" class="vertical-center" size="2x" aria-controls="navbar-menu" aria-label="Toggle navigation" (click)="toggleSideMenu()"></fa-icon>
        </div>
        <a class="navbar-brand" link="/" [class.fadeIn]="isFadeIn" [class.desktop]="!showLogoMobile">
          <img [src]="company.logo" class="clickable" />
        </a>
        <h3 class="vertical-center link-black desktop clickable" [class.fadeIn]="isFadeIn" link="/" *ngIf="!event">
          {{ company.name }}
        </h3>
        <h2 class="vertical-center link-black desktop my-0 clickable" [class.fadeIn]="isFadeIn" link="/dashboard/events/{{ event.id }}" *ngIf="event">
          {{ event.name }}
        </h2>
        <div class="ml-auto d-flex">
          <div class="nav-item h-100 vertical-center mr-3" *ngIf="share">
            <button class="btn btn-primary desktop mr-3" (click)="shareLink()"><fa-icon [icon]="['fas', 'share-alt']"></fa-icon>Share this page</button>
            <fa-icon class="mobile event-master-blue-color" [icon]="['fas', 'share-alt']" (click)="shareLink()"> </fa-icon>
          </div>
          <div class="navbar-nav">
            <div class="flex-auto  logged-out-buttons nav-item d-flex flex-column-mobile justify-content-center" *ngIf="!isLoggedIn()">
              <div class="text-center vertical-center">
                <button class="btn auth btn-success first bold" link="/register"><fa-icon [icon]="['fas', 'user-plus']"></fa-icon>Register</button>
              </div>
              <div class="text-center  vertical-center">
                <button class="btn auth btn-warning bold" link="/login" (click)="onLoginButtonClick()"><fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>Log in</button>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-item" ngbDropdown display="dynamic" placement="bottom-right" *ngIf="isLoggedIn()">
          <a id="user-dropdown" class="nav-link p-0" ngbDropdownToggle>
            <user-avatar [user]="currentUser && currentUser.user" size="4.5rem" class="mt-1"></user-avatar>
          </a>
          <div ngbDropdownMenu aria-labelledby="user-dropdown">
            <h6 class="dropdown-header" *ngIf="isLoggedIn()">
              <span>Logged in as</span> <b class="ml-2">{{ getUserName() }}</b>
            </h6>
            <div class="dropdown-divider" *ngIf="isLoggedIn()"></div>
            <button class="dropdown-item" *ngIf="isLoggedIn() && isAdmin" link="/dashboard">Dashboard</button>
            <button class="dropdown-item" *ngIf="isLoggedIn() && isTimesheetManager" link="/timesheet-dashboard">Timesheets</button>
            <button class="dropdown-item" *ngIf="isLoggedIn()" link="/profile">Profile</button>
            <button class="dropdown-item" *ngIf="isLoggedIn()" link="/profile/applications">Applications</button>
            <button class="dropdown-item" *ngIf="isLoggedIn()" link="/profile/deposits">Deposits</button>
            <button class="dropdown-item" *ngIf="isLoggedIn()" link="/profile/payroll">Payroll</button>
            <button class="dropdown-item" *ngIf="isLoggedIn()" link="/profile/settings">Settings</button>
            <button class="dropdown-item" *ngIf="isLoggedIn()" (click)="logout()">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
