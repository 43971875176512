import { Injectable, ErrorHandler } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './services/user.service';
import { CurrentUser } from './models/user.model';
import { environment } from '../../environments/environment';
import { merge } from 'rxjs';
import { AuthService } from './services/auth.service';

declare const Sentry: any;

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private currentUser: CurrentUser;

  constructor(private toastr: ToastrService, private authService: AuthService) {
    merge(this.authService.getLoggedInUser(), this.authService.onLoggedInUserChanged()).subscribe(currentUser => (this.currentUser = currentUser));
  }

  reportError(name: string, toastrMessage?: string, data?: string) {
    return ((error: any) => {
      if (toastrMessage) {
        this.toastr.error(error?.message?.includes('client is offline') ? 'Check network connection, event master is experiencing connection issues' : toastrMessage);
      }
      console.error(error);
      Sentry.setTag('file', name);
      Sentry.setTag('version', environment.version);
      Sentry.setExtras({ data });
      Sentry.setTag('email', this.currentUser ? this.currentUser.email : 'not logged in');
      Sentry.captureException(error.originalError || error || 'An error occurred with no message');
      return Promise.reject(error);
    }).bind(this);
  }

  reportErrorSkippable(name: string, toastrMessage?: string, data?: string) {
    return (error: any) => {
      this.reportError(name, toastrMessage, data)(error);
      return Promise.resolve();
    };
  }
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private currentUser: CurrentUser;

  constructor(private authService: AuthService) {
    merge(this.authService.getLoggedInUser(), this.authService.onLoggedInUserChanged()).subscribe(currentUser => (this.currentUser = currentUser));
  }

  handleError(error: any) {
    console.error(error);
    Sentry.setTag('version', environment.version);
    Sentry.setTag('email', this.currentUser ? this.currentUser.email : 'not logged in');

    Sentry.captureException(error.originalError || error);
  }
}
