import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FirebaseConfigHelper } from '@app/../firebase-config/firebase-config.helper';
import { Timestamp } from '@app/core/models/firebase.model';
import { parseUserLite, parseUserBasic, parseUserExtras } from '../models/user.model';
import { Company, parseCompany, UserIdentifier } from '../models/company.model';
import firebase from 'firebase/app';

@Injectable()
export class CompanyService {
  private loadingPromise: Promise<Company>;
  private companyId: string;
  private company: Company;

  private onLoadingComplete = new EventEmitter<void>();

  private onUpdate: EventEmitter<Company> = new EventEmitter<Company>();

  constructor(private firestore: AngularFirestore) {
    this.companyId = FirebaseConfigHelper.getConfig().companyId;
    this.loadingPromise = new Promise((resolve, _) => {
      const unsub = this.onLoadingComplete.subscribe(() => {
        resolve(this.company);
        unsub.unsubscribe();
      });
    });
  }

  init() {
    this.loadCompany()
      .then(company => (this.company = company))
      .then(() => this.onLoadingComplete.emit());
  }

  getId() {
    return this.companyId;
  }

  onLoaded() {
    return this.loadingPromise;
  }

  update(company: Company): Promise<Company> {
    const json = Company.toJSON(company);
    return this.firestore
      .doc('companys/' + this.companyId)
      .set(json, { merge: true })
      .then(async () => {
        this.company = await this.getCompanyById(this.companyId);
        this.onUpdate.emit(this.company);
        this.loadingPromise = Promise.resolve(this.company);
        return this.company;
      });
  }

  getCompany(): Promise<Company> {
    return this.loadingPromise;
  }

  getCompanyById(companyId: string): Promise<Company> {
    return this.firestore
      .doc(`companys/${companyId}`)
      .get()
      .toPromise()
      .then(parseCompany.bind(this));
  }

  getCompanies(ids: string[]): Promise<Company[]> {
    return Promise.all(
      ids.map(id =>
        this.firestore
          .collection('companys')
          .doc(id)
          .get()
          .toPromise(),
      ),
    ).then(actions => actions.map(parseCompany.bind(this)));
  }

  getCompanyMetadataContacts() {
    return this.getCompanyMetadata('contacts').then(data =>
      Object.keys(data).map(userId => {
        return {
          id: userId,
          added: data[userId].added as Timestamp,
          user: parseUserLite(data[userId].userLite, userId),
          extras: parseUserExtras(data[userId].extras, this.companyId),
        };
      }),
    );
  }

  getCompanyMetadataDeposits() {
    return this.getCompanyMetadata('deposits').then(data =>
      Object.keys(data).map(userId => {
        return {
          userId: userId,
          latestDeposit: data[userId],
        };
      }),
    );
  }

  getCompanyMetadataInterested() {
    return this.getCompanyMetadata('interested').then(data =>
      Object.keys(data).map(userId => {
        return {
          id: userId,
          added: data[userId].added as Timestamp,
          user: parseUserBasic(data[userId].userBasic, userId),
        };
      }),
    );
  }

  getCompanyMetadataApplications() {
    return this.getCompanyMetadata('applications').then(data =>
      Object.keys(data).map(applicationId => {
        return {
          added: data[applicationId].added as Timestamp,
          id: applicationId,
          eventId: data[applicationId].eventId,
        };
      }),
    );
  }

  getCompanyMetadataTraffic() {
    return this.getCompanyMetadata('traffic').then(data =>
      Object.keys(data).map(day => {
        return {
          date: day,
          views: data[day],
        };
      }),
    );
  }

  getCompanyAutoIdentifiersApproved(): Promise<UserIdentifier[]> {
    return this.getCompanyAutoIdentifiers('approved');
  }

  getCompanyAutoIdentifiersBlacklisted(): Promise<UserIdentifier[]> {
    return this.getCompanyAutoIdentifiers('blacklisted');
  }

  patchCompanyAuthIdentifiersApproved(config: UserIdentifier[]) {
    return this.patchCompanyAuthIdentifiers('approved', config);
  }

  patchCompanyAuthIdentifiersBlacklisted(config: UserIdentifier[]) {
    return this.patchCompanyAuthIdentifiers('blacklisted', config);
  }

  onCompanyUpdate(): Observable<Company> {
    return this.onUpdate;
  }

  private async patchCompanyAuthIdentifiers(type: string, config: UserIdentifier[]) {
    const updates = UserIdentifier.getDiffAsJSON(config, await this.getCompanyAutoIdentifiers(type));
    Object.keys(updates || {}).forEach(key => {
      if (!updates[key]) {
        updates[key] = firebase.firestore.FieldValue.delete();
      }
    });
    console.log(`patching company auto ${type} config with: ${Object.keys(updates || {})}`, updates);
    const company = await this.loadingPromise;
    return this.firestore.doc(`companys/${company.id}/auto-identifiers/${type}`).set(updates, { merge: true });
  }

  private async getCompanyAutoIdentifiers(type: string): Promise<any> {
    const company = await this.loadingPromise;
    return this.firestore
      .doc(`companys/${company.id}/auto-identifiers/${type}`)
      .get()
      .toPromise()
      .then(doc => doc.data())
      .then(UserIdentifier.parse.bind(this));
  }

  private getCompanyMetadata(type: string) {
    return this.loadingPromise.then(company => {
      const result = this.firestore.doc(`companys/${company.id}/metadata/${type}`);
      return result
        .get()
        .toPromise()
        .then(doc => doc.data() || {});
    });
  }

  private loadCompany(): Promise<Company> {
    console.info('getting company: ' + this.companyId);
    const result = this.firestore.doc('companys/' + this.companyId);
    return result
      .get()
      .toPromise()
      .then(doc => parseCompany(doc));
  }
}
