<div class="app">
	<app-header [isFadeIn]="isFadeIn"></app-header>
	<router-outlet></router-outlet>
	<app-footer></app-footer>
</div>
<p-blockUI [blocked]="showingUIBlockedMessage">
	<div class="w-100 h-100 vertical-center">
		<fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="3x" class="text-white ml-auto mr-auto"></fa-icon>
		<div class="ml-auto mr-auto text-white">{{ showingUIBlockedMessage }}</div>
	</div>
</p-blockUI>
