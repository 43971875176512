import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

@Injectable()
export class LinkService {
  onNavigate: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router, private route: ActivatedRoute) {}

  navigate(urls: string[], options?: NavigationExtras) {
    if (urls.length > 1 && urls.lastIndexOf('/') === urls.length - 1) {
      urls.pop();
    }
    this.onNavigate.emit();
    this.router.navigate([...urls], options);
  }

  navigateRelative(urls: string[], options?: NavigationExtras) {
    if (urls.length > 1 && urls.lastIndexOf('/') === urls.length - 1) {
      urls.pop();
    }
    this.onNavigate.emit();
    this.router.navigate([...this.router.url.split('/'), ...urls], options);
  }
}
