<p-calendar
  [(ngModel)]="time"
  (ngModelChange)="onTimeUpdated($event)"
  [baseZIndex]="1060"
  icon="pi pi-clock"
  appendTo="body"
  [focusTrap]="isMobile"
  [defaultDate]="defaultTime"
  [showButtonBar]="clearButton"
  hourFormat="24"
  [hideOnDateTimeSelect]="false"
  [showTime]="true"
  [timeOnly]="true"
  [touchUI]="isMobile"
  [readonlyInput]="true"
  [stepMinute]="1"
  todayButtonStyleClass="d-none"
  clearButtonStyleClass="p-button-text mx-auto"
  (onClearClick)="clear()"
>
</p-calendar>
