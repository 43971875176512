import { Component } from '@angular/core';
import { CompanyService } from '@app/core/services/company.service';
import { Company } from '@app/core/models/company.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  company: Company;

  constructor(protected companyService: CompanyService) {
    companyService.getCompany().then(company => this.company = company);
  }
}
