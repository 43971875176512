import { PipeTransform, Pipe } from '@angular/core';
import { DateRange } from '@app/core/models/firebase.model';
import * as moment from 'moment';

@Pipe({ name: 'dateRange', pure: false })
export class DateRangePipe implements PipeTransform {
  transform(range: DateRange, args: any) {
    if (!range || !range.start || !range.end) {
      return null;
    }
    const year = args?.indexOf('showYear') > -1 ? 'YYYY' : '';
    const time = args?.indexOf('showTime') > -1 ? 'HH:mm' : '';
    const month = args?.indexOf('hideMonth') > -1 ? '' : 'MMMM';
    const dayOfWeek = args?.indexOf('showDayOfWeek') > -1 ? 'ddd' : '';

    const endDateFormat = `${dayOfWeek} Do ${month} ${year}`;
    const startTimeFormat = time ? ` @ ${time}` : '';
    const monthIfNeeded = range.start.toDate().getMonth() !== range.end.toDate().getMonth() ? month : '';

    const startDate = moment(range.start.toDate());

    const endDate = moment(range.end.toDate());
    if (this.monthDay(startDate) !== this.monthDay(endDate)) {
      const format = `${dayOfWeek} Do ${monthIfNeeded}`;
      return startDate.format(format + startTimeFormat) + ' - ' + endDate.format(endDateFormat + startTimeFormat);
    } else {
      const startTimeIfRequired = time ? startDate.format(startTimeFormat) + ' - ' : '';
      return endDate.format(endDateFormat) + startTimeIfRequired + (time ? endDate.format(time) : '');
    }
  }

  private monthDay(date: moment.Moment) {
    return date.get('month') + '-' + date.get('date');
  }
}
