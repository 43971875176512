<table class="table table-striped no-more-tables">
	<thead>
		<tr>
			<th>
				<h6>Updated</h6>
			</th>
			<th>
				<h6>Status</h6>
			</th>
			<th>
				<h6>
					Updated by
				</h6>
			</th>

			<th>
				<h6>Notes</h6>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let item of history; index as i">
			<td data-title="Updated">
				<p>{{ item.date.toDate() | amTimeAgo }}</p>
			</td>
			<td data-title="Application Status">
				<application-status [status]="item.status" [text]="i < history.length - 1" [isAdmin]="isAdmin"> </application-status>
			</td>
			<td data-title="Updated By">
				<p>{{ isAdmin || ['SUBMITTED', 'WITHDRAWN'].includes(item.status) ? item.user : 'Admin' }}</p>
			</td>
			<td data-title="Notes">
				<p>{{ item.notes ? item.notes : '-' }}</p>
			</td>
		</tr>
	</tbody>
</table>
