<div class="card" (click)="open()">
  <div class="card-img-top">
    <img [src]="event.imageMini || '/assets/default-event.jpg'" />
    <div class="date-range flex-column">
      <div class="flex-auto date-wrapper vertical-center">
        <h4>
          {{ event.dateRange | dateRange: 'showYear' }}
        </h4>
      </div>
    </div>
  </div>
  <div class="card-body flex-column p-3">
    <div class="flex-none row row-no-margin">
      <div class="flex-auto">
        <h4 class="event-master-blue-color text-left">{{ event.name }}</h4>
      </div>
    </div>
    <div class="flex-auto row row-no-margin mt-3 flex-column-mobile">
      <div class="flex-auto flex-column justify-content-end">
        <h6 class="event-master-blue-color text-left" *ngIf="event.location">
          <fa-icon [icon]="['fas', 'map-marker']" class="event-master-gold-color"></fa-icon>
          {{ event.location }}
        </h6>
      </div>
    </div>
    <div class="flex-none text-right desktop">
      <button class="btn btn-success" routerLink="../events/{{ event.id }}">Show Details</button>
    </div>
  </div>
</div>
