import { NgModule, ErrorHandler } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared/shared.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { JobsModule } from './jobs/jobs.module';
import { SentryErrorHandler } from './core/error-handler.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgcCookieConsentConfig, NgcCookieConsentModule, NgcCookieConsentService } from 'ngx-cookieconsent';

let domain;
if (window.location.hostname.includes('eventmaster.jobs')) {
  domain = '.eventmaster.jobs';
} else {
  domain = window.location.hostname;
}
console.log(domain);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: domain,
  },
  position: 'bottom-left',
  theme: 'classic',
  palette: {
    popup: {
      background: '#2d5ba7',
      text: '#ffffff',
      link: '#fdc330',
    },
    button: {
      background: '#26af89',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'opt-in',
  content: {
    message: 'We use cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Only essential',
    link: 'Learn more',
    href: 'https://eventmaster.jobs/privacy',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: true }),
    CoreModule,
    SharedModule,
    ShellModule,
    JobsModule,
    AuthModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private cookieService: NgcCookieConsentService) {}
}
