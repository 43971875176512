import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
  transform(value: number) {
    if (value == null) {
      return null;
    }
    return '£' + value.toFixed(2);
  }
}
