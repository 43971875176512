import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from '@app/core/services/user.service';
import { LinkService } from '@app/core/link.service';
import { ApplicationService } from '@app/core/services/application.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class DuplicateApplicationGuard implements CanActivate {
  constructor(private linkService: LinkService, private applicationService: ApplicationService, private authService: AuthService, private toastrService: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const eventId = route.params['eventId'];
    const roleId = route.params['roleId'];
    return this.authService
      .getLoggedInUser()
      .then(user => {
        if (user && user.user) {
          return this.applicationService.getActiveApplicationsForUserAndRole(user.user, roleId);
        } else {
          return [];
        }
      })
      .then(activeApplicationsForRole => {
        if (activeApplicationsForRole.length) {
          this.linkService.navigate(['events', eventId]);
          this.toastrService.warning('You already have an active application for this role');
          return false;
        } else {
          return true;
        }
      });
  }
}
