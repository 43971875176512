<div class="card" (click)="open()">
	<div class="card-img-top">
		<img [src]="event.imageMini || '/assets/default-event.jpg'" />
		<div class="date-range flex-column">
			<div class="flex-auto date-wrapper vertical-center">
				<h4>
					{{ event.dateRange | dateRange: 'showYear' }}
				</h4>
			</div>
		</div>
	</div>
	<div class="card-body flex-column">
		<div class="flex-none row row-no-margin">
			<div class="flex-auto">
				<h3 class="event-master-blue-color text-left">{{ event.name }}</h3>
			</div>
		</div>
		<div class="flex-auto row row-no-margin conditional-content flex-column-mobile">
			<div class="flex-auto flex-column justify-content-end">
				<h6 class="event-master-blue-color text-left" *ngIf="event.location">
					<fa-icon [icon]="['fas', 'map-marker']" class="event-master-gold-color"></fa-icon>
					{{ event.location }}
				</h6>
			</div>
			<div class="flex-none flex-column justify-content-end" *ngIf="roles && roles.length">
				<h5 class="bold gold-hover event-master-blue-color text-right"><fa-icon [icon]="['fas', 'bell']" class="mr-2 event-master-gold-color"></fa-icon>Now recruiting</h5>
			</div>
			<div class="flex-none flex-column justify-content-end" *ngIf="!roles || !roles.length">
				<h5 class="bold gold-hover text-right">
					<fa-icon [icon]="['fas', 'envelope']" class="mr-2 event-master-gold-color"></fa-icon>
					Register interest
				</h5>
			</div>
		</div>
		<div class="flex-none text-right desktop">
			<button class="btn btn-success mt-3" routerLink="../events/{{ event.id }}">Show Details</button>
		</div>
	</div>
</div>
