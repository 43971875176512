<div class="modal" id="{{ id }}" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog zoomIn faster animated" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title text-center w-100" id="{{ id }}-title">Review Reference Response</h3>
			</div>
			<div class="modal-body">
				<div class="row row-no-margin">
					<fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="text-muted mx-auto" *ngIf="isLoading"></fa-icon>
				</div>
				<div class="card mb-3" *ngFor="let response of responses">
					<div class="card-body">
						<h4 class="w-100 text-center mt-0 mb-2">Questionnaire</h4>
						<div class="row row-no-margin">
							<div class="mx-auto d-flex">
								<h6 class="mr-2">Date Submitted:</h6>
								<p>{{ response.date.toDate() | amDateFormat: 'DD/MM/YYYY' }}</p>
							</div>
						</div>
						<div class="row mb-2" *ngFor="let question of response.questions">
							<div class="col">
								<h6>{{ question.question }}:</h6>
								<p>{{ question.answer }}</p>
							</div>
						</div>
						<div class="row mb-2" *ngFor="let rating of response.ratings">
							<div class="col">
								<h6>{{ rating.type }}:</h6>
								<p>{{ rating.value }}</p>
							</div>
						</div>
					</div>
					<p *ngIf="!isLoading && (!responses || !responses.length)" class="text-muted text-center">
						None yet
					</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" (click)="close()">
						Close
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
