import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
// @ts-ignore
import Modernizr from './device-support';

@Injectable()
export class DeviceService {
  constructor(private deviceDetectorService: DeviceDetectorService) {

  }

  isRunningOnMobile() {
    return this.deviceDetectorService.isMobile() || this.isMobileSize();
  }

  isRunningOnDevice() {
    return this.deviceDetectorService.isTablet() || this.deviceDetectorService.isMobile();
  }

  isMobileSize() {
    return this.getScreenWidth() <= 768;
  }

  isMobilePortrait() {
    return this.getScreenWidth() <= 450 && (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet());
  }

  isDeviceSize() {
    return this.getScreenWidth() <= 992 || navigator.userAgent.match(/iPad/i);
  }

  isDateSupported() {
    return Modernizr.inputtypes['date'];
  }

  getScreenWidth() {
    return document.documentElement.clientWidth;
  }
}
