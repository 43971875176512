export class ObjectCache<T> {
  static ONE_MINUTE = 1000 * 60;
  static FIVE_MINUTES = ObjectCache.ONE_MINUTE * 5;
  static ONE_HOUR = ObjectCache.ONE_MINUTE * 60;
  static ONE_DAY = ObjectCache.ONE_HOUR * 24;

  private cache: {
    [key: number]: {
      timestamp: number;
      promise: Promise<T>;
    };
  } = {};

  private expiresAfter: number;

  constructor(expiresAfter: number) {
    this.expiresAfter = expiresAfter;
  }

  getOrUpdate(key: string, userPromise: () => Promise<T>): Promise<T> {
    this.checkIfExpired(key);
    if (!this.cache[key]) {
      this.update(key, userPromise);
    }
    return this.cache[key].promise;
  }

  update(key: string, userPromise: () => Promise<T>) {
    this.cache[key] = {
      timestamp: new Date().getTime(),
      promise: userPromise()
        .then(
          result => result,
          error => {
            this.cache[key] = null;
            return Promise.reject(error);
          },
        )
        .catch(error => {
          this.cache[key] = null;
          return Promise.reject(error);
        }),
    };
  }

  reset() {
    this.cache = {};
  }

  resetId(key: string) {
    this.cache[key] = null;
  }

  private checkIfExpired(key: string) {
    if (this.cache[key] && this.cache[key].timestamp < new Date().getTime() - this.expiresAfter) {
      this.cache[key] = null;
    }
  }
}
