import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CompanyService } from '@app/core/services/company.service';


@Injectable({
  providedIn: 'root'
})
export class WaitForCompanyLoadGuard implements CanActivate {
  constructor(private companyService: CompanyService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.companyService.onLoaded().then(() => {
      return true;
    });
  }
}
