import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';
import { EventLite } from './event.service';
import { Timestamp } from '@app/core/models/firebase.model';
import { CompanyService } from './company.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Role } from '../models/role.model';
import { AnalyticsService } from '../helpers/analytics.service';
import { UserLite, User } from '../models/user.model';
import { AuthService } from './auth.service';

export class ApplicationHistory {
  status: ApplicationStatus;
  notes: string;
  date: Timestamp;
}

export enum ApplicationStatus {
  SUBMITTED = 'SUBMITTED',
  REVIEWING = 'REVIEWING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
}

export enum ApplicationSource {
  INTERNET = 'INTERNET',
  COMPANY_FACEBOOK = 'COMPANY_FACEBOOK',
  COMPANY_INSTAGRAM = 'COMPANY_INSTAGRAM',
  COMPANY_EMAIL = 'COMPANY_EMAIL',
  COMPANY_WEBSITE = 'COMPANY_WEBSITE',
  EVENT_MASTER_SOCIAL = 'EVENT_MASTER_SOCIAL',
  EVENT_MASTER_JOB_SITE = 'EVENT_MASTER_JOB_SITE',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  OTHER = 'OTHER',
}

export class Application {
  id?: string;
  userId: string;
  dateSubmitted: Timestamp;
  coverLetter: string;
  eventId: string;
  roleId: string;
  status: ApplicationStatus;
  history: ApplicationHistory[];
  priorityChoice: number;
  source: ApplicationSource;

  static toJSON(application: Application) {
    return {
      userId: application.userId,
      dateSubmitted: application.dateSubmitted.toMillis(),
      coverLetter: application.coverLetter,
      eventId: application.eventId,
      roleId: application.roleId,
      status: application.status,
      priorityChoice: application.priorityChoice ? Number.parseInt(application.priorityChoice + '', undefined) : null,
      source: application.source || null,
    };
  }

  static create(userId: string, eventId: string, roleId: string, coverLetter: string, dateSubmitted: Date, priorityChoice: number, source: ApplicationSource) {
    const application = new Application();
    application.userId = userId;
    application.dateSubmitted = Timestamp.fromDate(dateSubmitted);
    application.eventId = eventId;
    application.roleId = roleId;
    application.coverLetter = coverLetter;
    application.status = ApplicationStatus.SUBMITTED;
    application.priorityChoice = priorityChoice;
    application.source = source;
    return application;
  }

  static clone(application: Application) {
    return cloneDeep(application);
  }
}

@Injectable()
export class ApplicationService {
  lastSavedApplicationId: string;

  onApplicationCreate: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private fns: AngularFireFunctions,
    private companyService: CompanyService,
    private firestore: AngularFirestore,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
  ) {}

  createApplication(application: Application): Promise<string> {
    return this.authService
      .getLoggedInUserForceRefresh()
      .then(() => this.authService.getAuthToken(true))
      .then(token => {
        const callable = this.fns.httpsCallable('application-createApplication', { timeout: 30000 });
        return callable({ idToken: token, application: Application.toJSON(application), companyId: this.companyService.getId() })
          .toPromise()
          .then(result => (this.lastSavedApplicationId = result.applicationId))
          .then(() => {
            this.analyticsService.reportApplicationCreate();
          })
          .then(() => this.onApplicationCreate.emit())
          .then(() => this.lastSavedApplicationId);
      });
  }

  getApplicationsForUser(userId: string, status: ApplicationStatus[] = null): Promise<Application[]> {
    console.info('getting applications for userIs: ' + userId);
    const result = this.firestore.collection(this.getUrl('applications'), ref => {
      const query = ref.where('userId', '==', userId);
      return status !== null ? query.where('status', 'in', status) : query;
    });
    return result
      .get()
      .toPromise()
      .then(actions => actions.docs.map(this.parseApplication.bind(this)))
      .then((applications: Application[]) => sortBy(applications, a => -a.dateSubmitted.toMillis()));
  }

  getApplication(id: string): Promise<Application> {
    console.info('getting application: ' + id);
    const result = this.firestore.doc(this.getUrl(`applications/${id}`));
    return result
      .get()
      .toPromise()
      .then(this.parseApplication.bind(this));
  }

  getAcceptedApplicationsForUser(userId: string) {
    return this.getApplicationsForUser(userId, [ApplicationStatus.ACCEPTED]);
  }

  getApplicationsForEvent(eventId: string, status?: ApplicationStatus[]): Promise<Application[]> {
    console.info('getting applications for eventId' + eventId);
    const result = this.firestore.collection(this.getUrl('applications'), ref => {
      const query = ref.where('eventId', '==', eventId);
      return status ? query.where('status', 'in', status) : query;
    });
    return result
      .get()
      .toPromise()
      .then(actions => actions.docs.map(this.parseApplication.bind(this)));
  }

  getApplicationsForRole(role: Role, status?: ApplicationStatus[]): Promise<Application[]> {
    console.info('getting applications for roleId' + role.id);
    const result = this.firestore.collection(this.getUrl('applications'), ref => {
      const query = ref.where('roleId', '==', role.id);
      return status ? query.where('status', 'in', status) : query;
    });
    return result
      .get()
      .toPromise()
      .then(actions => actions.docs.map(this.parseApplication.bind(this)));
  }

  getActiveApplicationsForUserAndEvent(user: UserLite, event: EventLite): Promise<Application[]> {
    console.info('getting applications for userId' + user.id + ' eventId' + event.id);
    const result = this.firestore.collection(this.getUrl('applications'), ref =>
      ref
        .where('userId', '==', user.id)
        .where('eventId', '==', event.id)
        .where('status', 'in', [ApplicationStatus.ACCEPTED, ApplicationStatus.SUBMITTED, ApplicationStatus.REVIEWING, ApplicationStatus.REJECTED]),
    );
    return result
      .get()
      .toPromise()
      .then(actions => actions.docs.map(this.parseApplication.bind(this)));
  }

  getAllApplicationsForUserAndEvent(user: UserLite, event: EventLite): Promise<Application[]> {
    console.info('getting applications for userId' + user.id + ' eventId' + event.id);
    const result = this.firestore.collection(this.getUrl('applications'), ref => ref.where('userId', '==', user.id).where('eventId', '==', event.id));
    return result
      .get()
      .toPromise()
      .then(actions => actions.docs.map(this.parseApplication.bind(this)));
  }
  getActiveApplicationsForUserAndRole(user: User, roleId: string): Promise<Application[]> {
    console.info('getting applications for userId' + user.id + ' roleId' + roleId);
    const result = this.firestore.collection(this.getUrl('applications'), ref =>
      ref
        .where('userId', '==', user.id)
        .where('roleId', '==', roleId)
        .where('status', 'in', [ApplicationStatus.ACCEPTED, ApplicationStatus.SUBMITTED, ApplicationStatus.REVIEWING, ApplicationStatus.REJECTED]),
    );
    return result
      .get()
      .toPromise()
      .then(actions => actions.docs.map(this.parseApplication.bind(this)));
  }

  update(application: Application, changeNotes: string): Promise<Application> {
    return this.authService
      .getAuthToken()
      .then(token => {
        const callable = this.fns.httpsCallable('application-updateApplication', { timeout: 30000 });
        return callable({
          idToken: token,
          applicationId: application.id,
          application: Application.toJSON(application),
          companyId: this.companyService.getId(),
          changeNotes,
        }).toPromise();
      })
      .then(() => this.getApplication(application.id));
  }

  clearOutWithdrawnApplications(roleId: string) {
    return this.authService.getAuthToken().then(idToken => {
      const callable = this.fns.httpsCallable('application-clearOutWithdrawnApplicationsForRole', { timeout: 30000 });
      return callable({ idToken, roleId, companyId: this.companyService.getId() }).toPromise();
    });
  }

  private getUrl(uri: string): string {
    return `companys/${this.companyService.getId()}/${uri}`;
  }

  private parseApplication(doc: any): Application {
    console.log('Parsing retrieved application');
    const application = Object.assign(new Application(), doc.data());
    application.id = doc.id;
    return application;
  }
}
