<div class="page">
	<div *ngIf="!isLoading">
		<div class="header vertical-center">
			<img class="banner" [src]="company.banner" />
			<div class="container company-summary-wrapper">
				<div class="company-summary">
					<div class="row">
						<div class="col-lg-2 vertical-center">
							<img [src]="company.logo" />
						</div>
						<div class="col-lg-10">
							<div class="d-flex flex-column-sm">
								<div class="flex-auto vertical-center">
									<h5 class="mb-0 mt-3 text-muted">Work for:</h5>
									<h2 class="my-0">{{ company.name }}</h2>
									<h5 *ngIf="countOfEventsHiring > 0" class="mt-2 mb-3 event-master-teal-color">
										{{ countOfEventsHiring }}
										event
										{{ 'role' | plural: countOfEventsHiring }} currently open for applications
									</h5>
								</div>
								<div class="flex-none vertical-center contact-info">
									<h5 class="mb-2 desktop text-muted">Contact:</h5>
									<h6 *ngIf="company.email">
										<fa-icon [icon]="['fas', 'envelope']"></fa-icon>
										<a href="mailto:{{ company.email }}" target="_blank">{{ company.email }}</a>
									</h6>
									<h6 *ngIf="company.telephone"><fa-icon [icon]="['fas', 'phone-alt']"></fa-icon>{{ company.telephone }}</h6>
									<h6 *ngIf="company.website">
										<fa-icon [icon]="['fas', 'mouse']"></fa-icon>
										<a href="{{ company.website }}" target="_blank">{{ company.website | stripHttp }}</a>
									</h6>
									<h6 *ngIf="company.instagram">
										<fa-icon [icon]="['fab', 'instagram']"></fa-icon>
										<a href="{{ company.instagram }}" target="_blank">{{ company.instagram | stripHttp }}</a>
									</h6>
									<h6 *ngIf="company.facebook">
										<fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
										<a href="{{ company.facebook }}" target="_blank">{{ company.facebook | stripHttp }}</a>
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="row row-no-margin mt-2">
						<div class="flex-auto">
							<p class="description">{{ company.description }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<h2 class="text-center">Upcoming Event Work</h2>
			<div class="row events" *ngIf="!isLoadingEvents">
				<div class="col-sm-12 col-md-6 mb-3 event" *ngFor="let event of events; let i = index">
					<div pDefer (onLoad)="loadNextEvent(i)">
						<ng-template class="event-card">
							<event-card class="col-no-padding-mobile text-center-mobile" [event]="event" *ngIf="event.shown"> </event-card>
						</ng-template>
					</div>
				</div>
			</div>
			<app-loader *ngIf="isLoadingEvents"></app-loader>
			<div class="row row-no-margin" *ngIf="!isLoadingEvents && !events?.length">
				<div class="image-background vertical-center">
					<img src="/assets/branding/dancers.svg" class="dancers" />
					<div>
						<h3 class="text-center mb-3 bold">No upcoming events listed at the moment..</h3>
						<h6 class="text-center">
							Check back here later to apply for work at <br class="mobile" /><span class="bold">{{ company?.name }}</span
							>.
						</h6>
					</div>
				</div>
			</div>
			<div class="container">
				<div pDefer (onLoad)="loadPastEvent($event)">
					<ng-template class="past-events">
						<h2 class="text-center">{{ company.name | possessive }} Past Events</h2>
						<div class="row events" *ngIf="!isLoadingPastEvents">
							<div class="col-sm-12 col-md-4 mb-3 event" *ngFor="let event of pastEvents">
								<event-card-mini class="col-no-padding-mobile text-center-mobile" [event]="event"> </event-card-mini>
							</div>
							<div class="text-center" *ngIf="!pastEvents?.length">
								<p class="text-muted">None yet</p>
							</div>
						</div>
						<app-loader *ngIf="isLoadingPastEvents"></app-loader>
					</ng-template>
				</div>
			</div>
			<app-loader *ngIf="isLoading"></app-loader>
		</div>
	</div>
</div>
