import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CompanyService } from './company.service';
import { Team, Position, Timesheet } from '../models/teams.model';
import { AngularFireFunctions } from '@angular/fire/functions';

import * as fs from 'file-saver';
import { Event } from './event.service';
import { AuthService } from './auth.service';
declare const Buffer: any;

@Injectable()
export class TeamService {
  constructor(
    private fns: AngularFireFunctions,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private companyService: CompanyService,
  ) {}

  saveOrUpdateTeamForEvent(team: Team, eventId: string): Promise<Team> {
    const obj = Team.toJSON(team);
    console.log(obj);
    const uri = this.url(eventId, 'teams');
    let promise;
    if (team.id) {
      console.log('Updating team: ' + team.id + ' for event: ' + eventId);
      promise = this.firestore
        .doc(uri + '/' + team.id)
        .set(obj)
        .then(() => team);
    } else {
      console.log('Creating team for event: ' + eventId);
      promise = this.firestore
        .collection(uri)
        .add(obj)
        .then(response => response.get())
        .then(Team.parse);
    }
    return promise.then(
      team => new Promise<Team>(resolve => setTimeout(() => resolve(team), 5000)),
    ); // to give the role metadata a chance to catch up
  }

  getTeamsForEvent(eventId: string) {
    return this.firestore
      .collection(this.url(eventId, 'teams'))
      .get()
      .toPromise()
      .then(result => result.docs.map(Team.parse));
  }

  getTeamById(eventId: string, teamId: string) {
    return this.firestore
      .doc(this.url(eventId, 'teams/' + teamId))
      .get()
      .toPromise()
      .then(result => Team.parse(result));
  }

  saveOrUpdatPositionForEvent(position: Position, eventId: string): Promise<Position> {
    const obj = Position.toJSON(position);
    const uri = this.url(eventId, 'positions');
    let promise;
    if (position.id) {
      console.log('Updating position: ' + position.id + ' for event: ' + eventId);
      promise = this.firestore
        .doc(uri + '/' + position.id)
        .set(obj)
        .then(() => position);
    } else {
      console.log('Creating position for event: ' + eventId);
      promise = this.firestore
        .collection(uri)
        .add(obj)
        .then(response => response.get())
        .then(Position.parse);
    }
    return promise;
  }

  getPositionsForEvent(eventId: string): Promise<Position[]> {
    return this.firestore
      .collection(this.url(eventId, 'positions'))
      .get()
      .toPromise()
      .then(result => result.docs.map(Position.parse));
  }

  getTimesheet(event: Event, team: Team, shiftId: string): Promise<Timesheet> {
    return this.firestore
      .doc(this.url(event.id, `teams/${team.id}/timesheets/${shiftId}`))
      .get()
      .toPromise()
      .then(Timesheet.parse);
  }

  async saveTimesheet(event: Event, teamId: string, id: string, timesheet: Timesheet): Promise<void> {
    if (!id) {
      throw new Error('tried to save timesheet with undefined id');
    }
    console.log('trying to save timesheet', timesheet);
    return this.firestore.doc(this.url(event.id, `teams/${teamId}/timesheets/${id}`)).set(timesheet);
  }

  async updateTimesheet(event: Event, teamId: string, id: string, timesheet: Timesheet): Promise<void> {
    if (!id) {
      throw new Error('tried to update timesheet with undefined id');
    }
    if (!Object.keys(timesheet.workingTimes || {}).length) {
      delete timesheet.workingTimes;
    }
    if (!Object.keys(timesheet.additional || {}).length) {
      delete timesheet.additional;
    }
    if (!Object.keys(timesheet).length) {
      console.log('nothing to save');
      return Promise.resolve();
    }
    console.log('trying to update timesheet', timesheet);

    return this.firestore.doc(this.url(event.id, `teams/${teamId}/timesheets/${id}`)).set(timesheet, { merge: true });
  }

  exportTeamsRotaToExcel(eventId: string, event: Event) {
    return this.authService
      .getAuthToken()
      .then(idToken => {
        const callable = this.fns.httpsCallable('export-exportTeamsToExcel', { timeout: 30000 });
        return callable({ idToken, companyId: this.companyService.getId(), eventId }).toPromise();
      })
      .then(result => {
        const buffer = this.parseBuffer(result.buffer);
        let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, event.name + ' Rota.xlsx');
      });
  }

  exportTimesheetToExcel(eventId: string, event: Event) {
    return this.authService
      .getAuthToken()
      .then(idToken => {
        const callable = this.fns.httpsCallable('export-exportTimesheetToExcel', { timeout: 30000 });
        return callable({ idToken, companyId: this.companyService.getId(), eventId }).toPromise();
      })
      .then(result => {
        const buffer = this.parseBuffer(result.buffer);
        let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, event.name + ' Timesheet.xlsx');
      });
  }

  private parseBuffer(bufferStr: string) {
    return Buffer.from(JSON.parse(bufferStr).data);
  }
  private url(eventId: string, suffix: string) {
    return `companys/${this.companyService.getId()}/events/${eventId}/${suffix}`;
  }
}
