<div class="bank-info w-100">
	<div class="d-flex" *ngIf="user">
		<current-user-avatar class="flex-none" size="4rem"></current-user-avatar>
		<div class="vertical-center flex-auto ml-2 text-parent">
			<h6 class="bold">{{ user.getName() }}</h6>
			<h6 ellipsis>{{ user.email }}</h6>
			<ng-content></ng-content>
		</div>
	</div>
	<div class="top-right" *ngIf="showDelete">
		<div class="d-flex ml-auto">
			<fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="mr-2 text-muted" *ngIf="isLoading"></fa-icon>
			<fa-icon [icon]="['fas', 'times']" class="clickable text-danger" (click)="onDelete.emit()"> </fa-icon>
		</div>
	</div>
</div>
