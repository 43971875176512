import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'plural' })
export class PluralPipe implements PipeTransform {
  transform(value: string, count: number) {
    if (value == null || !value.length) {
      return null;
    }
    return this.addPlural(value.trim(), count);
  }

  private addPlural(text: string, count: number) {
    if (count > 1) {
      return text + 's';
    } else {
      return text;
    }
  }
}
