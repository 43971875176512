import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import find from 'lodash-es/find';

import { Timestamp } from '@app/core/models/firebase.model';

export class RoleInfo {
  arrival?: Timestamp;
  food?: boolean;
  depositAmount?: number;
  payrollRequiredUpFront?: boolean;

  static toJSON(info: RoleInfo) {
    return {
      arrival: info.arrival || null,
      food: info.food || false,
      depositAmount: info.depositAmount || null,
      payrollRequiredUpFront: info.payrollRequiredUpFront || false,
    };
  }
}

export class RoleMetadata {
  applicationsStates?: string[][];
  shiftPatternsForTeams?: {
    teamId: string;
    patterns: {
      positionId: string;
      shiftIds: string[];
    }[];
  }[];

  constructor(json: any) {
    this.applicationsStates = [];
    Object.keys(json.applicationsStates || {}).forEach(state => {
      this.applicationsStates[state] = Object.keys(json.applicationsStates[state] || {}).filter(id => json.applicationsStates[state][id]);
    });
    this.shiftPatternsForTeams = [];
    Object.keys(json.shiftPatterns || {}).forEach(teamId => {
      this.shiftPatternsForTeams.push({
        teamId,
        patterns: json.shiftPatterns[teamId],
      });
    });
  }
}

export class RoleLite {
  id?: string;
  name: string;
  open: boolean;
  active: boolean;

  shiftsManual?: string;
  rateManual?: string;
  calculated: {
    rate: number[];
    shifts: number[];
  };
  constructor() {
    this.open = false;
    this.active = false;
    this.calculated = {
      rate: [],
      shifts: [],
    };
  }
}

export class Role extends RoleLite {
  id?: string;
  description: string;
  info: RoleInfo;
  metadata?: RoleMetadata;

  constructor() {
    super();
    this.info = {};
    this.description = '';
  }

  static toJSON(role: Role) {
    return {
      name: role.name,
      description: role.description,
      open: role.open,
      info: RoleInfo.toJSON(role.info || {}),
      active: role.active || false,
      rateManual: role.rateManual || null,
      shiftsManual: role.shiftsManual || null,
    };
  }

  isEqual(role: Role) {
    return isEqual(role, this);
  }

  static clone(role: Role) {
    return cloneDeep(role);
  }

  static isListEqual(roles: Role[], roles2: Role[]) {
    if (!roles && !roles2) {
      return true;
    } else if (!roles || !roles2) {
      return false;
    } else {
      return roles.length === roles2.length && roles.filter(role => find(roles2, r2 => r2.isEqual(role))).length === roles2.length;
    }
  }
}

export function getPendingApplications(metadata: RoleMetadata) {
  if (metadata?.applicationsStates) {
    const reviewing = metadata.applicationsStates['REVIEWING'] || [];
    const submitted = metadata.applicationsStates['SUBMITTED'] || [];
    return [...reviewing, ...submitted];
  } else {
    return [];
  }
}

export function getAcceptedApplications(metadata: RoleMetadata) {
  if (metadata?.applicationsStates) {
    const accepted = metadata.applicationsStates['ACCEPTED'] || [];
    return accepted;
  } else {
    return [];
  }
}

export function getTotalRequiredApplicationsCount(metadata: RoleMetadata) {
  if (metadata?.shiftPatternsForTeams) {
    const total = metadata.shiftPatternsForTeams.reduce((sum, next) => next.patterns.length + sum, 0);
    return total;
  } else {
    return 0;
  }
}

export class RoleLabel {
  id?: string;
  name: string;
  targetCount: number;
  applications: any;

  getActualCount(acceptedApplications: string[]) {
    return (acceptedApplications || []).reduce((sum, next) => {
      if (this.applications[next]) {
        return sum + 1;
      } else {
        return sum;
      }
    }, 0);
  }

  getCompletePercentage(acceptedApplications: string[]) {
    if (this.targetCount <= 0) {
      return 100;
    }
    const actual = this.getActualCount(acceptedApplications);
    const value = (100 / this.targetCount) * actual;
    if (value % 1) {
      return value.toFixed(1);
    } else {
      return value.toFixed(0);
    }
  }
  static toJSON(label: RoleLabel) {
    return {
      name: label.name || '',
      targetCount: label.targetCount || 0,
      applications: label.applications || {},
    };
  }
}
