<div class="card">
	<div class="card-body">
		<h3 class="bold text-center">Register your Interest</h3>
		<h6>
			<span class="bold">{{ company.name }}</span> can notify you when {{ isRolesAvailable ? 'more roles become available' : 'applications open for
      this event' }}.
		</h6>
		<user-basic-permissions-card *ngIf="currentUser && isRegisteredForNotifications" (onDelete)="onInterestInNotificationsRemove()" [isLoading]="isInterestRegisteredLoading">
			<p class="small"><fa-icon [icon]="['fas', 'bell']" class="mr-2 event-master-gold-color"> </fa-icon>Notifying</p>
		</user-basic-permissions-card>
		<div *ngIf="!isLoggedIn; then login_template; else undefined"></div>

		<button (click)="openRegisterNotificationsDialog()" class="btn btn-warning mx-auto d-block mt-3" *ngIf="!isRegisteredForNotifications && !isInterestRegisteredLoading && isLoggedIn"><fa-icon [icon]="['fas', 'bell']" class="mr-2"> </fa-icon>Notify me</button>
		<fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="text-muted text-center my-2" *ngIf="isLoggedIn && isInterestRegisteredLoading"></fa-icon>
	</div>
</div>
<register-for-notifications-dialog (onRegister)="isRegisteredForNotifications = true"> </register-for-notifications-dialog>
<confirm-dialog></confirm-dialog>

<ng-template #login_template>
	<div class="login-wrapper vertical-center">
		<img class="login" src="/assets/branding/logo-powered-by.svg" />
		<div class="d-flex justify-content-center">
			<button class="btn btn-success mr-2" (click)="register()"><fa-icon [icon]="['fas', 'user-plus']" class="mr-2"></fa-icon>Create profile</button>
			<button class="btn btn-warning ml-2" (click)="login()"><fa-icon [icon]="['fas', 'sign-in-alt']" class="mr-2"></fa-icon>Login</button>
		</div>
	</div>
</ng-template>
