import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';
import { JobsRoutingModule } from './jobs-routing.module';
import { HomeComponent } from './home/home.component';
import { EventPageComponent } from './event/event-page.component';
import { ApplyPageComponent } from './apply/apply-page.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './privacy/terms.component';
import { RegisterForNotificationsComponent } from './event/register-interest/register-for-notifications.dialog.component';
import { DuplicateApplicationGuard } from './duplicate-application-guard';
import { RoleIsOpenGuard } from './role-is-open-guard';
import { ApplyForRoleButtonComponent } from './event/apply-for-role-button/apply-for-role-button.component';
import { EventCardComponent } from './event/card/event-card.component';
import { EventCardMiniComponent } from './event/card/event-card-mini.component';
import { RegisterInterestCardComponent } from './event/register-interest/register-interest-card.component';
import { UserBasicPermissionsCardComponent } from './event/register-interest/user-basic-permissions-card.component';
import { SkeletonModule } from 'primeng/skeleton';
@NgModule({
  imports: [CommonModule, SharedModule, JobsRoutingModule, SkeletonModule],
  declarations: [
    HomeComponent,
    RegisterForNotificationsComponent,
    RegisterInterestCardComponent,
    UserBasicPermissionsCardComponent,
    TermsComponent,
    EventPageComponent,
    ApplyPageComponent,
    PrivacyComponent,
    ApplyForRoleButtonComponent,
    EventCardComponent,
    EventCardMiniComponent,
  ],
  providers: [DuplicateApplicationGuard, RoleIsOpenGuard],
})
export class JobsModule {}
