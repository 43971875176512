import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LinkService } from '../link.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticationGuard implements CanActivate {
  constructor(private linkService: LinkService, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.authService.getLoggedInUser().then(user => {
      if (user) {
        console.debug('Already authenticated, redirecting to profile...');
        this.linkService.navigate(['profile']);
        return false;
      } else {
        return true;
      }
    });
  }
}
