declare const loadImage: any;

import Compressor from 'compressorjs';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable()
export class ImageHelperService {
  constructor(private errorHandlerService: ErrorHandlerService) {}

  sanitizeImage(file: File, isImageCompressionSkippable: boolean, maxSize: number = 1000): Promise<File> {
    return this.compressImage(file, isImageCompressionSkippable)
      .then(compressed => this.resolveOrientationAndScaleDown(compressed, maxSize))
      .catch(this.errorHandlerService.reportError('ImageHelperService.sanitizeImage()', undefined, JSON.stringify(file)));
  }

  sanitizeBase64(base64: string, isImageCompressionSkippable: boolean, maxSize: number = 1000): Promise<string> {
    return this.compressImage(ImageHelperService.b64toFile(base64, 'image/jpeg', 'to-sanatize.jpeg'), isImageCompressionSkippable)
      .then(compressed => this.resolveOrientationAndScaleDown(compressed, maxSize))
      .then(image => ImageHelperService.fileToBase64(image))
      .catch(this.errorHandlerService.reportError('ImageHelperService.sanitizeBase64()', undefined, base64 ? base64.substr(0, 1000) : null));
  }

  private resolveOrientationAndScaleDown(file: File, maxSize: number): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      try {
        loadImage(
          file,
          (canvas: HTMLCanvasElement) => {
            console.info('image orientated');
            canvas.toBlob(blob => resolve(ImageHelperService.blobToFile(blob, file.name)), file.type, 1);
          },
          { maxWidth: maxSize, maxHeight: maxSize, canvas: true, orientation: true },
        );
      } catch (e) {
        this.errorHandlerService.reportError('ImageHelperService.resolveOrientationAndScaleDown()', undefined, JSON.stringify(file))(e);
        reject(e);
      }
    });
  }

  private compressImage(file: File, isImageCompressionSkippable: boolean): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      try {
        const compressor = new Compressor(file, {
          quality: 0.9,
          mimeType: 'image/jpeg',
          success: result => {
            console.info('image compressed');
            resolve(ImageHelperService.blobToFile(result, file.name));
          },
          error: e => {
            this.errorHandlerService.reportError('ImageHelperService.compressImage()' + (isImageCompressionSkippable ? ' but using original image anyway' : ''), undefined, JSON.stringify(file))(e);
            if (isImageCompressionSkippable) {
              console.info('compression failed, but carrying on anyway');
              resolve(file);
            } else {
              reject(e);
            }
          },
        });
      } catch (e) {
        this.errorHandlerService.reportError('ImageHelperService.compressImage().try' + (isImageCompressionSkippable ? ' but using original image anyway' : ''), undefined, JSON.stringify(file))(e);
        if (isImageCompressionSkippable) {
          console.info('compression failed, but carrying on anyway');
          resolve(file);
        } else {
          reject(e);
        }
      }
    });
  }

  static blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    if (!b.lastModifiedDate) {
      b.lastModifiedDate = new Date();
    }
    if (!b.name) {
      b.name = fileName;
    }

    // Cast to a File() type
    return theBlob as File;
  }

  static fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  /*
   * Not tested successfully, it might corrupt the file
   */
  static b64toFile(b64Data: string, contentType: string, filename: string): File {
    contentType = contentType || '';
    const sliceSize = 512;

    const base64String = b64Data.split(';base64,')[1];
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return this.blobToFile(blob, filename);
  }
}
