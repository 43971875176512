<div class="footer">
  <div class="container d-flex h-100">
    <div class="flex-none vertical-center">
      <a *ngIf="!isPrivate" href="https://eventmaster.jobs" target="_blank">
        <img class="logo" src="/assets/branding/logo-powered-by.svg" />
      </a>
      <div *ngIf="isPrivate">
        <img class="logo" src="/assets/branding/logo-powered-by.svg" />
      </div>
    </div>
    <div class="flex-auto vertical-center ml-3">
      <div class="d-flex flex-column-mobile">
        <div class="flex-auto flex-column" *ngIf="!isPrivate">
          <p class="desktop ml-2 mb-2">Follow event master</p>
          <div class="d-flex social-wrapper">
            <a href="https://instagram.com/eventmaster.jobs" target="_blank">
              <fa-icon [icon]="['fab', 'instagram']" size="2x" class="event-master-gold-color mr-2"> </fa-icon>
            </a>
            <a href="https://facebook.com/eventmaster.jobs" target="_blank">
              <fa-icon [icon]="['fab', 'facebook-f']" size="2x" class="event-master-teal-color mr-2"> </fa-icon>
            </a>
            <a href="https://www.linkedin.com/company/event-master-jobs" target="_blank">
              <fa-icon [icon]="['fab', 'linkedin']" size="2x" class="event-master-blue-color mr-2"> </fa-icon>
            </a>
          </div>
        </div>
        <div class="flex-auto flex-column" *ngIf="isPrivate"></div>
        <div class="flex-none vertical-center policy-wrapper">
          <a href="/terms-of-service" target="_blank">Terms of Service</a>
          <a href="/privacy" target="_blank">Privacy Policy</a>
          <p>© Copyright event master</p>
          <p class="text-muted">v{{ version }}</p>
        </div>
      </div>
    </div>
    <div class="flex-none vertical-center ml-3 desktop">
      <a *ngIf="!isPrivate" href="https://eventmaster.jobs" target="_blank">
        <img class="logo" src="/assets/branding/icon-mini.png" />
      </a>
      <div *ngIf="isPrivate">
        <img class="logo" src="/assets/branding/icon-mini.png" />
      </div>
    </div>
  </div>
</div>
