import { Component } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { Router } from '@angular/router';
import { LinkService } from '@app/core/link.service';
import { CurrentUserInfoComponent } from './current-user-info.component';
import { CompanyService } from '@app/core/services/company.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'current-user-contact',
  templateUrl: './current-user-contact.component.html',
  styleUrls: ['./current-user-info.component.scss'],
})
export class CurrentUserContactComponent extends CurrentUserInfoComponent {
  protected profileEditorSection: string = 'contact';

  constructor(protected authService: AuthService, protected linkService: LinkService, protected router: Router, protected companyService: CompanyService) {
    super(authService, linkService, router, companyService);
  }

  protected setupThisSection() {
    return Promise.resolve();
  }
  getAddressField(name: string) {
    if (!this.isLoading) {
      return this.user && this.user.address && this.user.address[name];
    } else {
      return undefined;
    }
  }

  getEmergencyContactField(name: string) {
    if (!this.isLoading) {
      return this.user && this.user.emergencyContact && this.user.emergencyContact[name];
    } else {
      return undefined;
    }
  }

  protected getFieldStates(): Promise<boolean[]> {
    const address = [
      this.user.address && !!this.user.address.line1,
      this.user.address && !!this.user.address.city,
      this.user.address && !!this.user.address.county,
      this.user.address && !!this.user.address.postCode,
      this.user.address && !!this.user.address.country,
    ];

    const emergencyContact = [
      this.user.emergencyContact && !!this.user.emergencyContact.name,
      this.user.emergencyContact && !!this.user.emergencyContact.relationship,
      this.user.emergencyContact && !!this.user.emergencyContact.telephone,
    ];

    return Promise.resolve([!!this.user.telephone, ...address, ...emergencyContact]);
  }
}
