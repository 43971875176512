import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService, Event } from '@app/core/services/event.service';
import { ApplicationService, Application } from '@app/core/services/application.service';
import { UserService } from '@app/core/services/user.service';
import { RoleService } from '@app/core/services/role.service';
import { CompanyService } from '@app/core/services/company.service';
import { Company } from '@app/core/models/company.model';
import { ErrorHandlerService } from '@app/core/error-handler.service';
import { CurrentUser, User } from '@app/core/models/user.model';
import { ConfirmDialogComponent } from '@app/shared/dialog/confirm-dialog.component';
import { DeviceService } from '@app/core/device/device.service';
import { ApplicationStatus } from '@app/core/services/application.service';
import * as moment from 'moment';
import { LinkService } from '@app/core/link.service';
import { Role } from '@app/core/models/role.model';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss'],
})
export class EventPageComponent implements OnDestroy {
  @ViewChild(ConfirmDialogComponent)
  confirmDialog: ConfirmDialogComponent;

  event: Event;
  isLoading = true;
  isLoggedIn = false;
  applications: Application[];
  isApplicationsLoading: boolean = false;
  roles: Role[];
  company: Company;
  isRegisteredForNotifications: boolean = false;
  isInterestRegisteredLoading: boolean = true;
  isAnyRoleOpen: boolean = false;
  isInFuture: boolean = false;
  isError: boolean = false;

  private currentUser: CurrentUser;

  private subs: Subscription;
  private eventId: string;

  constructor(
    private linkService: LinkService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private deviceService: DeviceService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private roleService: RoleService,
    private applicationService: ApplicationService,
    private eventService: EventService,
    private companyService: CompanyService,
  ) {
    this.subs = this.activatedRoute.params.subscribe(params => {
      this.isLoading = true;
      this.eventId = params['eventId'];
      this.init();
    });
  }

  async init() {
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.currentUser = await this.authService.getLoggedInUser();
    } else {
      this.isLoggedIn = false;
    }
    try {
      this.event = await this.eventService.getEvent(this.eventId);
      if (!this.event) {
        throw new Error('Event id: ' + this.eventId + ' is missing');
      }
      this.isInFuture = this.event?.dateRange?.end
        ? this.event.dateRange.end.toMillis() >
          moment()
            .toDate()
            .getTime()
        : false;
      this.company = await this.companyService.getCompany();
      this.roles = await this.roleService.getRolesForEvent(this.eventId, false);
      this.isAnyRoleOpen = !!this.roles.find(r => r.open && r.active);
      this.isLoading = false;
      this.isError = false;
    } catch (e) {
      this.errorHandlerService.reportError('EventPageComponent.init', e?.message !== 'Event id: ' + this.eventId + ' is missing' ? 'Error occurred while loading Event' : undefined)(e);
      this.isError = true;
      this.isLoading = true;
    }
    if (!this.isError && this.isLoggedIn) {
      this.refreshApplications();
    }
  }

  onEllipsisShowMore(text: string) {
    this.confirmDialog.openConfirm({
      title: this.event.name,
      message: '',
      messageHtml: '<p class="text-justify white-space-prewrap">' + text + '</p>',
    });
  }

  onApplicationChange(application: Application) {
    const found = this.applications.find(a => a.id === application.id);
    found.status = application.status;
    this.refreshActiveApplicationsForRoles();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  isMobile() {
    return this.deviceService.isMobileSize();
  }

  login() {
    this.linkService.navigate(['login'], { queryParams: { redirect: this.router.url } });
  }

  register() {
    this.linkService.navigate(['register'], { queryParams: { redirect: this.router.url } });
  }

  private refreshApplications() {
    this.isApplicationsLoading = true;
    this.applicationService
      .getActiveApplicationsForUserAndEvent(this.currentUser?.user, this.event)
      .then(applications => (this.applications = applications))
      .then(this.refreshActiveApplicationsForRoles.bind(this))
      .catch(this.errorHandlerService.reportErrorSkippable('EventPageComponent.refreshApplications'))
      .finally(() => (this.isApplicationsLoading = false));
  }

  private refreshActiveApplicationsForRoles() {
    (this.roles || []).forEach(role => {
      role['activeApplication'] = this.getActiveApplicationForRole(role);
    });
  }

  private getActiveApplicationForRole(role: Role): Application {
    return this.applications && this.applications.length && this.applications.find(a => a.roleId === role.id && a.status !== ApplicationStatus.WITHDRAWN);
  }
}
