import { Component, Input } from '@angular/core';
import { ExperienceType } from '@app/core/models/user.model';

@Component({
  selector: 'experience-label',
  templateUrl: './experience-label.component.html',
  styleUrls: ['./experience-label.component.scss'],
})
export class ExperienceLabelComponent {
  @Input()
  large: boolean = false;

  @Input()
  showIcon: boolean = true;

  @Input()
  reverse: boolean = false;

  @Input()
  showLabel: boolean = true;

  @Input()
  gold: boolean = false;

  @Input()
  set type(type: ExperienceType) {
    this._type = type;
    this.name = ExperienceLabelComponent.getName(type);
    this.icon = ExperienceLabelComponent.getIcon(type);
  }
  get type() {
    return this._type;
  }

  @Input()
  notes: string;

  name: string;
  icon: string;
  private _type: ExperienceType;

  static getIcon(type: ExperienceType) {
    if (!type) {
      return '';
    }
    switch (type) {
      case ExperienceType.FESTIVAL:
        return 'beer';
      case ExperienceType.COCKTAIL:
        return 'cocktail';
      case ExperienceType.MANAGER:
        return 'user-tie';
      case ExperienceType.SUPERVISOR:
        return 'users';
      case ExperienceType.FIRST_AID:
        return 'medkit';
      case ExperienceType.PERSONAL_LICENCE:
        return 'address-card';
      default:
        return 'briefcase';
    }
  }

  static getName(type: ExperienceType) {
    if (!type) {
      return '';
    }
    switch (type) {
      case ExperienceType.FESTIVAL:
        return 'Festival/Event Bar Experience';
      case ExperienceType.COCKTAIL:
        return 'Cocktail Skills';
      case ExperienceType.MANAGER:
        return 'Bar Manager Experience';
      case ExperienceType.SUPERVISOR:
        return 'Supervisor Experience';
      case ExperienceType.FIRST_AID:
        return 'First Aid Trained';
      case ExperienceType.PERSONAL_LICENCE:
        return 'Personal Alcohol Sale Licence';
      default:
        return 'Other Relevent Experience';
    }
  }
}
