import { Timestamp } from './firebase.model';
import { toInitCap, trimOrNull } from './helpers.model';

export class BankAccount {
  holderName: string;
  bankName: string;
  accountNumber: string;
  sortCode: string;

  static toJSON(bankAccount: BankAccount) {
    return {
      holderName: trimOrNull(bankAccount.holderName),
      bankName: toInitCap(trimOrNull(bankAccount.bankName)),
      accountNumber: trimOrNull(bankAccount.accountNumber),
      sortCode: trimOrNull(bankAccount.sortCode),
    };
  }
}

export class Payroll {
  bankAccount: BankAccount;

  static toJSON(payroll: Payroll) {
    return {
      bankAccount: payroll.bankAccount ? BankAccount.toJSON(payroll.bankAccount) : null,
    };
  }
}

export class CompanyUserPayrollInfo {
  requestLastSent: Timestamp;
  companyId: string;

  static toJSON(payroll: CompanyUserPayrollInfo) {
    return {
      requestLastSent: payroll.requestLastSent || null,
    };
  }
}

export function parsePayroll(doc: any) {
  console.log('Parsing retrieved payroll');
  const payroll = Object.assign(new Payroll(), doc.data());
  payroll.id = doc.id;
  return payroll;
}

export function parseCompanyUserPayroll(doc: any): CompanyUserPayrollInfo {
  if (doc.exists) {
    const companyUserPayroll = Object.assign(new CompanyUserPayrollInfo(), doc.data());
    companyUserPayroll.companyId = doc.id;
    return companyUserPayroll;
  } else return null;
}
