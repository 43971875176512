import { Component } from '@angular/core';
import { CompanyService } from '@app/core/services/company.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  version: string | null = environment.version;
  isPrivate: boolean;
  constructor(private companyService: CompanyService) {
    companyService.getCompany().then(c => (this.isPrivate = c.isPrivate));
  }
}
