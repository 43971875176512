<div class="modal" id="{{ id }}" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog zoomIn faster animated" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" id="{{ id }}-title">Application Status History</h4>
			</div>
			<div class="modal-body">
				<application-history [history]="history"></application-history>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="close()">Close</button>
			</div>
		</div>
	</div>
</div>
