import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { DocumentType, Document } from '../models/user.model';
import { CompanyService } from '@app/core/services/company.service';
import { AuthService } from './auth.service';

@Injectable()
export class FileStorageService {
  constructor(private storage: AngularFireStorage, private companyService: CompanyService) {}

  uploadProfileImageBase64(base64: string, userId: string): Promise<string> {
    const filePath = this.getURI(DocumentType.PROFILE_IMAGE, userId, 'jpeg');
    return this.uploadBase64ToUrl(base64, filePath);
  }

  uploadBase64CompanyLogo(base64: string): Promise<string> {
    const filePath = `companys/${this.companyService.getId()}/logo.jpeg`;
    return this.uploadBase64ToUrl(base64, filePath);
  }

  uploadBase64CompanyBanner(base64: string): Promise<string> {
    const filePath = `companys/${this.companyService.getId()}/banner.jpeg`;
    return this.uploadBase64ToUrl(base64, filePath);
  }

  uploadBase64EventImage(base64: string, eventId: string, suffix: string = null): Promise<string> {
    const filePath = `companys/${this.companyService.getId()}/events/${eventId}${suffix ? `-${suffix}` : ''}.jpeg`;
    return this.uploadBase64ToUrl(base64, filePath);
  }

  uploadFile(file: File, userId: string, type: DocumentType): Promise<Document> {
    const filePath = this.getURI(
      type,
      userId,
      file.name
        .split('.')
        .pop()
        .toLowerCase(),
    );
    const ref = this.storage.ref(filePath);
    const document: Document = {
      type,
      uri: filePath,
    };

    return new Promise<void>((resolve, reject) => {
      ref.put(file).then(result => {
        result.state === 'success' ? resolve() : reject();
      }, reject);
    }).then(() => document);
  }

  deleteDocument(document: Document): Promise<void> {
    try {
      const ref = this.storage.ref(document.uri);
      return new Promise((resolve, _) => {
        ref
          .delete()
          .toPromise()
          .then(resolve, resolve)
          .catch(resolve);
      });
    } catch (e) {
      return Promise.resolve();
    }
  }

  getProfileImageForUser(userId: string): Promise<string> {
    const filePath = this.getURI(DocumentType.PROFILE_IMAGE, userId, 'jpeg');
    const ref = this.storage.ref(filePath);
    return new Promise<string>((resolve, reject) =>
      ref
        .getDownloadURL()
        .toPromise()
        .then(
          url => resolve(url),
          () => reject({ status: 404 }),
        )
        .catch(() => reject({ status: 404 })),
    );
  }

  getDocument(document: Document): Promise<string> {
    const ref = this.storage.ref(document.uri);
    return new Promise<string>((resolve, reject) =>
      ref
        .getDownloadURL()
        .toPromise()
        .then(
          url => resolve(url),
          () => reject({ status: 404 }),
        )
        .catch(() => reject({ status: 404 })),
    );
  }

  private uploadBase64ToUrl(base64: string, filePath: string): Promise<string> {
    const ref = this.storage.ref(filePath);

    const base64Stripped = base64.replace('data:image/jpeg;base64,', '');
    return new Promise<void>((resolve, reject) => {
      ref
        .putString(base64Stripped, 'base64', { contentType: 'image/jpeg' })
        .then(result => {
          result.state === 'success' ? resolve() : undefined, reject;
        })
        .catch(reject);
    }).then(() => ref.getDownloadURL().toPromise());
  }

  private getURI(type: DocumentType, userId: string, extension: string): string {
    if (type === DocumentType.PROFILE_IMAGE) {
      return 'profile/' + userId + '.' + extension;
    }
    let suffix = '';
    switch (type) {
      case DocumentType.CV:
        suffix = 'cv';
        break;
      case DocumentType.PASSPORT:
        suffix = 'passport';
        break;
      case DocumentType.WORK_VISA:
        suffix = 'work-visa';
        break;
      case DocumentType.BIRTH_CERT:
        suffix = 'birth-cert';
        break;
      case DocumentType.NI_CARD:
        suffix = 'ni-card';
        break;
      case DocumentType.NI_PROOF:
        suffix = 'ni-proof';
        break;
      default:
        suffix = 'document';
        break;
    }
    return 'document/' + userId + '/' + suffix + '.' + extension;
  }
}
