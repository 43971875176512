import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/core/error-handler.service';
import { LinkService } from '@app/core/link.service';
import { Company } from '@app/core/models/company.model';
import { CurrentUser } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
import { EventService, Event } from '@app/core/services/event.service';
import { UserService } from '@app/core/services/user.service';
import { ConfirmDialogComponent } from '@app/shared/dialog/confirm-dialog.component';
import { RegisterForNotificationsComponent } from './register-for-notifications.dialog.component';

@Component({
  selector: 'register-interest-card',
  templateUrl: './register-interest-card.component.html',
  styleUrls: ['register-interest-card.component.scss'],
})
export class RegisterInterestCardComponent implements AfterContentInit {
  @ViewChild(RegisterForNotificationsComponent)
  registerForNotificationsDialog: RegisterForNotificationsComponent;

  @ViewChild(ConfirmDialogComponent)
  confirmDialog: ConfirmDialogComponent;

  @Input()
  event: Event;

  @Input()
  company: Company;

  @Input()
  currentUser: CurrentUser;

  @Input()
  isRolesAvailable: boolean;

  isInterestRegisteredLoading: boolean;
  isRegisteredForNotifications: boolean;

  isLoggedIn: boolean;

  constructor(private linkService: LinkService, private router: Router, private authService: AuthService, private eventService: EventService, private errorHandlerService: ErrorHandlerService) {}

  ngAfterContentInit(): void {
    this.init();
  }

  init() {
    this.isLoggedIn = this.authService.isLoggedIn();
    if (this.isLoggedIn) {
      this.refreshInterestRegistered();
    }
  }

  openRegisterNotificationsDialog() {
    this.registerForNotificationsDialog.openRegisterNotification(this.event, this.company, this.currentUser.user);
  }

  onInterestInNotificationsRemove() {
    if (this.currentUser) {
      this.confirmDialog.openConfirm({
        title: 'Stop receiving notifications?',
        message: 'Would you like to remove yourself from ' + this.company.name + "'s list of people interested in this event?",
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        onConfirm: () => {
          this.isInterestRegisteredLoading = true;
          return this.eventService
            .setCurrentUserForEventNotifications(this.event?.id, false)
            .then(() => (this.isRegisteredForNotifications = false))
            .catch(this.errorHandlerService.reportError('EventPageComponent.onInterestInNotificationsRemove'))
            .finally(() => (this.isInterestRegisteredLoading = false));
        },
      });
    }
  }

  login() {
    this.linkService.navigate(['login'], { queryParams: { redirect: this.router.url } });
  }

  register() {
    this.linkService.navigate(['register'], { queryParams: { redirect: this.router.url } });
  }

  private refreshInterestRegistered() {
    this.isInterestRegisteredLoading = true;
    this.eventService
      .isCurrentUserInterestedInEventNotifications(this.event.id)
      .then(isRegistered => (this.isRegisteredForNotifications = isRegistered))
      .catch(this.errorHandlerService.reportError('RegisterInterestCardComponent.refreshInterestRegistered'))
      .finally(() => (this.isInterestRegisteredLoading = false));
  }
}
