import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Dialog } from '@app/shared/dialog/dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LinkService } from '@app/core/link.service';
import { Timestamp } from '@app/core/models/firebase.model';

@Component({
  selector: 'datetime-picker-dialog',
  templateUrl: './datetime-picker-dialog.component.html',
  styleUrls: ['./datetime-picker-dialog.component.scss'],
})
export class DatetimePickerDialogComponent extends Dialog {
  form!: FormGroup;

  @Input()
  minDate: Timestamp;

  @Input()
  maxDate: Timestamp;

  @Input()
  title: string;

  private callback: (dateTime: Timestamp) => void;

  constructor(protected location: Location, protected linkSerivce: LinkService, private formBuilder: FormBuilder) {
    super(location, linkSerivce, 'date-range-picker-dialog', false);
    this.createForm();
  }

  edit(dateTime: Timestamp, defaultValue: Timestamp, callback: (dateTime: Timestamp) => void) {
    this.callback = callback;
    this.form.setValue({
      dateTime: dateTime || defaultValue,
    });

    super.open();
  }

  cancel() {
    this.callback(null);
    this.close();
  }
  close() {
    super.close();
    this.form.reset();
  }

  save() {
    this.callback(this.dateTime.value);
    this.close();
  }

  get dateTime() {
    return this.form.controls.dateTime;
  }

  private createForm() {
    this.form = this.formBuilder.group({
      dateTime: [null, Validators.required],
    });
  }
}
