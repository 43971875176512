import { Component, EventEmitter, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Dialog } from '@app/shared/dialog/dialog.component';
import * as $ from 'jquery';
import { LinkService } from '../link.service';

@Component({
  selector: 'unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html'
})
export class UnsavedChangesDialogComponent {

}

export class UnsavedChangesDialogWrapper extends Dialog {

  constructor(protected location: Location, protected linkService: LinkService, message: string, type: string, private onReponseFromUser: (confirm: boolean) => void) {
    super(location, linkService, 'unsaved-dialog', false);
    $(this.selector + ' .message').text(message);
    $(this.selector + '  .confirm').off('click');
    $(this.selector + '  .confirm').click(() => this.onConfirm());
    $(this.selector + '  .confirm').text(type + ' anyway');
    $(this.selector + '  .cancel').off('click');
    $(this.selector + '  .cancel').click(() => this.onCancel());
  }

  get id(): string {
    return 'unsaved-dialog';
  }

  onConfirm() {
    this.onReponseFromUser(true);
    this.close();
  }

  onCancel() {
    this.onReponseFromUser(false);
    this.close();
  }
}
