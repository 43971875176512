import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stripHttp' })
export class StripHttpPipe implements PipeTransform {
  transform(value: string) {
    if (value == null || !value.length) {
      return null;
    }
    return value
      .trim()
      .replace('http://', '')
      .replace('https://', '')
      .replace('www.', '');
  }
}
