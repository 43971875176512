import { Timestamp } from '../models/firebase.model';
import * as moment from 'moment';
import sortBy from 'lodash-es/sortBy';
import isMatch from 'lodash-es/isMatch';
import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import { toInitCap, trimOrNull } from './helpers.model';
import { trim } from 'jquery';

export class CustomClaims {
  adminForCompanys: string[];
  timesheetManagerForCompanys: string[];
  superAdminForCompanys: string[];
  emailHash: string;
  isGodAdmin: boolean;

  constructor(claims: any) {
    this.adminForCompanys = claims?.adminForCompanys || [];
    this.superAdminForCompanys = claims?.superAdminForCompanys || [];
    this.timesheetManagerForCompanys = claims?.timesheetManagerForCompanys || [];
    this.emailHash = claims?.emailHash || null;
    this.isGodAdmin = claims?.isGodAdmin || false;
  }
}

export class Address {
  line1: string;
  line2: string;
  city: string;
  county: string;
  postCode: string;
  country: string;

  static createBlank(): Address {
    return {
      line1: null,
      line2: null,
      city: null,
      county: null,
      postCode: null,
      country: null,
    };
  }

  static toString(address: Address): string {
    if (!address) {
      return null;
    } else {
      const formatted = Address.toJSON(address);
      return [
        trimOrNull(formatted.line1),
        trimOrNull(formatted.line2),
        trimOrNull(formatted.city),
        trimOrNull(formatted.county),
        trimOrNull(formatted.postCode),
        trimOrNull(formatted.country),
      ]
        .filter(a => !!a)
        .join(', ');
    }
  }

  static toJSON(address: Address) {
    const postCode = trimOrNull(address.postCode);
    return {
      line1: toInitCap(trimOrNull(address.line1)),
      line2: toInitCap(trimOrNull(address.line2)),
      city: toInitCap(trimOrNull(address.city)),
      county: toInitCap(trimOrNull(address.county)),
      postCode: postCode ? postCode.toUpperCase() : null,
      country: toInitCap(trimOrNull(address.country)),
    };
  }
}

export class Contact {
  name: string;
  telephone: string;
  relationship: string;

  static toJSON(contact: Contact) {
    return {
      name: toInitCap(trimOrNull(contact.name)),
      telephone: trimOrNull(contact.telephone),
      relationship: trimOrNull(contact.relationship),
    };
  }
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum ExperienceType {
  FESTIVAL = 'FESTIVAL',
  COCKTAIL = 'COCKTAIL',
  MANAGER = 'MANAGER',
  SUPERVISOR = 'SUPERVISOR',
  FIRST_AID = 'FIRST_AID',
  PERSONAL_LICENCE = 'PERSONAL_LICENCE',
}

export class Experience {
  type: ExperienceType;
  notes: string;

  static getOrder(experience: Experience) {
    switch (experience.type) {
      case ExperienceType.FESTIVAL:
        return 1;
      case ExperienceType.COCKTAIL:
        return 2;
      case ExperienceType.MANAGER:
        return 3;
      case ExperienceType.SUPERVISOR:
        return 4;
      case ExperienceType.FIRST_AID:
        return 5;
      case ExperienceType.PERSONAL_LICENCE:
        return 6;
      default:
        return 0;
    }
  }
}

export enum IdentificationType {
  UK_EU_PASSPORT = 'UK_EU_PASSPORT',
  NON_EU_PASSPORT = 'NON_EU_PASSPORT',
  BIRTH_CERT_NI = 'BIRTH_CERT_NI',
  BIRTH_CERT_NI_PROOF = 'BIRTH_CERT_NI_PROOF',
}

export enum EmployeeStatement {
  NO_JOB_OR_BENEFIT = 'NO_JOB_OR_BENEFIT',
  PREVIOUS_JOB_OR_BENEFIT = 'PREVIOUS_JOB_OR_BENEFIT',
  CONCURRENT_JOB_OR_BENEFIT = 'CONCURRENT_JOB_OR_BENEFIT',
}

export class SelfEmployedInfo {
  utr: string;
  agreeToInvoice: boolean;
}

export class EmployeeInfo {
  studentLoan: boolean;
  studentLoanInfo?: StudentLoanInfo;
  statement: EmployeeStatement;

  static toJSON(info: EmployeeInfo): EmployeeInfo {
    return {
      studentLoan: info.studentLoan || false,
      studentLoanInfo: info.studentLoan && info.studentLoanInfo ? StudentLoanInfo.toJSON(info.studentLoanInfo) : null,
      statement: info.statement,
    };
  }
}

export class StudentLoanInfo {
  payingDirect: boolean;
  planType: 'PLAN_1' | 'PLAN_2';
  finishedBeforeThisFY: boolean;

  static toJSON(info: StudentLoanInfo) {
    return {
      payingDirect: info.payingDirect,
      planType: info.planType,
      finishedBeforeThisFY: info.finishedBeforeThisFY,
    };
  }
}

export enum DocumentType {
  CV = 'CV',
  PASSPORT = 'PASSPORT',
  WORK_VISA = 'WORK_VISA',
  NI_CARD = 'NI_CARD',
  NI_PROOF = 'NI_PROOF',
  BIRTH_CERT = 'BIRTH_CERT',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
}

export class Document {
  type: DocumentType;
  uri: string;

  static toJSON(doc: Document) {
    return {
      type: doc.type,
      uri: doc.uri,
    };
  }
}

export class UserExtras {
  companyId: string;
  employeeId: number;
  approved: boolean;
  blacklisted: boolean;
  payrollActive: boolean;
  notes: {
    company: { text: string; date: Timestamp }[];
    system: { text: string; date: Timestamp }[];
  };
}

export class UserMetadataForCompany {
  companyId: string;

  applications: {
    id: string;
    eventId: string;
    roleId: string;
  }[];
  interested: {
    eventId: string;
    added: Timestamp;
  }[];

  constructor() {
    this.applications = [];
    this.interested = [];
  }
}

export class UserBasic {
  id?: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;

  companyProfileAccess: string[];
  companyBasicAccess: string[];
  companyPayrollAccess: string[];

  getName() {
    return (this.firstName || '') + ' ' + (this.lastName || '');
  }

  getTitle() {
    return toInitCap(trimOrNull(this.title));
  }

  getFullName() {
    return (this.firstName || '') + ' ' + (this.lastName || '');
  }

  getFullTitledName() {
    return (this.getTitle() || '') + ' ' + this.getFullName();
  }

  getReversedName() {
    return ((this.lastName + ',' || '') + ' ' + (this.firstName || '')).trim();
  }
}

export class UserLite extends UserBasic {
  telephone?: string;
  address?: {
    city: string;
  };
}

export class User extends UserLite {
  title: string;
  gender: Gender;
  dob: Timestamp;
  cv: Document;
  experience: Experience[];
  experienceNotes?: string;
  nationality?: string;
  disability?: boolean;
  disabilityNotes?: string;
  address?: Address;
  passportNumber?: string;
  emergencyContact?: Contact;
  ni?: string;
  identifyingDocuments?: Document[];
  identificationType?: IdentificationType;
  selfEmployed?: boolean;
  selfEmployedInfo?: SelfEmployedInfo;
  employeeInfo?: EmployeeInfo;
  hoursWorkingRegsWaver?: boolean;
  dateCreated: Timestamp;
  dateUpdated: Timestamp;

  constructor() {
    super();
  }

  getAge() {
    return moment().diff(moment(this.dob.toDate()), 'years');
  }

  getAddressStr() {
    return Address.toString(this.address);
  }

  static toJSON(user: User) {
    return {
      title: user.title,
      firstName: toInitCap(trim(user.firstName)),
      lastName: toInitCap(trim(user.lastName)),
      gender: user.gender,
      email: user.email,
      image: user.image,
      dob: user.dob.toMillis(),
      cv: user.cv ? Document.toJSON(user.cv) : null,
      nationality: toInitCap(trimOrNull(user.nationality)),
      experience: user.experience ? sortBy(user.experience, e => Experience.getOrder(e)) : [],
      experienceNotes: trimOrNull(user.experienceNotes),
      telephone: trimOrNull(user.telephone),
      disability: user.nullableBool(user.disability),
      disabilityNotes: user.disability ? trimOrNull(user.disabilityNotes) : null,
      address: user.address ? Address.toJSON(user.address) : null,
      ni: trimOrNull(user.ni ? user.ni.toUpperCase() : null),
      passportNumber: trimOrNull(user.passportNumber),
      emergencyContact: user.emergencyContact ? Contact.toJSON(user.emergencyContact) : null,
      identifyingDocuments: user.identifyingDocuments ? user.identifyingDocuments.map(Document.toJSON) : [],
      identificationType: user.identificationType || null,
      hoursWorkingRegsWaver: user.hoursWorkingRegsWaver || null,
      selfEmployed: user.nullableBool(user.selfEmployed),
      selfEmployedInfo: (user.selfEmployed && user.selfEmployedInfo) || null,
      employeeInfo: !user.selfEmployed && user.employeeInfo ? EmployeeInfo.toJSON(user.employeeInfo) : null,
    };
  }

  isEqual(user: User) {
    return isMatch(user, this) && isEqual(user.experience, this.experience);
  }

  private nullableBool(bool: boolean) {
    if (bool === true) {
      return true;
    } else if (bool === false) {
      return false;
    } else {
      return null;
    }
  }

  static clone(user: User) {
    return cloneDeep(user);
  }

  static getRequiredDocumentsForIdentificationType(type: IdentificationType): DocumentType[] {
    if (!type) {
      return [];
    }
    switch (type) {
      case IdentificationType.UK_EU_PASSPORT:
        return [DocumentType.PASSPORT];
      case IdentificationType.NON_EU_PASSPORT:
        return [DocumentType.PASSPORT, DocumentType.WORK_VISA];
      case IdentificationType.BIRTH_CERT_NI:
        return [DocumentType.BIRTH_CERT, DocumentType.NI_CARD];
      case IdentificationType.BIRTH_CERT_NI_PROOF:
        return [DocumentType.BIRTH_CERT, DocumentType.NI_PROOF];
    }
  }
}

export class CurrentUser {
  id: string;
  user: User;
  email: string;
  image: string;
  claims: CustomClaims;
  authPhotoUrl: string;
  authFirstName: string;
  authLastName: string;
  emailVerified: boolean;
  isEmailProvider: boolean;

  constructor(user: User, auth: firebase.default.User, claims: CustomClaims) {
    if (user) {
      this.user = user;
    }

    this.email = auth.email;
    this.isEmailProvider = !!auth.providerData.find(provider => provider.providerId === 'password');

    this.emailVerified = !this.isEmailProvider || auth.emailVerified;
    this.claims = claims;
    this.id = claims.emailHash;
    this.authPhotoUrl = auth.photoURL ? auth.photoURL + '?type=large' : null;

    if (auth.displayName) {
      this.authFirstName = auth.displayName.substr(0, auth.displayName.indexOf(' ')).trim();
      this.authLastName = auth.displayName.replace(this.authFirstName, '').trim();
    }
  }

  isMinimumFieldsComplete(): boolean {
    return this.user && this.user.email && this.user.image && this.user.firstName && this.user.lastName && !!this.user.gender;
  }
}

export class UserSettings {
  eventMasterEmailEvents: boolean;
  eventMasterEmailNews: boolean;
}

export function parseUser(json: any, id: string): User {
  const user = Object.assign(new User(), json);
  user.id = id;
  return user;
}

export function parseUserData(doc: any): User {
  console.log('Parsing retrieved user');
  return parseUser(doc.data(), doc.id);
}

export function parseUserLite(json: any, id: string): UserLite {
  if (json) {
    const user = Object.assign(new UserLite(), json);
    user.id = id;
    return user;
  } else {
    return null;
  }
}

export function parseUserBasicData(doc: any): UserBasic {
  return parseUserBasic(doc.data(), doc.id);
}

export function parseUserBasic(json: any, id: string): UserBasic {
  const user = Object.assign(new UserBasic(), json);
  user.id = id;
  return user;
}

export function parseUserExtras(json: any, id: string): UserExtras {
  const extras = Object.assign(new UserExtras(), json);
  extras.companyId = id;
  return extras;
}

export function parseUserExtrasData(doc: any): UserExtras {
  return parseUserExtras(doc.data(), doc.id);
}

export function parseUserMetadata(json: any, companyId: string): UserMetadataForCompany {
  json = json || {};
  console.log('Parsing retrieved user metadata');
  const metadata = new UserMetadataForCompany();
  metadata.companyId = companyId;
  metadata.applications = Object.keys(json.applications || {})
    .map(key => {
      if (json.applications[key]) {
        json.applications[key].id = key;
        return json.applications[key];
      } else {
        return null;
      }
    })
    .filter(a => !!a);

  metadata.interested = Object.keys(json.interested || {}).map(eventId => ({ eventId, added: json.interested[eventId].added }));
  return metadata;
}
