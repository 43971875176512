<div class="page">
	<div class="container">
		<div class="row mt-5">
			<div class="col-md-6">
				<img class="mb-5 logo" src="/assets/branding/logo-gold.svg" />
				<h3 class="mb-5">You have been logged out</h3>
			</div>

			<div class="col-md-6">
				<img class="circles desktop" src="/assets/branding/circles.svg" />
			</div>
		</div>
	</div>
</div>
