<div class="modal" id="{{ id }}" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog zoomIn faster animated" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="{{ id }}-title">Application Summary</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="application">
        <div class="pl-2">
          <h6>Application Status:</h6>
          <application-status [status]="application.status"> </application-status>

          <h6>Event:</h6>
          <p>{{ event?.name || '-' }}</p>

          <h6>Role:</h6>
          <p>{{ role?.name || '-' }}</p>

          <h6>Rate:</h6>
          <p>{{ role | roleRate }} <span *ngIf="event?.holidayPay"> + Holiday Pay</span></p>
        </div>
        <current-user-info [isLoading]="isSectionsLoading" [user]="user" [editable]="false" [showSectionError]="false"> </current-user-info>
        <current-user-experience [isLoading]="isSectionsLoading" [user]="user" [editable]="false" [showSectionError]="false" (onLoaded)="isSectionsLoading = false"> </current-user-experience>
        <current-user-contact [isLoading]="isSectionsLoading" [user]="user" [editable]="false" [showSectionError]="false"> </current-user-contact>
        <current-user-right-to-work [isLoading]="isSectionsLoading" [user]="user" [editable]="false" [showSectionError]="false"> </current-user-right-to-work>
        <current-user-payroll *ngIf="role?.info?.payrollRequiredUpFront" [isLoading]="isSectionsLoading" [user]="user" [editable]="false" [showSectionError]="false"> </current-user-payroll>

        <div class="pl-2">
          <div>
            <h6>Cover Letter:</h6>
            <p>{{ application?.coverLetter || '-' }}</p>
          </div>
          <div>
            <h6>Priority Choice:</h6>
            <p>{{ (application?.priorityChoice | numberOrder) || 'None' }}</p>
          </div>
          <div>
            <h6>How did you find out about this role:</h6>
            <p>
              <application-source-label [source]="application.source"></application-source-label>
            </p>
          </div>
          <div>
            <h6>I have reviewed all information being submitted with this Application and confirm it is accurate:</h6>
            <p>Agree</p>
          </div>
          <div>
            <h6>I agree to grant Impact Bar access to my event master Profile:</h6>
            <p>Agree</p>
          </div>
        </div>
        <hr />
        <h4>Application Status Tracking</h4>
        <application-history [history]="application?.history"></application-history>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</div>
