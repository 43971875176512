<div class="row">
	<div class="col-7">
		<p-calendar [(ngModel)]="date" [baseZIndex]="1060" [showIcon]="!isMobile" [showButtonBar]="clearButton" appendTo="body" [focusTrap]="isMobile" [touchUI]="isMobile" [readonlyInput]="true" dateFormat="dd/mm/yy">
			<ng-template pTemplate="date" let-date>
				<span
					[ngStyle]="{ backgroundColor: isDateWithinRange(date) ? 'lightgrey' : '' }"
					style="border-radius:100%; display:block; padding: 0.25rem; padding-right: 0.3rem;
    padding-top: 0.3rem; width: 2rem; height: 2rem;"
					>{{ date.day }}</span
				>
			</ng-template>
		</p-calendar>
	</div>
	<div class="col-5">
		<p-calendar
			[(ngModel)]="time"
			(ngModelChange)="onTimeUpdated($event)"
			[baseZIndex]="1060"
			[showIcon]="!isMobile"
			icon="pi pi-clock"
			appendTo="body"
			[focusTrap]="isMobile"
			[defaultDate]="defaultTime"
			[showButtonBar]="clearButton"
			hourFormat="24"
			[hideOnDateTimeSelect]="false"
			[showTime]="true"
			[timeOnly]="true"
			[touchUI]="isMobile"
			[readonlyInput]="true"
			[stepMinute]="15"
		>
		</p-calendar>
	</div>
</div>
