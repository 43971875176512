<div class="bank-info" [class.is-profile-complete]="(isProfileComplete || !showSectionError) && !isLoading">
	<div class="absolute-fill vertical-center" *ngIf="isLoading || !user">
		<fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="text-center"></fa-icon>
	</div>
	<div class="row row-no-margin">
		<div class="body-col flex-auto p-2">
			<div class="row">
				<div class="col">
					<h5>Curriculum Vitae:</h5>
					<div class="info">
						<document-label [document]="user.cv" [type]="DocumentType.CV" [showError]="false" [light]="true" [small]="false" *ngIf="user && !isLoading"> </document-label>
						<p class="error text-center-mobile mb-5" *ngIf="!link">Required</p>
					</div>
					<h5>Experience:</h5>
					<div *ngIf="user && !isLoading">
						<div class="row my-2" *ngFor="let e of user.experience">
							<div class="col-sm-12 col-md-5 text-right text-center-mobile">
								<experience-label class="desktop" [gold]="!isProfileComplete" [type]="e.type" [reverse]="true" [large]="false"> </experience-label>
								<experience-label class="mobile" [gold]="!isProfileComplete" [showIcon]="false" [type]="e.type" [reverse]="false" [large]="false"> </experience-label>
							</div>
							<div class="col text-center-mobile vertical-center">
								<p>{{ e.notes || '-' }}</p>
							</div>
						</div>
						<p class="text-center small" *ngIf="!user.experience.length && !user.experienceNotes">None provided</p>
						<div class="row" *ngIf="user.experienceNotes">
							<div class="col-sm-12 col-md-5 text-right text-center-mobile">
								<h6 class="mt-0">Other Relevant Experience</h6>
							</div>
							<div class="col vertical-center text-center-mobile">
								<p>{{ user.experienceNotes }}</p>
								<p *ngIf="!user.experience || (!user.experience.length && !user.experienceNotes)" class="text-center-mobile">-</p>
							</div>
						</div>
					</div>
					<div *ngIf="!user || isLoading">
						<div class="row mt-5">
							<div class="col-sm-12 col-md-5 text-right text-center-mobile">
								<h6 class="mt-0">Other Relevant Experience</h6>
							</div>
							<div class="col vertical-center text-center-mobile">
								<p class="text-center-mobile">-</p>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-6" *ngFor="let reference of references; let i = index">
							<div class="bank-info reference mb-2">
								<h5 class="mt-0">Reference {{ i + 1 }}</h5>
								<div>
									<div class="row info mt-0">
										<div class="col-sm-12 col-md-5 vertical-center">
											<h6 class="tiny">Company Name</h6>
										</div>
										<div class="col vertical-center">
											<p *ngIf="reference">{{ reference.company }}</p>
											<p class="error" *ngIf="!reference || !reference.company">Required</p>
										</div>
									</div>
									<div class="row info mt-0">
										<div class="col-sm-12 col-md-5 vertical-center">
											<h6 class="tiny">Referee Name</h6>
										</div>
										<div class="col vertical-center">
											<p *ngIf="reference">{{ reference.refereeName }}</p>
											<p class="error" *ngIf="!reference || !reference.refereeName">Required</p>
										</div>
									</div>
									<div class="row info mt-0">
										<div class="col-sm-12 col-md-5 vertical-center">
											<h6 class="tiny">Telephone</h6>
										</div>
										<div class="col vertical-center">
											<p *ngIf="reference">{{ reference.telephone }}</p>
											<p class="error " *ngIf="!reference || !reference.telephone">Required</p>
										</div>
									</div>
									<div class="row info mt-0">
										<div class="col-sm-12 col-md-5 vertical-center">
											<h6 class="tiny">Email</h6>
										</div>
										<div class="col vertical-center">
											<p *ngIf="reference">{{ reference.email }}</p>
											<p class="error" *ngIf="!reference || !reference.email">Required</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row row-no-margin" *ngIf="editable">
		<button class="btn btn-success ml-auto d-block mt-2 mr-2 text-center-mobile" (click)="edit()">
			<fa-icon [icon]="['fas', 'pencil-alt']" class="mr-2"></fa-icon>
			{{ isProfileComplete ? 'Edit' : 'Complete Experience' }}
		</button>
	</div>
</div>
