import { Component, Input } from '@angular/core';
import { ApplicationHistory } from '@app/core/services/application.service';
import { Dialog } from '../../dialog/dialog.component';
import { LinkService } from '@app/core/link.service';
import { Location } from '@angular/common';

@Component({
  selector: 'application-history-dialog',
  templateUrl: './application-history-dialog.component.html',
})
export class ApplicationHistoryDialogComponent extends Dialog {
  @Input()
  isAdmin = false;

  history: ApplicationHistory[];

  constructor(protected location: Location, protected linkService: LinkService) {
    super(location, linkService, 'application-history-dialog');
  }

  openWithHistory(history: ApplicationHistory[]) {
    this.history = history;
    super.open();
  }
}
