import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LinkService } from '@app/core/link.service';
import { RoleService } from '@app/core/services/role.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class RoleIsOpenGuard implements CanActivate {
  constructor(private linkService: LinkService, private roleService: RoleService, private toastr: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const eventId = route.params['eventId'];
    const roleId = route.params['roleId'];
    return this.roleService.getRole(eventId, roleId).then(role => {
      if (!role.open) {
        this.linkService.navigate(['events', eventId]);
        this.toastr.warning('This role is not currently open for applications');
        return false;
      } else {
        return true;
      }
    });
  }
}
