import { Component } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { UserAvatarComponent } from './user-avatar.component';
import { FileStorageService } from '@app/core/services/file-storage.service';
import { UserCacheService } from '@app/core/services/user-cache.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'current-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class CurrentUserAvatarComponent extends UserAvatarComponent {
  constructor(protected fileStorageService: FileStorageService, protected userCacheService: UserCacheService, protected authService: AuthService) {
    super(fileStorageService, userCacheService);
    this.authService.getLoggedInUser().then(currentUser => {
      this.user = currentUser.user;
    });
  }
}
