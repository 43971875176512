import { Component } from '@angular/core';
import { LoginComponent } from './login.component';
import { CompanyService } from '@app/core/services/company.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.scss']
})
export class RegisterComponent extends LoginComponent {

  constructor(protected companyService: CompanyService) {
    super(companyService);
  }
}
