import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { User } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
@Component({
  selector: 'user-basic-permissions-card',
  templateUrl: './user-basic-permissions-card.component.html',
  styles: ['h6 { display: table}', '.text-parent { overflow: hidden; }', '.bank-info { position: relative; }', '.top-right {position: absolute; top: 0.5rem; right: 0.5rem'],
})
export class UserBasicPermissionsCardComponent {
  @Input()
  isLoading: boolean = false;

  @Input()
  showDelete: boolean = true;

  @Output()
  onDelete: EventEmitter<void> = new EventEmitter<void>();

  user: User;

  constructor(private authService: AuthService) {
    this.authService.getLoggedInUser().then(currentUser => (this.user = currentUser && currentUser.user));
  }
}
