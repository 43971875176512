import { Component } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { Router } from '@angular/router';
import { LinkService } from '@app/core/link.service';
import { CurrentUserInfoComponent } from './current-user-info.component';
import { FileStorageService } from '@app/core/services/file-storage.service';
import { Document, DocumentType, User } from '@app/core/models/user.model';
import { CompanyService } from '@app/core/services/company.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'current-user-right-to-work',
  templateUrl: './current-user-right-to-work.component.html',
  styleUrls: ['./current-user-info.component.scss'],
})
export class CurrentUserRightToWorkComponent extends CurrentUserInfoComponent {
  requiredTypes: DocumentType[];
  requiredDocumentForType: { type: DocumentType; document: Document }[];

  protected profileEditorSection: string = 'identity';

  constructor(
    protected authService: AuthService,
    protected linkService: LinkService,
    protected router: Router,
    protected companyService: CompanyService,
    private fileStorageService: FileStorageService,
  ) {
    super(authService, linkService, router, companyService);
  }

  protected async setupThisSection() {
    this.requiredTypes = User.getRequiredDocumentsForIdentificationType(this.user.identificationType);
    this.requiredDocumentForType = this.requiredTypes.map(requiredType => ({
      type: requiredType,
      document: this.user.identifyingDocuments.find(t => t.type === requiredType),
    }));
  }

  protected getFieldStates(): Promise<boolean[]> {
    const employeeFields = [this.user.employeeInfo && this.isBooleanSet(this.user.employeeInfo.studentLoan), this.user.employeeInfo && !!this.user.employeeInfo.statement];

    if (this.user.employeeInfo && this.user.employeeInfo.studentLoan) {
      employeeFields.push(this.user.employeeInfo.studentLoanInfo && this.isBooleanSet(this.user.employeeInfo.studentLoanInfo.finishedBeforeThisFY));
      employeeFields.push(this.user.employeeInfo.studentLoanInfo && this.isBooleanSet(this.user.employeeInfo.studentLoanInfo.payingDirect));
      employeeFields.push(this.user.employeeInfo.studentLoanInfo && !!this.user.employeeInfo.studentLoanInfo.planType);
    }

    const selfEmployedFields = [this.user.selfEmployedInfo && this.user.selfEmployedInfo.agreeToInvoice, this.user.selfEmployedInfo && !!this.user.selfEmployedInfo.utr];

    const conditionalFields = this.user.selfEmployed ? selfEmployedFields : employeeFields;

    return this.isRightToWorkValid().then(result => [
      ...result,
      !!this.user.identificationType,
      !!this.user.ni,
      ...conditionalFields,
      !!this.user.hoursWorkingRegsWaver,
      this.isBooleanSet(this.user.selfEmployed),
    ]);
  }

  private isRightToWorkValid(): Promise<boolean[]> {
    if (!this.user.identificationType || !this.user.identifyingDocuments) {
      return Promise.resolve([false]);
    } else {
      return Promise.all(
        this.requiredTypes.map(requiredType => {
          const foundDocument = this.user.identifyingDocuments.find(t => t.type === requiredType);
          return foundDocument ? this.isAllDocumentValid(foundDocument) : Promise.resolve(false);
        }),
      );
    }
  }

  private isAllDocumentValid(document: Document): Promise<boolean> {
    return this.fileStorageService.getDocument(document).then(
      link => {
        document['valid'] = !!link;
        return !!link;
      },
      () => {
        document['valid'] = false;
        return false;
      },
    );
  }
}
