import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Dialog } from '@app/shared/dialog/dialog.component';
import { Location } from '@angular/common';
import { FileStorageService } from '@app/core/services/file-storage.service';
import { UserService } from '@app/core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ImageShape } from './image-uploader.component';
import { LinkService } from '@app/core/link.service';

@Component({
  selector: 'image-uploader-dialog',
  templateUrl: './image-uploader-dialog.component.html',
})
export class ImageUploaderDialogComponent extends Dialog {
  @Input()
  preselectedImage: File = null;

  @Input()
  shape: ImageShape;

  @Input()
  title: string;

  private base64: string;
  private readyForSave: string;

  isSaving: boolean = false;

  isOpen = false;
  isFileSelected = false;
  isImageLoaded = false;

  @Output()
  onUpload: EventEmitter<string> = new EventEmitter<string>();

  constructor(protected location: Location, protected linkService: LinkService, protected fileStorageService: FileStorageService, protected userService: UserService, protected toastr: ToastrService) {
    super(location, linkService, 'image-uploader-dialog');
  }

  open() {
    super.open();
    this.isOpen = true;
    this.isImageLoaded = false;
  }

  onImageSelectedForUpload(base64: string) {
    this.base64 = base64;
    this.isImageLoaded = true;
  }

  save() {
    this.onUpload.emit(this.base64);
    this.readyForSave = this.base64;
    this.close();
  }

  close() {
    super.close();
    this.isOpen = false;
    this.base64 = null;
    this.isFileSelected = false;
  }

  getImageIfSet() {
    return this.readyForSave || null;
  }

  onFileSelected() {
    this.isFileSelected = true;
  }
}
