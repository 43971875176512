<div class="page">
	<div class="container">
		<div class="row mt-5">
			<div class="col-md-6 logo-parent">
				<img class="mb-5 logo" src="/assets/branding/logo-teal.svg" />
				<h3 class=" mb-2">
					Register an event master profile today to make light work of applying for event roles with <span class="event-master-blue-color">{{ company.name }}</span
					>.
				</h3>
				<div class="d-flex mb-3 mt-5">
					<div class="flex-none mr-2 vertical-center">
						<fa-icon [icon]="['fas', 'user-circle']" size="2x" class="event-master-gold-color"></fa-icon>
					</div>
					<h6 class="flex-auto">
						Simply input all of your details to showcase your skills, achievements and past experiences. Then very quickly and easily transfer your details to a variety of roles, events and companies within seconds.
					</h6>
				</div>

				<div class="d-flex mb-2">
					<div class="flex-none mr-2 vertical-center">
						<fa-icon [icon]="['fas', 'lock']" size="2x" class="event-master-gold-color"></fa-icon>
					</div>
					<h6 class="flex-auto">
						Your details are secure and they won’t be shared with any company until you apply. When you do apply for a position we will only share your details with the company you’ve applied to work for so that they can process your application.
					</h6>
				</div>
			</div>

			<div class="col-md-6 circles-parent">
				<h2 class="event-master-teal-color text-center bold mt-0 mb-3">Register Now</h2>
				<img class="circles desktop" src="/assets/branding/circles.svg" />
				<login-buttons id="login-buttons" redirectTo="/"></login-buttons>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<img src="/assets/branding/dancers.svg" />
			</div>
			<div class="col-md-6"></div>
		</div>
	</div>
</div>
