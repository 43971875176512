import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CompanyDeposits, parseCompanyDeposits } from '../models/deposit.model';
import { AuthService } from './auth.service';

@Injectable()
export class DepositService {
  constructor(private firestore: AngularFirestore, private authService: AuthService, private fns: AngularFireFunctions) {}

  getDepositsForUser(userId: string): Promise<CompanyDeposits[]> {
    console.info('getting deposits for userId: ' + userId);
    const result = this.firestore.collection('users/' + userId + '/deposits');
    return result
      .get()
      .toPromise()
      .then(response => response.docs.map(parseCompanyDeposits.bind(this)));
  }

  getDepositsForCompany(userId: string, companyId: string) {
    console.log(`getting deposits for user: ${userId}  company: ${companyId}`);
    const result = this.firestore.doc('users/' + userId + '/deposits/' + companyId);
    return result
      .get()
      .toPromise()
      .then(response => parseCompanyDeposits(response));
  }

  getDeposit(userId: string, companyId: string, depositId: string) {
    return this.getDepositsForCompany(userId, companyId).then(deposits => {
      const found = deposits.deposits.find(d => d.id === depositId);
      return found ? Promise.resolve(found) : Promise.reject(new Error('Deposit not found with id: ' + depositId));
    });
  }

  async createStripePayment(companyId: string, depositId: string): Promise<any> {
    const authToken = await this.authService.getAuthToken();
    const callable = this.fns.httpsCallable('payment-createStripePayment', { timeout: 30000 });
    const payment = await callable({ idToken: authToken, companyId, depositId }).toPromise();
    return payment;
  }

  async requestDeposit(userId: string, companyId: string, amount: number) {
    const authToken = await this.authService.getAuthToken();
    const callable = this.fns.httpsCallable('payment-createDeposit', { timeout: 30000 });
    return callable({ idToken: authToken, userId, companyId, amount })
      .toPromise()
      .then(result => console.log(result));
  }

  async resendDepositRequestEmail(userId: string, companyId: string) {
    const authToken = await this.authService.getAuthToken();
    const callable = this.fns.httpsCallable('payment-resendDepositRequestEmail', { timeout: 30000 });
    return callable({ idToken: authToken, userId, companyId })
      .toPromise()
      .then(result => console.log(result));
  }
}
