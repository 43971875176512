import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from '@app/core/services/user.service';
import { LinkService } from '../link.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IncompleteProfileGuard implements CanActivate {
  constructor(private linkService: LinkService, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.authService.getLoggedInUser().then(user => {
      if (!state.url.includes('/profile/edit') && user && !user.isMinimumFieldsComplete()) {
        this.linkService.navigate(['profile', 'edit'], { queryParams: { redirect: state.url } });
        return false;
      } else {
        return true;
      }
    });
  }
}
