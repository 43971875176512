import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from '@app/core/services/user.service';
import { LinkService } from '../link.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private linkService: LinkService, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.authService.getLoggedInUser().then(user => {
      if (!user) {
        console.debug('Not authenticated, redirecting and adding redirect url...');
        this.linkService.navigate(['login'], { queryParams: { redirect: state.url }, replaceUrl: true });
        return false;
      } else {
        return true;
      }
    });
  }
}
