import { Pipe, PipeTransform } from '@angular/core';
import { RoleLite } from '@app/core/models/role.model';
@Pipe({ name: 'roleShifts' })
export class RoleShiftsPipe implements PipeTransform {
  transform(role: RoleLite, args: any) {
    if (role == null) {
      return null;
    }
    if (role?.shiftsManual && !args?.includes('hideManual')) {
      return role.shiftsManual;
    } else if (role?.calculated?.shifts?.length) {
      if (role.calculated.shifts.length === 1) {
        const shifts = role.calculated.shifts[0];
        return shifts > 1 ? shifts + ' Shifts' : shifts + ' Shift';
      } else {
        return `${Math.min(...role.calculated.shifts)}-${Math.max(...role.calculated.shifts)} Shifts`;
      }
    } else {
      return 'TBC';
    }
  }
}
