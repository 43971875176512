import { NgModule } from '@angular/core';

import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';

import { BlockUIModule } from 'primeng/blockui';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule, BlockUIModule],
  providers: [],
  declarations: [HeaderComponent, FooterComponent, ShellComponent],
  exports: [HeaderComponent],
})
export class ShellModule {}
