import { Directive, HostListener, Input } from '@angular/core';
import { LinkService } from '@app/core/link.service';

@Directive({
  selector: '[link]',
})
export class LinkDirective {
  @Input('link')
  link: string;

  constructor(private linkService: LinkService) {}

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.linkService.navigate([this.link]);
  }
}
