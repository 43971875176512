import { PipeTransform, Pipe } from '@angular/core';
import { DateRange } from '@app/core/models/firebase.model';
import * as moment from 'moment';

@Pipe({ name: 'timeDayRange', pure: false })
export class TimeDayRangePipe implements PipeTransform {
  transform(range: DateRange, args: any) {
    if (!range || !range.start || !range.end) {
      return null;
    }
    const time = 'HH:mm';

    const format = `${time}`;

    const startDate = moment(range.start.toDate());
    const endDate = moment(range.end.toDate());

    if (this.dayOfWeek(startDate) !== this.dayOfWeek(endDate)) {
      return startDate.format(format) + ' - ' + endDate.format(format);
    } else {
      const startTimeIfRequired = startDate.format(time) + ' - ';
      return startTimeIfRequired + endDate.format(format);
    }
  }

  private dayOfWeek(date: moment.Moment) {
    return date.get('day');
  }
}
