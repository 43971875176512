import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CompanyService } from '../services/company.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MetricsService {
  constructor(private companyService: CompanyService, private authService: AuthService, private fns: AngularFireFunctions) {}

  reportSiteViewMetric() {
    setTimeout(async () => {
      try {
        if (!(await this.authService.isLoggedInAsAdminForCompany())) {
          const loggedInUser = await this.authService.getLoggedInUser();
          let idToken: string;
          try {
            idToken = loggedInUser ? await this.authService.getAuthToken() : undefined;
          } catch (e) {}
          console.info('reporting site view metric', document.referrer);
          const callable = this.fns.httpsCallable('utils-addSiteVisitMetric', { timeout: 30000 });
          await callable({ idToken, companyId: this.companyService.getId(), referrer: document.referrer || null }).toPromise();
        } else {
          console.log('skipping site view metric as logged in as admin');
        }
      } catch (e) {
        console.error('Error processing response from metrics logger', e);
      }
    }, 1000 * 15);
  }
}
