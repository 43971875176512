import { Component } from '@angular/core';
import { Dialog } from '../../dialog/dialog.component';
import { LinkService } from '@app/core/link.service';
import { Location } from '@angular/common';
import { Application } from '@app/core/services/application.service';
import { UserService } from '@app/core/services/user.service';
import { User } from '@app/core/models/user.model';
import { Role, RoleLite } from '@app/core/models/role.model';
import { RoleService } from '@app/core/services/role.service';
import { ErrorHandlerService } from '@app/core/error-handler.service';
import { CompanyService } from '@app/core/services/company.service';
import { Event } from '@app/core/services/event.service';

@Component({
  selector: 'application-summary-dialog',
  templateUrl: './application-summary-dialog.component.html',
  styles: ['.modal-body > * { margin-bottom: 1rem; display: block}', 'h6 { margin-bottom: 0.25rem; margin-top: 1rem;} '],
})
export class ApplicationSummaryDialogComponent extends Dialog {
  application: Application;
  user: User;
  role: Role;
  isSectionsLoading: boolean = false;
  event: Event;

  constructor(
    protected location: Location,
    private companyService: CompanyService,
    private errorHandlerService: ErrorHandlerService,
    private roleService: RoleService,
    protected linkService: LinkService,
    private userService: UserService,
  ) {
    super(location, linkService, 'application-summary-dialog');
  }

  async openForApplication(application: Application, event: Event) {
    this.isSectionsLoading = true;
    this.application = application;
    this.event = event;
    try {
      this.role = await this.roleService.getRole(application.eventId, application.roleId);
      this.user = await this.userService.getUserById(application.userId);
      super.open();
    } catch (e) {
      this.errorHandlerService.reportError(
        'ApplicationSummaryDialogComponent.openForApplication()',
        'An error ocurred while trying to load your application',
        JSON.stringify({ applicationId: application.id, companyId: this.companyService.getId() }),
      );
    }
  }
}
