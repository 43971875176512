import { Injectable } from '@angular/core';
import { User, UserBasic, UserLite } from '@app/core/models/user.model';
import { UserService } from '@app/core/services/user.service';
import { ObjectCache } from '../../shared/storage/object-cache';

@Injectable()
export class UserCacheService {
  private static userBasicCache: ObjectCache<UserBasic> = new ObjectCache<UserLite>(ObjectCache.ONE_HOUR);
  private static userCache: ObjectCache<User> = new ObjectCache<User>(ObjectCache.ONE_HOUR);
  constructor(private userService: UserService) {}

  getUserBasicById(id: string) {
    if (!id) {
      throw new Error('null id passed to UserCacheService');
    }
    return UserCacheService.userBasicCache.getOrUpdate(id, () => {
      console.log('populating user cache with id: ' + id);
      return this.userService.getUserBasicById(id);
    });
  }

  addUserBasicToCache(userBasic: UserBasic) {
    UserCacheService.userBasicCache.update(userBasic.id, () => Promise.resolve(userBasic));
  }

  getUserById(id: string) {
    if (!id) {
      throw new Error('null id passed to UserCacheService');
    }
    return UserCacheService.userCache.getOrUpdate(id, () => {
      console.log('populating user cache with id: ' + id);
      return this.userService.getUserById(id);
    });
  }

  static resetId(id: string) {
    UserCacheService.userBasicCache.resetId(id);
    UserCacheService.userCache.resetId(id);
  }
}
