import { Component } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { DocumentType } from '@app/core/models/user.model';
import { Router } from '@angular/router';
import { LinkService } from '@app/core/link.service';
import { CurrentUserInfoComponent } from './current-user-info.component';
import { FileStorageService } from '@app/core/services/file-storage.service';
import flatten from 'lodash-es/flatten';
import sortBy from 'lodash-es/sortBy';
import { Reference } from '@app/core/models/reference.model';
import { ReferenceService } from '@app/core/services/reference.service';
import { CompanyService } from '@app/core/services/company.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'current-user-experience',
  templateUrl: './current-user-experience.component.html',
  styleUrls: ['./current-user-info.component.scss'],
})
export class CurrentUserExperienceComponent extends CurrentUserInfoComponent {
  link: string;

  references: Reference[] = [null, null];

  DocumentType = DocumentType;

  protected profileEditorSection: string = 'experience';

  constructor(
    protected authService: AuthService,
    private referenceService: ReferenceService,
    protected linkService: LinkService,
    protected router: Router,
    private fileStorageService: FileStorageService,
    protected companyService: CompanyService,
  ) {
    super(authService, linkService, router, companyService);
  }

  protected setupThisSection() {
    return Promise.resolve();
  }

  protected getFieldStates(): Promise<boolean[]> {
    const promise = this.getReferences().then(references => flatten(references.map(r => [r && !!r.company, r && !!r.email, r && !!r.refereeName, r && !!r.telephone])));
    return promise.then(referenceFields => this.isCVValid().then(cv => [cv, ...referenceFields]));
  }

  private isCVValid(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      this.user && this.user.cv
        ? this.fileStorageService
            .getDocument(this.user.cv)
            .then(link => (this.link = link))
            .then(
              () => (this.link ? resolve(true) : resolve(false)),
              () => resolve(false),
            )
            .catch(() => resolve(false))
        : resolve(false);
    });
  }

  private getReferences() {
    return this.referenceService.getReferencesForUser(this.user.id).then(references => {
      this.references = sortBy(references, r => r.order);
      if (this.references.length === 0) {
        this.references.push(null);
      }
      if (this.references.length === 1) {
        this.references.push(null);
      }
      return this.references;
    });
  }
}
