/**
 * For more on font awesome 5's weird way to get icons into your application, see README and
 * https://github.com/FortAwesome/angular-fontawesome/blob/51072a0435e6bb06afb1c9c1b3c579ff85913568/docs/usage/icon-library.md
 *
 * For example font awesome usage, see
 * https://github.com/FortAwesome/angular-fontawesome/blob/HEAD/docs/usage/features.md
 */
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText';
import { faStopwatch20 } from '@fortawesome/free-solid-svg-icons/faStopwatch20';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faEnvelope as fasEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons/faSatelliteDish';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { faMedkit } from '@fortawesome/free-solid-svg-icons/faMedkit';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faCocktail } from '@fortawesome/free-solid-svg-icons/faCocktail';
import { faBeer } from '@fortawesome/free-solid-svg-icons/faBeer';
import { faBaby } from '@fortawesome/free-solid-svg-icons/faBaby';
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faPassport } from '@fortawesome/free-solid-svg-icons/faPassport';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faIdCard } from '@fortawesome/free-solid-svg-icons/faIdCard';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faMouse } from '@fortawesome/free-solid-svg-icons/faMouse';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons/faGlobeEurope';
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons/faBirthdayCake';
import { faVenusMars } from '@fortawesome/free-solid-svg-icons/faVenusMars';
import { faUserTie } from '@fortawesome/free-solid-svg-icons/faUserTie';
import { faAmbulance } from '@fortawesome/free-solid-svg-icons/faAmbulance';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faUserTimes } from '@fortawesome/free-solid-svg-icons/faUserTimes';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillAlt';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { faTshirt } from '@fortawesome/free-solid-svg-icons/faTshirt';
import { faShuttleVan } from '@fortawesome/free-solid-svg-icons/faShuttleVan';
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons/faTicketAlt';
import { faCampground } from '@fortawesome/free-solid-svg-icons/faCampground';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons/faMapMarker';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faPiggyBank } from '@fortawesome/free-solid-svg-icons/faPiggyBank';

import { faFilePdf } from '@fortawesome/free-regular-svg-icons/faFilePdf';
import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons/faIdBadge';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt';
import { faBan, faBroadcastTower, faRobot } from '@fortawesome/free-solid-svg-icons';

export class FaIconsLibrary {
  // add icons to this array to be able to use them anywhere in the app
  // e.g. <fa-icon [icon]="['fas', 'coffee']"></fa-icon> would require faCoffee to be in here
  private static ICONS: any[] = [
    faLinkedin,
    faUserCircle,
    faFile,
    faBan,
    faLock,
    faBroadcastTower,
    faPlus,
    faRobot,
    faUsersCog,
    faCheck,
    faChartLine,
    faSquare,
    faCog,
    faEnvelopeOpenText,
    faFileSignature,
    farFileAlt,
    faPiggyBank,
    faTags,
    faSearch,
    faStopwatch20,
    faChevronDown,
    faUserCheck,
    faCalendarAlt,
    faMapMarker,
    faChevronLeft,
    faChevronRight,
    faDatabase,
    faCamera,
    faTimes,
    fasEnvelope,
    farEnvelope,
    faUser,
    faDownload,
    faMouse,
    faTrash,
    faTrashAlt,
    faSave,
    faClipboard,
    faSync,
    faFilePdf,
    faPassport,
    faIdBadge,
    faCreditCard,
    faFileWord,
    faBars,
    faCircle,
    faBaby,
    faIdCard,
    faBeer,
    faCocktail,
    faUserTie,
    faUsers,
    faMedkit,
    faAddressCard,
    faBriefcase,
    faEllipsisH,
    faSatelliteDish,
    faSpinner,
    faMinus,
    faFileAlt,
    faChartLine,
    faFacebookF,
    faInstagram,
    faInfoCircle,
    faHistory,
    faExternalLinkAlt,
    faPhoneAlt,
    faTicketAlt,
    faCampground,
    faCar,
    faShuttleVan,
    faTshirt,
    faUtensils,
    faCalendarAlt,
    faClock,
    faMoneyBillAlt,
    faPencilAlt,
    faUserPlus,
    faUserFriends,
    faUserTimes,
    faEye,
    faEyeSlash,
    faShareAlt,
    faSignInAlt,
    faPhoneAlt,
    faHome,
    faAmbulance,
    faUserTie,
    faVenusMars,
    faBirthdayCake,
    faBell,
    faGlobeEurope,
  ];

  static setupLibrary(library: FaIconLibrary): void {
    library.addIcons(...this.ICONS);
  }
}
