import { Component, AfterViewInit } from '@angular/core';
import { CompanyService } from '@app/core/services/company.service';
import { Company } from '@app/core/models/company.model';
import { EventService, Event } from '@app/core/services/event.service';
import { ErrorHandlerService } from '@app/core/error-handler.service';
import reverse from 'lodash-es/reverse';
@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {
  company: Company;
  isLoading: boolean = true;
  events: Event[];
  pastEvents: Event[];
  isLoadingEvents: boolean = true;
  isLoadingPastEvents: boolean = true;
  countOfEventsHiring: number;

  constructor(private errorHandlerService: ErrorHandlerService, private companyService: CompanyService, private eventService: EventService) {}

  ngAfterViewInit() {
    this.companyService
      .getCompany()
      .then(company => (this.company = company))
      .catch(this.errorHandlerService.reportError('HomeComponent.ngAfterViewInit', 'Error occurred while loading Events'))
      .finally(() => (this.isLoading = false))
      .then(this.loadEvents.bind(this));
  }

  loadNextEvent(index: number) {
    for (let i = 0; i < index + 2; i++) {
      if (this.events.length > i) {
        this.events[i]['shown'] = true;
      }
    }
  }

  loadPastEvent() {
    if (!this.pastEvents) {
      this.isLoadingPastEvents = true;
      setTimeout(() => {
        this.eventService
          .getPastActiveEvents()
          .then(events => {
            this.pastEvents = reverse(events);
          })
          .catch(this.errorHandlerService.reportError('HomeComponent.loadPastEvents()'))
          .finally(() => (this.isLoadingPastEvents = false));
      });
    }
  }

  private loadEvents() {
    this.isLoadingEvents = true;
    this.eventService
      .getUpcomingActiveEvents()
      .then(events => {
        this.events = events;
        if (events.length > 0) {
          events[0]['shown'] = true;
        }
        if (events.length > 1) {
          events[1]['shown'] = true;
        }
        this.countOfEventsHiring = 0;
        this.events.forEach(event => {
          event.rolesLite.forEach(role => {
            if (role.open && role.active) {
              this.countOfEventsHiring++;
            }
          });
        });
      })
      .catch(this.errorHandlerService.reportError('HomeComponent.loadEvents', 'Error occurred while loading Events'))
      .finally(() => (this.isLoadingEvents = false));
  }
}
