import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AnalyticsService } from '../helpers/analytics.service';
import { User } from '../models/user.model';
import { Reference, parseReference, ReferenceResponse, parseReferenceResponse } from '../models/reference.model';
import { UserService } from './user.service';
import { AuthService } from './auth.service';

@Injectable()
export class ReferenceService {
  constructor(private fns: AngularFireFunctions, private firestore: AngularFirestore, private authService: AuthService) {}

  removeReferenceForUser(userId: string, reference: Reference): Promise<void> {
    console.log('Removing reference', reference);
    return this.firestore.doc(`users/${userId}/references/${reference.id}`).delete();
  }

  addReferenceForUser(userId: string, reference: Reference): Promise<Reference> {
    console.log('Adding reference for userId: ' + userId, reference);
    return this.firestore
      .collection(`users/${userId}/references`)
      .add(reference.toJSON())
      .then(result => result.get())
      .then(parseReference);
  }

  getReferencesForUser(userId: string): Promise<Reference[]> {
    return this.firestore
      .collection(`users/${userId}/references`)
      .get()
      .toPromise()
      .then(actions => actions.docs.map(parseReference));
  }

  getReferenceResponsesForUser(userId: string, referenceId: string): Promise<ReferenceResponse[]> {
    return this.firestore
      .collection(`users/${userId}/references/${referenceId}/responses`)
      .get()
      .toPromise()
      .then(actions => actions.docs.map(parseReferenceResponse));
  }

  sendReferenceQuestionnaire(userId: string, referenceId: string): Promise<void> {
    return this.authService
      .getAuthToken()
      .then(token => {
        const callable = this.fns.httpsCallable('reference-sendReferenceQuestionnaireEmail', { timeout: 30000 });
        return callable({ idToken: token, userId, referenceId }).toPromise();
      })
      .then(() => undefined);
  }
}
