import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'upperCaseFirstLetter' })
export class UpperCaseFirstLetterPipe implements PipeTransform {
  transform(value: string) {
    if (value == null || !value.length) {
      return null;
    }
    return value
      .trim()
      .split(' ')
      .map(this.upperWord)
      .join(' ');
  }

  private upperWord(value: string) {
    if (value.trim() === '') {
      return '';
    } else {
      return value[0].toUpperCase() + value.substr(1, value.length - 1).toLowerCase();
    }
  }
}
