<div class="modal" id="{{ id }}" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog zoomIn faster animated" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="{{ id }}-title">{{ edit ? 'Edit' : 'Set' }} {{ title }}</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="No">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" novalidate>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <datetime-picker formControlName="dateTime" [minDate]="minDate" [maxDate]="maxDate"></datetime-picker>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" (click)="cancel()">
          Cancel
        </button>
        <button type="button" class="btn btn-sm btn-info" (click)="save()" [disabled]="form.invalid">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
