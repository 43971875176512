import { Component } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { Router } from '@angular/router';
import { LinkService } from '@app/core/link.service';
import { CurrentUserInfoComponent } from './current-user-info.component';
import { FileStorageService } from '@app/core/services/file-storage.service';
import { CompanyService } from '@app/core/services/company.service';
import { PayrollService } from '@app/core/services/payroll.service';
import { Payroll } from '@app/core/models/payroll.model';
import { Company } from '@app/core/models/company.model';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'current-user-payroll',
  templateUrl: './current-user-payroll.component.html',
  styleUrls: ['./current-user-info.component.scss'],
})
export class CurrentUserPayrollComponent extends CurrentUserInfoComponent {
  protected profileEditorSection: string = 'payroll';

  payroll: Payroll;
  accessGranted: boolean;
  company: Company;
  constructor(protected authService: AuthService, private payrollService: PayrollService, protected companyService: CompanyService, protected linkService: LinkService, protected router: Router) {
    super(authService, linkService, router, companyService);
  }

  protected async setupThisSection() {
    this.payroll = await this.payrollService.getPayrollForUser(this.user.id);
    this.accessGranted = this.user?.companyPayrollAccess?.includes(this.companyService.getId());
    this.company = await this.companyService.getCompany();
  }
  protected getFieldStates(): Promise<boolean[]> {
    const bankAccountFields = [
      !!this.accessGranted,
      !!this.payroll?.bankAccount?.holderName,
      !!this.payroll?.bankAccount?.bankName,
      !!this.payroll?.bankAccount?.accountNumber,
      !!this.payroll?.bankAccount?.sortCode,
    ];

    return Promise.resolve([...bankAccountFields]);
  }

  edit() {
    this.linkService.navigate(['profile', 'payroll'], { queryParams: { redirect: this.router.url, application: true } });
  }
}
