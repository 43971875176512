import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class TermsComponent {
  constructor() {}
}
