import { Component, Input } from '@angular/core';
import { ApplicationStatus } from '@app/core/services/application.service';
@Component({
  selector: 'application-status',
  template: `
    <div
      class="status badge flex-none text-center"
      *ngIf="!text"
      [class.badge-warning]="showColour && (!status || status === ApplicationStatus.SUBMITTED || status === ApplicationStatus.REVIEWING)"
      [class.badge-secondary]="showColour && status === ApplicationStatus.REJECTED"
      [class.badge-primary]="showColour && status === ApplicationStatus.ACCEPTED"
      [class.badge-danger]="showColour && status === ApplicationStatus.WITHDRAWN"
    >
      <h6><fa-icon [icon]="['fas', 'cog']" [spin]="true" class="mr-2" *ngIf="isSaving"></fa-icon>{{ statusText(status) }}</h6>
      <ng-content></ng-content>
    </div>
    <p *ngIf="text">{{ statusText(status) }}</p>
  `,
  styles: ['.badge { width: 6rem; display: flex; justify-content: center}', 'h6 { font-weight: 600}'],
})
export class ApplicationStatusComponent {
  @Input()
  status: ApplicationStatus;

  @Input()
  isAdmin: boolean = false;

  @Input()
  showColour: boolean = true;

  @Input()
  text: boolean = false;

  @Input()
  isSaving: boolean = false;

  ApplicationStatus = ApplicationStatus;

  statusText(status: ApplicationStatus) {
    return ApplicationStatusComponent.statusText(status, this.isAdmin);
  }

  static statusText(status: ApplicationStatus, isAdmin: boolean = false) {
    switch (status) {
      case ApplicationStatus.ACCEPTED:
        return 'Accepted';
      case ApplicationStatus.REJECTED:
        return isAdmin ? 'Rejected' : 'Not this time';
      case ApplicationStatus.SUBMITTED:
        return 'Submitted';
      case ApplicationStatus.REVIEWING:
        return 'Reviewing';
      case ApplicationStatus.WITHDRAWN:
        return 'Withdrawn';
    }
  }
}
