import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CompanyUserPayrollInfo, parsePayroll, Payroll, parseCompanyUserPayroll } from '../models/payroll.model';
import { CompanyService } from './company.service';
import { Event } from './event.service';
import { UserService } from './user.service';
import * as fs from 'file-saver';
import { AuthService } from './auth.service';
declare const Buffer: any;

@Injectable()
export class PayrollService {
  constructor(private firestore: AngularFirestore, private authService: AuthService, private fns: AngularFireFunctions, private companyService: CompanyService) {}

  getPayrollForUser(userId: string): Promise<Payroll> {
    console.info('getting payroll for userId: ' + userId);
    const result = this.firestore.doc('users/' + userId + '/payroll/payroll');
    return result
      .get()
      .toPromise()
      .then(response => parsePayroll(response));
  }

  getPayrollCompanyInfoForUser(userId: string, companyId: string): Promise<CompanyUserPayrollInfo> {
    console.info('getting company payroll for userId: ' + userId + ' ' + companyId);
    const result = this.firestore.doc(`users/${userId}/payroll/${companyId}`);
    return result
      .get()
      .toPromise()
      .then(response => parseCompanyUserPayroll(response));
  }

  requestAccessToPayrollInfo(userId: string): Promise<CompanyUserPayrollInfo> {
    console.info('requesting company payroll access for userId: ' + userId);
    const companyId = this.companyService.getId();
    return this.authService
      .getAuthToken()
      .then(token => {
        const callable = this.fns.httpsCallable('payroll-requestAccessToPayrollInfo', { timeout: 30000 });
        return callable({ idToken: token, userId, companyId }).toPromise();
      })
      .then(() => this.getPayrollCompanyInfoForUser(userId, companyId));
  }

  exportPayrollToExcel(eventId: string, event: Event) {
    return this.authService
      .getAuthToken()
      .then(idToken => {
        const callable = this.fns.httpsCallable('export-exportPayrollToExcel', { timeout: 30000 });
        return callable({ idToken, companyId: this.companyService.getId(), eventId }).toPromise();
      })
      .then(result => {
        const buffer = this.parseBuffer(result.buffer);
        let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, event.name + ' Payroll.xlsx');
      });
  }

  async createOrUpdate(userId: string, payroll: Payroll) {
    console.log('Saving payroll');
    const json = Payroll.toJSON(payroll);
    await this.delay(1000);
    await this.firestore.doc(`users/${userId}/payroll/payroll`).set(json);
  }

  private parseBuffer(bufferStr: string) {
    return Buffer.from(JSON.parse(bufferStr).data);
  }
  private delay(millis: number) {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, millis);
    });
  }
}
