<div *ngIf="options" class="modal" id="{{ id }}" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog zoomIn faster animated" [class.modal-dialog-centered]="centered" [class.large]="options.isLarge" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" id="{{ id }}-title">{{ options.title }}</h4>
				<button type="button" class="close" data-dismiss="modal" (click)="onCancel()" aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" *ngIf="options && !options.messageHtml">
				<p *ngFor="let paragraph of messageParagraphs">{{ paragraph }}</p>
			</div>
			<div class="modal-body" *ngIf="options && options.messageHtml">
				<p [innerHTML]="options.messageHtml"></p>
			</div>
			<div class="modal-footer">
				<button *ngIf="options.cancelText" type="button" class="btn btn-sm btn-secondary" (click)="onCancel()">
					{{ options.cancelText }}
				</button>
				<button *ngIf="options.confirmText" type="button" class="btn btn-sm btn-info" (click)="onConfirm()">
					<fa-icon [icon]="['fas', 'cog']" [spin]="true" *ngIf="isSaving"></fa-icon>
					{{ options.confirmText }}
				</button>
			</div>
		</div>
	</div>
</div>
