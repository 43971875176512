import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Dialog } from '@app/shared/dialog/dialog.component';
import { LinkService } from '@app/core/link.service';
import { ReferenceService } from '@app/core/services/reference.service';
import { ReferenceResponse } from '@app/core/models/reference.model';
import { ErrorHandlerService } from '@app/core/error-handler.service';

@Component({
  selector: 'reference-response-review-dialog',
  templateUrl: './reference-response-review-dialog.component.html',
  styles: [''],
})
export class ReferenceResponseReviewDialogComponent extends Dialog {
  isLoading: boolean = false;
  responses: ReferenceResponse[];

  constructor(protected location: Location, protected linkService: LinkService, private referenceService: ReferenceService, private errorHandlerService: ErrorHandlerService) {
    super(location, linkService, 'reference-response-review-dialog');
  }

  openForUserReference(userId: string, referenceId: string) {
    super.open();
    this.isLoading = true;
    this.referenceService
      .getReferenceResponsesForUser(userId, referenceId)
      .then(responses => {
        this.responses = responses;
      })
      .catch(this.errorHandlerService.reportError('ReferenceResonseReviewDialogComponent.openForUserReference()', 'Error occurred while loading response', JSON.stringify({ userId, referenceId })))
      .finally(() => (this.isLoading = false));
  }

  close() {
    super.close();
    this.responses = [];
  }
}
