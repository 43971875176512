import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { CurrentUser, User } from '@app/core/models/user.model';
import { Router } from '@angular/router';
import { LinkService } from '@app/core/link.service';
import { CompanyService } from '@app/core/services/company.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'current-user-info',
  templateUrl: './current-user-info.component.html',
  styleUrls: ['./current-user-info.component.scss'],
})
export class CurrentUserInfoComponent {
  @Input()
  set user(user: User) {
    this._user = user;
    if (user) {
      this.setup();
    }
  }

  get user() {
    return this._user;
  }

  @Input()
  editable: boolean = true;

  @Input()
  showSectionError: boolean = true;

  @Input()
  isLoading: boolean = true;

  @Output()
  onLoaded: EventEmitter<void> = new EventEmitter<void>();

  isProfileComplete: boolean = false;
  completePercentage: number = 0;
  isEmailVerified: boolean = false;
  isEmailVerificationLoading: boolean = false;

  protected profileEditorSection: string = 'info';

  private _user: User;

  constructor(protected authService: AuthService, protected linkService: LinkService, protected router: Router, protected companyService: CompanyService) {}

  edit() {
    this.linkService.navigate(['profile', 'edit'], { queryParams: { redirect: this.router.url, application: true }, fragment: this.profileEditorSection });
  }

  protected async setupThisSection(currentUser: CurrentUser) {
    console.log('currentUser emailVerified', !!currentUser.emailVerified);
    this.isEmailVerified = await this.checkIfEmailVerified(currentUser);
    if (!this.isEmailVerified && this.authService.resentlyRequestedEmailVerificationEmail) {
      this.startEmailCheckLoop(currentUser);
    }
  }
  protected setup() {
    return this.authService
      .getLoggedInUser()
      .then(this.setupThisSection.bind(this))
      .then(() => this.getFieldStates())
      .then(fieldStates => {
        this.isProfileComplete = fieldStates.filter(state => state).length === fieldStates.length;
        this.completePercentage = Number.parseInt(((fieldStates.filter(state => state).length / fieldStates.length) * 100).toFixed(0), undefined);
      })
      .then(() => this.onLoaded.emit());
  }

  protected getFieldStates(): Promise<boolean[]> {
    return Promise.resolve([
      !!this.user.email,
      !!this.user.image,
      !!this.user.title,
      !!this.user.firstName,
      !!this.user.lastName,
      !!this.user.gender,
      !!this.user.nationality,
      !!this.user.dob,
      this.isEmailVerified,
      this.isBooleanSet(this.user.disability),
    ]);
  }

  protected isBooleanSet(field: boolean): boolean {
    if (field !== true && field !== false) {
      return false;
    } else {
      return true;
    }
  }

  private startEmailCheckLoop(currentUser: CurrentUser, attempts: number = 5) {
    this.isEmailVerificationLoading = true;
    setTimeout(async () => {
      const verified = await this.checkIfEmailVerified(currentUser);
      if (!verified) {
        if (attempts > 0) {
          this.startEmailCheckLoop(currentUser, --attempts);
        } else {
          this.isEmailVerificationLoading = false;
        }
      } else {
        this.isEmailVerified = true;
        this.isEmailVerificationLoading = false;
        this.setup();
      }
    }, 5000);
  }

  private async checkIfEmailVerified(currentUser: CurrentUser) {
    return currentUser.emailVerified || (await this.checkIfVerificationHasCompleted()) || this.isTestAccount(currentUser);
  }

  private checkIfVerificationHasCompleted() {
    console.log('forcing refresh of emailVerified flag');
    return this.authService.getLoggedInUserForceRefresh().then(updated => updated.emailVerified || false);
  }

  private isTestAccount(currentUser: CurrentUser) {
    return this.companyService.getId() === 'int' && currentUser.email.includes('@example.com');
  }
}
