import { Component, forwardRef, Input } from '@angular/core';
import { Timestamp } from '@app/core/models/firebase.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeviceService } from '@app/core/device/device.service';
import moment from 'moment';

@Component({
  selector: 'time-picker',
  templateUrl: 'time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimePickerComponent),
    },
  ],
})
export class TimePickerComponent implements ControlValueAccessor {
  @Input()
  set maxDate(date: Timestamp) {
    this._max = date ? date.toDate() : null;
  }

  @Input()
  set minDate(date: Timestamp) {
    this._min = date ? date.toDate() : null;
  }

  @Input()
  clearButton: boolean = false;

  get max() {
    return this._max;
  }

  get min() {
    return this._min;
  }

  onChange: any;
  onTouch: any;

  time: Date;
  defaultTime: Date;

  private _max: Date;
  private _min: Date;

  constructor(private mobileService: DeviceService) {}

  writeValue(time: string): void {
    if (time && time.indexOf(':') > -1) {
      const [hours, minutes] = time.split(':');
      this.time = new Date();
      this.time.setHours(Number.parseInt(hours), Number.parseInt(minutes));
    } else {
      this.time = null;
    }
    this.defaultTime = new Date();
    this.defaultTime.setHours(0, 0, 0, 0);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  onTimeUpdated(time: Date): void {
    if (time) {
      const updated = new Date();
      updated.setHours(time?.getHours() || 0);
      updated.setMinutes(time?.getMinutes() || 0);
      updated.setMilliseconds(0);
      updated.setSeconds(0);
      this.onChange(moment(updated).format('HH:mm'));
    } else {
      this.onChange(null);
    }
  }

  clear() {
    this.time = null;
    this.defaultTime = null;
  }

  get isMobile() {
    return this.mobileService.isMobileSize();
  }
}
