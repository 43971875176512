import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { EventPageComponent } from './event/event-page.component';
import { ApplyPageComponent } from './apply/apply-page.component';
import { Shell } from '@app/shell/shell.service';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './privacy/terms.component';
import { IncompleteProfileGuard } from '@app/core/guards/incomplete-profile.guard';
import { DuplicateApplicationGuard } from './duplicate-application-guard';
import { RoleIsOpenGuard } from './role-is-open-guard';
import { IsSavingFormGuard } from '../core/guards/is-saving-form.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      component: HomeComponent,
      data: { title: 'Home', isFadeIn: true },
    },
    {
      path: 'events/:eventId',
      component: EventPageComponent,
      data: { title: 'Events' },
      canActivate: [IncompleteProfileGuard],
    },
    {
      path: 'events/:eventId/apply/:roleId',
      component: ApplyPageComponent,
      data: { title: 'Apply to work' },
      canDeactivate: [IsSavingFormGuard],
      canActivate: [DuplicateApplicationGuard, IncompleteProfileGuard, RoleIsOpenGuard],
    },
    {
      path: 'privacy',
      component: PrivacyComponent,
      data: { title: 'Privacy Policy' },
    },
    {
      path: 'terms-of-service',
      component: TermsComponent,
      data: { title: 'Terms and Conditions' },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class JobsRoutingModule {}
