import { Component, Output, EventEmitter, Input } from '@angular/core';

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { ImageHelperService } from '@app/core/services/image-helper.service';

export enum ImageShape {
  ROUND = 'ROUND',
  BANNER = 'BANNER',
  SQUARE = 'SQUARE',
  LARGE_SQUARE = 'LARGE_SQUARE',
}

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
  @Input()
  set preselectedImage(file: File) {
    if (file) {
      this.fileSelected = true;
      this.imageFile = file;
    }
  }

  @Input()
  isImageCompressionSkippable: boolean = false;

  @Output()
  onUpload: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onFileSelected: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set shape(shape: ImageShape) {
    this._shape = shape;
    let width = 300;
    if (shape === ImageShape.BANNER) {
      width = 1500;
    }
    if (shape === ImageShape.LARGE_SQUARE) {
      width = 600;
    }
    this.options = {
      roundCropper: shape === ImageShape.ROUND,
      resizeToWidth: width,
      aspectRatio: shape === ImageShape.BANNER ? 2.5 : 1,
      resizeToHeight: 0,
    };
    this.options.resizeToHeight = this.options.resizeToWidth / this.options.aspectRatio;
  }

  get shape() {
    return this._shape;
  }

  imageFile: File;
  croppedImage: any = '';
  fileSelected: boolean = false;

  isLoadingFile: boolean = false;

  options: {
    roundCropper: boolean;
    resizeToWidth: number;
    aspectRatio: number;
    resizeToHeight: number;
  };

  showTooSmallError: boolean = false;
  selectionDimensions: {
    width: number;
    height: number;
  };
  private _shape: ImageShape;

  constructor(private toastr: ToastrService, private imageHelperService: ImageHelperService) {}

  onBrowseClicked() {
    this.isLoadingFile = true;
  }

  onFileSelect(file: any): void {
    this.isLoadingFile = true;
    this.showTooSmallError = false;
    console.info('trying to sanitize image');
    this.imageHelperService.sanitizeImage(file.underlyingFile, this.isImageCompressionSkippable, 1500).then(image => {
      this.imageFile = image;
      this.fileSelected = true;
      this.onFileSelected.emit();
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    if (event.imagePosition) {
      const { x1, x2, y1, y2 } = event.imagePosition;
      this.showTooSmallError = x2 - x1 < this.options.resizeToWidth;
      this.selectionDimensions = {
        width: x2 - x1,
        height: y2 - y1,
      };
    }
    this.onUpload.emit(this.croppedImage);
  }

  onImageLoaded() {
    this.isLoadingFile = false;
  }

  cropperReady() {}

  loadImageFailed() {
    console.error('Image upload fail');
    this.croppedImage = '';
    this.fileSelected = false;
    this.isLoadingFile = false;
    this.toastr.error('Error with selected image or is unsupported.');
  }

  reset() {}
}
