<div class="page">
	<div class="container">
		<div class="row mt-5">
			<div class="col-md-6 logo-parent">
				<img class="mb-5 logo" src="/assets/branding/logo-gold.svg" />
				<h3 class="mb-5">
					Log in to apply for new event work with <span class="event-master-blue-color">{{ company.name }} </span> using your event master Profile.
				</h3>
			</div>

			<div class="col-md-6 circles-parent">
				<img class="circles desktop" src="/assets/branding/circles.svg" />
				<h2 id="login" class="event-master-gold-color text-center bold mt-0 mb-3">Log in</h2>
				<login-buttons redirectTo="/"></login-buttons>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<img src="/assets/branding/dancers.svg" />
			</div>
			<div class="col-md-6"></div>
		</div>
	</div>
</div>
