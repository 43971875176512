import { Component, Output, EventEmitter } from '@angular/core';
import { Dialog } from '@app/shared/dialog/dialog.component';
import { Location } from '@angular/common';
import { ApplicationStatus } from '@app/core/services/application.service';
import { ApplicationStatusComponent } from '../application-status.component';
import { LinkService } from '@app/core/link.service';

@Component({
  selector: 'application-status-change-dialog',
  templateUrl: './application-status-change-dialog.component.html',
})
export class ApplicationStatusChangeDialogComponent extends Dialog {
  notes: string;
  status: ApplicationStatus;

  @Output()
  onChange = new EventEmitter<{ notes: string; status: ApplicationStatus }>();

  constructor(protected location: Location, protected linkService: LinkService) {
    super(location, linkService, 'application-status-change-dialog');
  }

  openForApplicationStatus(status: ApplicationStatus) {
    this.status = status;
    super.open();
  }

  save() {
    this.onChange.emit({ notes: this.notes, status: this.status });
    this.close();
    this.status = null;
  }

  close() {
    this.notes = null;
    super.close();
  }

  statusText(status: ApplicationStatus) {
    return ApplicationStatusComponent.statusText(status);
  }
}
