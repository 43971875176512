import { Component } from '@angular/core';
import { LinkService } from '@app/core/link.service';
import { EventCardComponent } from './event-card.component';

@Component({
  selector: 'event-card-mini',
  templateUrl: './event-card-mini.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardMiniComponent extends EventCardComponent {
  constructor(protected linkService: LinkService) {
    super(linkService);
  }
}
