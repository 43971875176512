import { FirebaseConfigHelper } from '../../../firebase-config/firebase-config.helper';
import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';

declare const gtag: any;

@Injectable()
export class AnalyticsService {
  constructor(private titleService: Title, private router: Router, private ccService: NgcCookieConsentService) {
    const measurementId = FirebaseConfigHelper.getConfig().measurementId;

    this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      this.updateConsent(event.status === 'allow');
    });

    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: this.ccService.hasConsented() ? 'granted' : 'denied',
      wait_for_update: this.ccService.hasConsented() ? 500 : 10000,
    });

    gtag('js', new Date());
    gtag('config', measurementId, {
      send_page_view: false,
      anonymizeIp: !this.ccService.hasConsented(),
    });
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const page = val as NavigationEnd;
        setTimeout(() => {
          gtag('config', measurementId, { page_path: page.url });
          this.reportEvent('page_view', {
            page_title: this.titleService.getTitle(),
            page_location: page.url,
          });
        }, 500);
      }
    });

    this.reportEvent('version', { value: environment.version });
    this.reportEvent('company', { value: FirebaseConfigHelper.getConfig().companyId });
  }

  updateConsent(approved: boolean) {
    if (approved) {
      console.log('User has consented to Analytics');
    }
    gtag('consent', 'update', {
      analytics_storage: approved ? 'granted' : 'denied',
    });
    const measurementId = FirebaseConfigHelper.getConfig().measurementId;
    gtag('config', measurementId, {
      anonymizeIp: !approved,
    });
  }

  reportInit() {
    this.reportEvent('on_init');
  }
  reportLogin() {
    this.reportEvent('login');
  }
  reportApplicationCreate() {
    this.reportEvent('application-create');
  }

  reportInterestRegistered() {
    this.reportEvent('interest-registered');
  }

  reportRegisterUser() {
    this.reportEvent('new-user');
  }

  reportInterestDeregistered() {
    this.reportEvent('interest-deregistered');
  }

  setupUser(userId: string, email: string) {
    //this.analytics.setUserId(userId);
    //this.analytics.setUserProperties({ email }, { global: true });
  }

  private reportEvent(name: string, value?: any) {
    try {
      gtag('event', name, { ...value, event_callback: () => console.debug('analytic: ' + name + ' sent', value) });
    } catch (e) {
      console.error(e);
    }
  }
}
