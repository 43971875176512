export function trimOrNull(str: string) {
  return str ? str.trim() : null;
}

export function toInitCap(str: string) {
  if (str) {
    return str.toLowerCase().replace(/(?:^|\s|-)[a-z]/g, m => m.toUpperCase());
  } else {
    return null;
  }
}

export function trim(str: string) {
  return str ? str.trim() : '';
}
