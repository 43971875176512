import { Component, Output, EventEmitter } from '@angular/core';
import { Dialog } from '@app/shared/dialog/dialog.component';
import { Location } from '@angular/common';
import { LinkService } from '@app/core/link.service';
import { User } from '@app/core/models/user.model';
import { Company } from '@app/core/models/company.model';
import { Event, EventService } from '@app/core/services/event.service';
import { ErrorHandlerService } from '@app/core/error-handler.service';
import { UserService } from '@app/core/services/user.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'register-for-notifications-dialog',
  templateUrl: './register-for-notifications.dialog.component.html',
  styleUrls: [],
})
export class RegisterForNotificationsComponent extends Dialog {
  user: User;
  event: Event;
  company: Company;
  isSaving: boolean = false;

  @Output()
  onRegister: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected location: Location,
    protected linkService: LinkService,
    private eventService: EventService,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService,
  ) {
    super(location, linkService, 'register-for-notifications', false);
  }

  openRegisterNotification(event: Event, company: Company, user: User) {
    this.event = event;
    this.company = company;
    this.user = user;
    super.open();
  }

  save() {
    this.isSaving = true;
    this.eventService
      .setCurrentUserForEventNotifications(this.event.id, true)
      .then(() => {
        this.onRegister.emit();
        this.close();
        this.authService.getLoggedInUserForceRefresh();
      })
      .catch(this.errorHandlerService.reportError('RegisterForNotificationsComponent.save()'))
      .finally(() => (this.isSaving = false));
  }

  close() {
    this.user = null;
    this.event = null;
    this.company = null;
    super.close();
  }
}
