import { AfterContentInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[positionColour]',
})
export class PositionColourDirective {
  @Input('class')
  defaultClassList: string;

  @HostBinding('class')
  classList: string;

  @Input('positionColour')
  set positionColour(positionColour: string) {
    this._positionColour = positionColour;
    this.setup();
  }

  get positionColour() {
    return this._positionColour;
  }

  private _positionColour: string;

  constructor(private element: ElementRef) {}

  setup() {
    if (this.positionColour) {
      this.classList = this.defaultClassList + ' colour colour-' + this.positionColour;
    } else {
      this.classList = this.defaultClassList;
    }
  }
}
