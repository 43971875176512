import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Dialog } from './shared/dialog/dialog.component';
import { VersionCheckService } from 'ngx-version-check';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { CompanyService } from './core/services/company.service';
import { MetricsService } from './core/helpers/metrics.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private metricsService: MetricsService,
    private toastr: ToastrService,
    private companyService: CompanyService,
    private ccService: NgcCookieConsentService,
    private versionCheckService: VersionCheckService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private config: NgbTooltipConfig,
    private deviceDetector: DeviceDetectorService,
  ) {
    this.config.triggers = this.deviceDetector.isMobile() ? 'click' : 'hover';
    this.config.container = 'body';
    Dialog.cleanup();
    this.companyService.onLoaded().then(() => {
      setTimeout(() => {
        $('#bootstrap-loading-screen').fadeOut(400);
        window['isAppLoaded'] = true;
      }, 500);
    });
  }

  ngOnInit() {
    this.companyService.init();
    this.metricsService.reportSiteViewMetric();
    console.log('Current version is: ' + this.versionCheckService.Version + ' hash ' + this.versionCheckService.Hash);

    setTimeout(() => this.versionCheckService.checkVersion(this.showNotification.bind(this)), 5000);
    this.versionCheckService.startVersionChecking({
      frequency: 1000 * 60 * 5,
      notification: this.showNotification.bind(this),
    });

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this),
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(title);
        }
      });
  }

  showNotification() {
    this.toastr
      .info('Click here to launch!', 'New EventMaster version available', {
        timeOut: 1000000,
        extendedTimeOut: 1000000,
      })
      .onTap.toPromise()
      .then(() => {
        window['reloadServiceWorker']();
      });
  }

  ngOnDestroy() {}
}
