<div class="bank-info" [class.is-profile-complete]="(isProfileComplete || !showSectionError) && !isLoading">
	<div class="absolute-fill vertical-center" *ngIf="isLoading || !user">
		<fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="text-center"></fa-icon>
	</div>
	<div class="row row-no-margin">
		<div class="body-col flex-auto p-2">
			<div class="row row-no-margin flex-column info">
				<h5>Bank Account to receive wages:</h5>
				<div class="row">
					<span class="col-sm-12 col-md-6">
						<h6 class="tiny">Account Holder:</h6>
						<p>{{ payroll?.bankAccount?.holderName }}</p>
						<p class="error" *ngIf="!payroll?.bankAccount?.holderName">Required</p>
					</span>
					<span class="col-sm-12 col-md-6">
						<h6 class="tiny">Account Number:</h6>
						<p>{{ payroll?.bankAccount?.accountNumber }}</p>
						<p class="error" *ngIf="!payroll?.bankAccount?.accountNumber">Required</p>
					</span>
					<span class="col-sm-12 col-md-6">
						<h6 class="tiny">Sort Code:</h6>
						<p>{{ payroll?.bankAccount?.sortCode }}</p>
						<p class="error" *ngIf="!payroll?.bankAccount?.sortCode">Required</p>
					</span>
					<span class="col-sm-12 col-md-6">
						<h6 class="tiny">Bank Name:</h6>
						<p>{{ payroll?.bankAccount?.bankName }}</p>
						<p class="error" *ngIf="!payroll?.bankAccount?.bankName">Required</p>
					</span>
				</div>
				<p *ngIf="!user || !payroll?.bankAccount || isLoading" class="error">Required</p>
			</div>
			<span class="info">
				<fa-icon [icon]="['fas', 'lock']" placement="right" ngbTooltip="Access Granted"></fa-icon>
				<p *ngIf="accessGranted">Access granted to {{ company.name }}</p>
				<p *ngIf="!accessGranted" class="error">Access permission to {{ company?.name }} Required</p>
			</span>
		</div>
	</div>
	<div class="row row-no-margin" *ngIf="editable">
		<button class="btn btn-success ml-auto mr-2 d-block mt-2 text-center-mobile" (click)="edit()"><fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>{{ isProfileComplete ? 'Edit' : 'Complete Payroll' }}</button>
	</div>
</div>
