import { Directive, OnChanges, OnDestroy, AfterContentInit, ContentChildren, QueryList, Input, ElementRef, Renderer2, ChangeDetectorRef, Optional, SimpleChanges } from '@angular/core';
import { LinkDirective } from './link.directive';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Directive({
  selector: '[linkActive]',
  exportAs: 'linkActive',
})
export class LinkActiveDirective implements OnChanges, OnDestroy, AfterContentInit {
  // TODO(issue/24571): remove '!'.
  @ContentChildren(LinkDirective, { descendants: true }) links!: QueryList<LinkDirective>;
  @Input() routerLinkActiveOptions: { exact: boolean } = { exact: false };
  public readonly isActive: boolean = false;

  private classes: string[] = [];
  private subscription: Subscription;

  constructor(private router: Router, private element: ElementRef, private renderer: Renderer2, private readonly cdr: ChangeDetectorRef, @Optional() private link?: LinkDirective) {
    this.subscription = router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  ngAfterContentInit(): void {
    this.links.changes.subscribe(_ => this.update());
    this.update();
  }

  @Input()
  set linkActive(data: string[] | string) {
    const classes = Array.isArray(data) ? data : data.split(' ');
    this.classes = classes.filter(c => !!c);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private update(): void {
    if (!this.links || !this.router.navigated) {
      return;
    }
    Promise.resolve().then(() => {
      const hasActiveLinks = this.hasActiveLinks();
      if (this.isActive !== hasActiveLinks) {
        (this as any).isActive = hasActiveLinks;
        this.cdr.markForCheck();
        this.classes.forEach(c => {
          if (hasActiveLinks) {
            this.renderer.addClass(this.element.nativeElement, c);
          } else {
            this.renderer.removeClass(this.element.nativeElement, c);
          }
        });
      }
    });
  }

  private isLinkActive(router: Router): (link: LinkDirective) => boolean {
    return (link: LinkDirective) => router.isActive(link.link, this.routerLinkActiveOptions.exact);
  }

  private hasActiveLinks(): boolean {
    const isActiveCheckFn = this.isLinkActive(this.router);
    return (this.link && isActiveCheckFn(this.link)) || this.links.some(isActiveCheckFn);
  }
}
